import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Router
import {
    Route,
    Switch
} from "react-router-dom";

import { headerSearch, tabs, toogleButton, unoTheme } from '../../../assets/styles/unoTheme';

import { MyTab, MyTabs } from "../../utils/MyTabs";
import { getStartDateAndEndDateByMonthYear, verifyClientPortfolioIsSgregated } from "../../utils/utils";
import AccountsScreen from './AccountsScreen';
import AprsScreen from './AprsScreen';
import AssetsScreen from './AssetsScreen';


//UnoButton
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import MyToggleSegregationButton from "../../utils/MyToggleSegregationButton";
import UnoButton from '../../utils/UnoButton';

import CodeIcon from '@material-ui/icons/Code';
import { BalancesValidationsAPI } from "../../../API/balances_validations";
import { loadClientDiaryByRange } from "../../../controllers/ClientController";
import { checkUserIsRole, roles } from "../../utils/user";
import { Badge } from '@material-ui/core';
import { getBlankTransactionsByClientIdAndDateRange } from '../../../API/transaction';

import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },
    selectableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

        '&:hover': {
            color: 'white',
            background: unoTheme.mainColor,
            '& > *': {
                color: 'white',
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                }

            }
        }

    },
    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '100px !important',
        }
    },
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    toogleButton: toogleButton,

    aprBadge: {
        '& .MuiBadge-badge': {
            marginRight: "-10px !important",
            backgroundColor: "#f1c40f !important"
        }
    }
}));

const specificClientsIdsToRemoveApr = [455]; //Camaçari

export default function PortfolioScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)
    const innerLoad = useSelector(state => state.innerLoad)

    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const [tabValue, setTabValue] = React.useState(0);

    const ACCOUNT_TAB_INDEX = 1;

    const [countBlankAprs, setCountBlankAprs] = useState();

    const [flagDoExcelPortfolio, setFlagDoExcelPortfolio] = React.useState(false);
    const [flagDoNewAccount, setFlagDoNewAccount] = React.useState(false);
    const [flagDoReportBatchAprs, setFlagDoReportBatchAprs] = React.useState(0);
    const [flagDoExcelTransactions, setFlagDoExcelTransactions] = React.useState(false);

    function resetFlag() {

        setFlagDoReportBatchAprs(false);
        setFlagDoExcelTransactions(false);
        setFlagDoExcelPortfolio(false);
        setFlagDoNewAccount(false);

    }

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        if (newValue == 0) {
            history.push('/portfolio/assets')
        } else if (newValue == 1) {
            history.push('/portfolio/accounts')
        } else {
            history.push('/portfolio/aprs')
        }

    };

    const [formats, setFormats] = React.useState(() => null);

    const handleFormat = (event, newFormats) => {

        console.log("NEW FORMAT: ", newFormats)

        switch (newFormats) {

            case 0:
                break;
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;

        }

        setFormats(newFormats);
    };

    async function doPortofolioAnalyze() {

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Analisando rentabildiades do ano..."
        }))

        //analisando diários
        const diarys = await loadClientDiaryByRange(clientOn.client.id,
            clientOn.client.selectedPortfolioMonth,
            clientOn.client.selectedPortfolioYear, 'ano');

        console.log('diarys: ', diarys);
        const { arrayRents } = diarys;
        //Observando quantidade de diários por mês
        const invalidMonthRents = [];
        arrayRents.forEach(element => {
            if (element.diarys.length < 18 || element.diarys.length > 30) {
                invalidMonthRents.push(element);
            }
        });

        //Analisando saldos possivelmente duplicados do atual e o mês seguinte
        let nextMonth = clientOn.client.selectedPortfolioMonth + 1;
        let nextYear = clientOn.client.selectedPortfolioYear;
        if (nextMonth === 13) {
            nextMonth = 1;
            nextYear++;
        }

        const currentBalances = await BalancesValidationsAPI
            .getBalancesByMonthAndYear(clientOn.client.id,
                clientOn.client.selectedPortfolioMonth,
                clientOn.client.selectedPortfolioYear);

        //testando duplicações
        const duplicatedCurrentBalances = [];
        if (currentBalances?.body?.rows?.length) {
            currentBalances.body.rows.forEach(bv1 => {

                currentBalances.body.rows.forEach(bv2 => {
                    if (bv1.id != bv2.id && bv1.asset_id === bv2.asset_id) {
                        duplicatedCurrentBalances.push(bv1);
                    }
                });

            });
        }

        const duplicatedCurrentMoneyBalances = [];
        if (currentBalances?.body?.rows?.length) {
            currentBalances.body.rows.forEach(bv1 => {

                currentBalances.body.rows.forEach(bv2 => {
                    const bv1Balance = parseFloat(bv1.balance);
                    const bv2Balance = parseFloat(bv2.balance);
                    if (bv1.id != bv2.id && bv1Balance != 0 && bv1Balance === bv2Balance) {
                        duplicatedCurrentMoneyBalances.push({
                            bv1: bv1,
                            bv2: bv2,
                        });
                    }
                });

            });
        }

        console.log(nextMonth, nextYear);
        const nextBalances = await BalancesValidationsAPI
            .getBalancesByMonthAndYear(clientOn.client.id,
                nextMonth,
                nextYear);

        //testando duplicações
        const duplicatedBalances = [];
        if (nextBalances?.body?.rows?.length) {
            nextBalances.body.rows.forEach(bv1 => {

                nextBalances.body.rows.forEach(bv2 => {

                    if (bv1.id != bv2.id && bv1.asset_id === bv2.asset_id) {
                        duplicatedBalances.push(bv1);
                    }
                });

            });
        }


        console.log("////////////////////////////")
        console.log("/////////ANÁLISE///////////")
        console.log("invalidMonthRents: ", invalidMonthRents);
        console.log("duplicatedCurrentBalances: ", duplicatedCurrentBalances);
        console.log("duplicatedCurrentMoneyBalances: ", duplicatedCurrentMoneyBalances);
        console.log("duplicatedBalances: ", duplicatedBalances);
        console.log("////////////////////////////")


        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
        }))
    }

    const getCountBlankAprs = useCallback(async (clientId, month, year) => {

        const { startDate, endDate } = getStartDateAndEndDateByMonthYear(month, year);
        const response = await getBlankTransactionsByClientIdAndDateRange(clientId, startDate, endDate);
        console.log("REsponse blanks: ", response);
        if (response.success) {
            setCountBlankAprs(response.body.rows.length);
        } else {
            setCountBlankAprs(undefined);
        }
    }, [])

    //Caso em que alterou-se o cliente
    if (clientOn && clientOn.client
        && (!clientOnScreen || clientOn.client.id != clientOnScreen.id)) {

        setClientOnScreen(_.cloneDeep(clientOn.client));
        getCountBlankAprs(clientOn.client.id,
            clientOn.client.selectedPortfolioMonth,
            clientOn.client.selectedPortfolioYear)

        //Caso em que alterou-se o mês ou o ano no controller no dashboard
    } else if (clientOn && clientOn.client && clientOnScreen
        && clientOn.client.id == clientOnScreen.id
        && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear
        && clientOn.client.portfolio_closed != clientOnScreen.portfolio_closed) {

        setClientOnScreen(_.cloneDeep(clientOn.client));
        getCountBlankAprs(clientOn.client.id,
            clientOn.client.selectedPortfolioMonth,
            clientOn.client.selectedPortfolioYear)

        //Caso em que alterou-se o mês ou o ano
    } else if (clientOn && clientOn.client && clientOnScreen
        && clientOn.client.id == clientOnScreen.id
        && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear
        && (
            clientOn.client.selectedPortfolioMonth != clientOnScreen.selectedPortfolioMonth
            ||
            clientOn.client.selectedPortfolioYear != clientOnScreen.selectedPortfolioYear
        )
    ) {
        getCountBlankAprs(clientOn.client.id,
            clientOn.client.selectedPortfolioMonth,
            clientOn.client.selectedPortfolioYear)

    }

    useEffect(() => {

        console.log("LOCATION: ", location)
        // if (location && location.pathname == '/portfolio/accounts' && tabValue != 1) {
        //     setTabValue(1);
        // }

    }, [location]);

    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}
                        aria-label="disabled tabs example">

                        <MyTab label="Ativos" />
                        <MyTab label="Contas" />

                        {
                            (!checkUserIsRole(currentUser, [roles.viewer])
                                ||
                                (checkUserIsRole(currentUser, [roles.viewer]) && !specificClientsIdsToRemoveApr.includes(clientOn?.client?.id)))
                            &&

                            // <MyTab label="APRs" />
                            <MyTab label={
                                countBlankAprs ?
                                    <Badge
                                        className={classes.aprBadge}
                                        //variant='dot'
                                        badgeContent={countBlankAprs}
                                        color="error">
                                        APRs
                                    </Badge>
                                    :
                                    "APRs"
                            } />


                        }

                    </MyTabs>

                    {/* <div className={classes.bottomTabs}></div> */}
                </div>

                <div className={classes.rightHeader}>

                    {
                        //Exibido apenas para SUPER ADMIN
                        checkUserIsRole(currentUser, [roles.superadmin, roles.admin]) ?
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                <UnoButton
                                    size={'small'}
                                    onClick={() => doPortofolioAnalyze()}
                                    type='warning'
                                    startIcon={<CodeIcon />}>
                                    {"ANÁLISE"}
                                </UnoButton>

                            </div> : null
                    }

                    {
                        //Exibido apenas no menu de APRS
                        tabValue == 0 ?
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                <UnoButton
                                    size={'small'}
                                    onClick={() => setFlagDoExcelPortfolio(true)}
                                    type='success'
                                    startIcon={<PrintIcon />}>
                                    {"Excel"}
                                </UnoButton>

                            </div> : null
                    }



                    {
                        //Exibido apenas no menu de APRS
                        tabValue == 2 ?
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                <UnoButton
                                    style={{ marginRight: '16px' }}
                                    size={'small'}
                                    onClick={() => setFlagDoExcelTransactions(true)}
                                    type='success'
                                    startIcon={<PrintIcon />}>
                                    {"Excel"}
                                </UnoButton>

                                <UnoButton
                                    size={'small'}
                                    onClick={() => setFlagDoReportBatchAprs(true)}
                                    type='primary'
                                    startIcon={<PrintIcon />}>
                                    {"Gerar lote"}
                                </UnoButton>
                            </div> : null
                    }

                    <MyToggleSegregationButton
                        value={formats}
                        handleValue={handleFormat}
                        disabled={innerLoad.show && !innerLoad.emptyMessage}
                        segregated={verifyClientPortfolioIsSgregated(clientOn)}
                    />

                    {
                        //Exibido apenas no menu de CONTAS
                        tabValue == ACCOUNT_TAB_INDEX && !currentUser?.user?.isViewer ?
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                                <UnoButton
                                    size={'small'}
                                    onClick={() => setFlagDoNewAccount(true)}
                                    type='primary'
                                    startIcon={<AddIcon />}>
                                    {"Nova conta"}
                                </UnoButton>

                            </div> : null
                    }
                </div>


            </div>

            <Switch className={classes.switch}>
                <Route path="/portfolio/assets">
                    <AssetsScreen accountRegimeId={formats}
                        flagDoExcelPortfolio={flagDoExcelPortfolio}
                        resetFlag={resetFlag} />
                </Route>
                <Route path="/portfolio/accounts">
                    <AccountsScreen
                        flagDoNewAccount={flagDoNewAccount}
                        resetFlag={resetFlag}
                        accountRegimeId={formats} />
                </Route>
                <Route path="/portfolio/aprs">
                    <AprsScreen accountRegimeId={formats}
                        flagDoReportBatchAprs={flagDoReportBatchAprs}
                        flagDoExcelTransactions={flagDoExcelTransactions}
                        resetFlag={resetFlag} />
                </Route>
            </Switch>
        </div>
    );
}




import React, { useEffect, useState, useRef } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Checkbox, Divider, Paper, Tooltip, Typography } from "@material-ui/core";
import { unoTheme } from "../../../assets/styles/unoTheme";

//Tables
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import MyTextField from "../../utils/MyTextField";
import MyAlertBalance from "../../utils/MyAlertBalance";
import MyTextFieldRef from "../../utils/MyTextFieldRef";

import UnoButton from "../../utils/UnoButton";

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import {
    getArrayMonthsWithNames,
    applyMaskAmount,
    applyMaskAccount,
    parseFloatByPtCurrency,
    applyCurrencyMask,
    getMonthName,
    formatAnbimaDateToPt,
    applyMaskAmountFreeDecimals,
    verifyClientPortfolioIsSgregated,
    checkAssetsAndSubAssetsSameBalances,
} from "../../utils/utils";

import { getRegimeTypes } from "../../utils/regimeTypes";
import { setBalanceReconciliaded } from "../../../API/client";

//MyInputs
import MySelect from '../../utils/MySelect'
import MyDatePicker from '../../utils/MyDatePicker'

import _ from 'lodash';
import moment from 'moment'

import CircularProgress from '@material-ui/core/CircularProgress';

import EventIcon from '@material-ui/icons/Event';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import QuotizationAssetModal from '../../modals/QuotizationAssetModal';
import InfosFundModal from '../../modals/InfosFundModal';

import InfoIcon from '@material-ui/icons/Info';
import MyAlertSpecialClientQuota from "../../utils/MyAlertSpecialClientQuota";
import NewSpecialClientQuotaModal from "../../modals/NewSpecialClientQuotaModal";
import { checkUserIsRole, roles } from "../../utils/user";


const bottomHeight = 40;
//const headerHeight = 170;
const headerHeight = 196;
//const headerHeight = 187;

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #000000"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        fontSize: '15px',
        backgroundColor: theme.palette.common.white,
        border: "1px solid #000000",
        color: "#000000"
    }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'column',
        //height: headerHeight,        
        backgroundColor: unoTheme.hoverTable,
        paddingBottom: '2px',
    },

    topHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        // paddingTop: theme.spacing(2),
        paddingTop: '12px',
        // paddingBottom: theme.spacing(2),
        paddingBottom: '12px',

        '& .actionButtonsHeader': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
    },

    bottomHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        paddingTop: '12px',
        paddingBottom: '12px',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)'
    },

    main: {
        //paddingBottom: theme.spacing(2),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        //paddingTop: theme.spacing(2),
        height: 'calc(100% - ' + (headerHeight) + 'px)',

        // '& .mainTable': {
        //     //height: 'calc(100% - ' + (headerHeight + bottomHeight) + 'px)',
        //     height: 'calc(100% - ' + (headerHeight) + 'px)',
        // },

        '& .alertMain': {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            textAlign: 'left',
        }
    },

    bottom: {
        height: bottomHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    table: {
        height: 'calc(100% - 1px)',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: '#1C2228',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px',
            },
        },
    },

    // detailRow: {
    //     cursor: "pointer",
    //     '& > *': {
    //         borderBottom: 'unset',
    //         padding: '3px 16px 3px 16px !important',
    //         textTransform: 'uppercase',
    //         color: unoTheme.defaultTextColor,
    //         fontWeight: 400,
    //         fontSize: '12px',
    //     },
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: unoTheme.secondaryTuplaTable,
    //     },

    // },

    flexCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100%',
        paddingLeft: '0px !important',

        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    statusOff: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusOk: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusPendent: {
        color: unoTheme.warningColor,
        fill: unoTheme.warningColor,
    },
    marginLeft: {
        marginLeft: '5px',
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    disableMonth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    green: {
        color: unoTheme.greenMoney + ' !important'
    },
    red: {
        color: unoTheme.errorColor + ' !important',
    },
    blue: {
        color: 'blue !important',
    },
    warning: {
        color: unoTheme.warningColor
    },
    labelTotal: {
        fontSize: '13px',
        color: unoTheme.thirdColor
    },
    resultAmount: {
        fontSize: '13px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    headerTotal: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '& .infosHeaderTotal': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',

            '& .numberQuotas': {
                cursor: 'pointer',
                padding: '0px',
                margin: '0px',
                fontSize: '12px',
                textAlign: 'left',
                transition: 'all .3s ease',

                '&:hover': {
                    color: '#aaaaaa',
                }
            }
        }

    },
    leftHeader: {
        display: 'flex',
        //alignItems: 'center',
        flexDirection: 'column',
    },
    headerDate: {
        display: 'flex',
        alignItems: 'center',
        color: unoTheme.secondaryColor,
        fontSize: '12px',
        '& svg': {
            marginRight: '5px',
        },
        '& > *': {
            fontSize: '14px',
        }
    },

    cnpjLabel: {
        cursor: 'pointer',
        fontSize: '14px',
        color: unoTheme.secondaryColor,

        transition: 'all .3s ease',

        '&:hover': {
            color: '#615b5b',
        }
    },

    fundLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228'
    },

    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    alertBalance: {
        marginBottom: '5px',
        '& .MuiAlert-message': {
            fontSize: '12px',
            padding: '0px',
            '& span': {
                verticalAlign: 'middle',
            }
        },
        '& .MuiAlert-icon': {
            padding: '0px',
        }
    },

    tabsAsset: {
        background: '#F3F5F8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    selectableInfo: {

        transition: 'all .3s ease',

        '&:hover': {
            color: '#aaaaaa',
        }
    },

    clickableSpanCota: {
        transition: 'all .1s ease',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '0.8em',
        color: unoTheme.mainColor,

        '&:hover': {
            color: '#aaaaaa',
        }
    },

    clickableSpanPl: {
        transition: 'all .1s ease',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '0.8em',
        color: unoTheme.greenMoney,

        '&:hover': {
            color: '#aaaaaa',
        }
    },

    cnpjAndFilterRegime: {

        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'center',
    },

    lastQuotasInfo: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontSize: '12px',
        }
    },

    bold: {
        fontWeight: 'bold',
        cursor: 'pointer',

        '&:hover': {
            opacity: 0.7,
        }
    },

    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '0px !important',
        }
    },



}));

export const ADD_SPECIAL_QUOTA_CLIENT = "ADD_SPECIAL_QUOTA_CLIENT"
export const UPDATE_SPECIAL_QUOTA_CLIENT = "UPDATE_SPECIAL_QUOTA_CLIENT"

export default function DetailBalanceCollection(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openQuotizationAssetModal, setOpenQuotizationAssetModal] = useState(false);

    //Fund infos modal
    const [openInfosFundModal, setOpenInfosFundModal] = useState(false);

    const [finalMapQuotasAnbimaByPtDate, setFinalMapQuotasAnbimaByPtDate] = useState();

    //Copy to DAIR
    const textRef = useRef(null);
    //Filtro de regime 
    const [regimeTypes,] = useState(getRegimeTypes(verifyClientPortfolioIsSgregated(clientOn)));
    const [selectedRegimeTypeSelect, setSelectedRegimeTypeSelect] = useState(0); //detail
    const [selectedRegimeType, setSelectedRegimeType] = useState(0); //detail


    const [openModalNewSpecialQuotaClient, setOpenModalNewSpecialQuotaClient] = useState(false); //detail

    //Exibir componente para adicionar quota de cliente específico
    //ableToAddNewSpecial evita deixar cadastrar cota para carteira já fechada
    function maybeAddSpecialClientQuota(asset, ableToAddNewSpecial) {

        if (ableToAddNewSpecial == true && asset.special == true
            && !checkAssetsAndSubAssetsSameBalances(asset)) {

            return true;
        }
        return null;
    }

    function handleRegimeType(value) {


        let valueToConsult = 0;
        valueToConsult = value;
        if (regimeTypes.length === 3
            && value == 2) {
            valueToConsult = 3;
        }

        setSelectedRegimeTypeSelect(value)
        setSelectedRegimeType(valueToConsult)

        console.log("VALUE COMPONENT: ", value);
        console.log("valueToConsult: ", valueToConsult);

        loadTotalInfosObject(content.data.transactions, parseInt(valueToConsult), numberAccountOptions[selectedNumberAccountOption]);
    }

    //Filtro de contas 
    const [numberAccountOptions, setNumberAccountsOptions] = useState([]);
    const [selectedNumberAccountOption, setSelectedNumberAccountOption] = useState(0); //detail

    function doSelectNumberAccount(value) {
        setSelectedNumberAccountOption(value);

        loadTotalInfosObject(content.data.transactions, parseInt(selectedRegimeType), numberAccountOptions[value]);

    }
    // function handleRegimeType(value) {
    //     setSelectedRegimeType(value)
    //     loadTotalInfosObject(content.data.transactions, value);
    // }

    const [totalInfosObject, setTotalInfosObject] = useState({
        totalApplications: 0,
        totalApplicationsQuotas: 0,
        totalRescues: 0,
        totalRescuesQuotas: 0,
    });

    function loadTotalInfosObject(transactions, regimeType, accountOption) {

        //Existe a possibilidade de não haver transações para o ativo selecionado

        if (transactions) {


            let totalApplications = 0;
            let totalApplicationsQuotas = 0;
            let totalRescues = 0;
            let totalRescuesQuotas = 0;

            //Caso o regimeType seja 0 deve-se tratar os totais a partir de todas as transações, informação consolidada
            const transactionsInTheRegime = regimeType == 0 ?
                transactions
                :
                transactions.filter(transaction => transaction.account_regime_id == regimeType);

            console.log("TRASANCTIONS TO TOTAL: ", transactionsInTheRegime);
            console.log("accountOption: ", accountOption);
            console.log("regimeType: ", regimeType);

            const transactionsRegimeAndAccount = accountOption == null || accountOption == 'Conta' ?
                transactionsInTheRegime
                :
                transactionsInTheRegime.filter(transaction => transaction.account == accountOption)

            if (transactionsRegimeAndAccount) {
                transactionsRegimeAndAccount.forEach(element => {

                    if (element.operation_id == 1) {

                        totalApplications += element.amount ? parseFloat(element.amount) : 0;
                        totalApplicationsQuotas += element.result_qtd_quotas ? element.result_qtd_quotas : 0;

                    } else if (element.operation_id == 2) {

                        totalRescues += element.amount ? parseFloat(element.amount) : 0;
                        totalRescuesQuotas += element.result_qtd_quotas ? element.result_qtd_quotas : 0;

                    }

                });

                setTotalInfosObject({
                    totalApplications: totalApplications,
                    totalApplicationsQuotas: totalApplicationsQuotas,
                    totalRescues: totalRescues,
                    totalRescuesQuotas: totalRescuesQuotas,
                });
            }

        }



    }

    //Tab de saldos do ano ou descrição do ativo
    const [tabValue, setTabValue] = useState(0); //detail
    function handleChangeTabValue(event, value) {
        setTabValue(value)
    }

    function copyToClipboard(e, text) {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
        dispatch(allActions.mainAlertActions.setMainAlert({
            type: 'success',
            message: 'Informação copiada para área de transferência!',
            show: true,
        }));
    };

    function doEditTransaction(transaction, index) {

        //console.log("T: ", transaction)
        // setEditAmount(applyMaskAmount(transaction.amount))
        // setEditOperation(parseInt(transaction.operation_id) - 1)
        // setEditDate(moment.utc(transaction.transaction_date));
        // setEdittingIndex(index)

        dispatch(allActions.rightContentActions.setRightContent({
            path: 'newTransaction',
            update: true,
            transactionToUpdate: transaction,
            data: { //Utilizado para enviar no retorno da tela
                path: 'detailBalanceCollection',
                data: content.data,
                selectedMonth: content.selectedMonth,
                selectedYear: content.selectedYear,
                accounts: content.accounts,
                assets: content.assets,
                operations: content.operations
            }
        }))
    }

    async function doConcilio(balanceId) {

        //open backdrop
        setOpenBackdrop(true);

        let response = await setBalanceReconciliaded(balanceId,
            currentUser.user.id,
            true);

        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Conciliação realizada com sucesso!'
            }))
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Erro ao realizar conciliação!'
            }))
        }

        dispatch(allActions.rightContentActions.setAction({
            task: 'updateConcilio',
            data: {
                balanceId: balanceId,
            },
        }))
        setOpenBackdrop(false);
    }

    function getAccountsWithTransactions(transactions) {

        let accountsWithTransactions = ["Conta"];

        if (!transactions) {
            return accountsWithTransactions;
        }

        transactions.forEach(t => {

            if (!accountsWithTransactions.includes(t.account)) {
                accountsWithTransactions.push(t.account);
            }

        });

        return accountsWithTransactions;
    }

    function getDataMapByDateTransaction(dateTransaction, attr) {
        if (finalMapQuotasAnbimaByPtDate && dateTransaction) {
            if (finalMapQuotasAnbimaByPtDate[moment.utc(dateTransaction).format('DD/MM/YYYY')]) {
                console.log('data:', finalMapQuotasAnbimaByPtDate[moment.utc(dateTransaction).format('DD/MM/YYYY')]);
                return applyMaskAmountFreeDecimals(finalMapQuotasAnbimaByPtDate[moment.utc(dateTransaction).format('DD/MM/YYYY')][attr], null, 20);
            }
        }

        return '-';
    }

    function createMapQuotasAnbimaByPtDate(quotasAnbima) {

        const mapQuotasAnbimaByPtDate = {};
        quotasAnbima.forEach(element => {

            const ptDate = formatAnbimaDateToPt(element.data_referencia);
            mapQuotasAnbimaByPtDate[ptDate] = {
                cota: element.valor_cota,
                pl: element.patrimonio_liquido
            }

        });

        setFinalMapQuotasAnbimaByPtDate(mapQuotasAnbimaByPtDate);

    }

    useEffect(() => {

        console.log("DATA DETAIL BALANCE COLLECTION: ", content.data)
        console.log("DATA DETAIL BALANCE COLLECTION ACCOUNTS: ", content.accounts)

        //Fazendo a última cota subir
        // if (content && content.data && content.data.quotas_anbima) {
        //     content.data.quotas_anbima.reverse();
        // }

        //init totalInfosObject
        if (content && content.data) {

            // let arrayNumberAccountsOptions = [];
            // content.accounts.forEach(account => {
            //     arrayNumberAccountsOptions.push(applyMaskAccount(account.number_account));
            // });
            // arrayNumberAccountsOptions.unshift("Contas");

            //setNumberAccountsOptions(arrayNumberAccountsOptions);
            createMapQuotasAnbimaByPtDate(content.data.quotas_anbima);
            setNumberAccountsOptions(getAccountsWithTransactions(content.data.transactions));
            loadTotalInfosObject(content.data.transactions, 0, null);
        }


    }, [])

    return (
        <div className={classes.root}>


            <div className={classes.header}>

                <div className={classes.topHeader}>

                    <div className={classes.leftHeader}>
                        <div className={classes.headerDate}>
                            <EventIcon />
                            <Typography
                                align={'left'}

                                className={classes.labelMonth}>
                                {content && content.selectedMonth && content.selectedYear ?
                                    getMonthName(content.selectedMonth).toString().toUpperCase() + " " + content.selectedYear
                                    : ""}
                            </Typography>
                        </div>
                        <Typography
                            align={'left'}
                            className={classes.fundLabel}>
                            {content && content.data ? content.data.fund_name : ""}

                        </Typography>
                        <div className={classes.cnpjAndFilterRegime}>
                            <Typography
                                onClick={(e) => copyToClipboard(e, content && content.data ? content.data.cnpj : "")}
                                ref={textRef}
                                align={'left'}
                                className={classes.cnpjLabel}>
                                {content && content.data ? content.data.cnpj : ""}
                            </Typography>
                            <MySelect
                                style={{ marginLeft: '16px' }}
                                value={selectedRegimeTypeSelect}
                                verysmall
                                onChange={(e) => handleRegimeType(e.target.value)}
                                options={regimeTypes.map((row, index) => (

                                    <option key={'regime-' + index} value={index}>{row}</option>

                                ))}
                            />

                        </div>

                    </div>

                    <div className={'actionButtonsHeader'}>

                        <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                <Divider />

                <div className={classes.bottomHeader}>
                    <div className={classes.headerTotal}>
                        <Typography
                            className={classes.labelTotal}>
                            {'APLICAÇÔES: '}
                        </Typography>
                        <div class="infosHeaderTotal">
                            <Typography
                                className={[classes.resultAmount, classes.green].join(' ')}>
                                {applyMaskAmount(totalInfosObject.totalApplications, true)}
                            </Typography>
                            <Typography class="numberQuotas" onClick={(e) => copyToClipboard(e, applyMaskAmountFreeDecimals(totalInfosObject.totalApplicationsQuotas, false))}>
                                {"(" + applyMaskAmountFreeDecimals(totalInfosObject.totalApplicationsQuotas, false) + ")"}
                            </Typography>
                        </div>

                    </div>
                    <div className={classes.headerTotal}>
                        <Typography
                            className={classes.labelTotal}>
                            {'RESGATES: '}
                        </Typography>
                        <div class="infosHeaderTotal">
                            <Typography
                                className={[classes.resultAmount, classes.red].join(' ')}>
                                {applyMaskAmount(totalInfosObject.totalRescues, true)}
                            </Typography>
                            <Typography class="numberQuotas" onClick={(e) => copyToClipboard(e, applyMaskAmountFreeDecimals(totalInfosObject.totalRescuesQuotas, false))}>
                                {"(" + applyMaskAmountFreeDecimals(totalInfosObject.totalRescuesQuotas, false) + ")"}
                            </Typography>
                        </div>
                    </div>
                    {/* <div className={classes.headerTotal}>
                        <Typography
                            className={classes.labelTotal}>
                            {'TOTAL: '}
                        </Typography>
                        <div>
                            <Typography
                                className={[classes.resultAmount, content.data.resultAmount > 0 ? classes.green : classes.red].join(' ')}>
                                {content.data && content.data.resultAmount ?
                                    applyMaskAmount(content.data.resultAmount, true) : "-"}
                            </Typography>
                            <Typography className={classes.numberQuotas}>
                                {content.data && content.data.sumQuotasApplication && content.data.sumQuotasRescues ?
                                    applyMaskAmountFreeDecimals(content.data.sumQuotasApplication - content.data.sumQuotasRescues, false) : "-"}
                            </Typography>
                        </div>

                    </div> */}



                </div>

                {content && content.data
                    && content.data.quotas_anbima && content.data.quotas_anbima.length > 0 ?

                    <Paper square className={classes.tabsAsset}>
                        <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChangeTabValue}>

                                <Tab label={'Movimentações'} />
                                <Tab label="Cotas" />

                            </Tabs>
                        </div>

                        {
                            content && content.data && content.data.quotas_anbima ?
                                <div className={[classes.lastQuotasInfo, classes.spacingRight2].join(' ')}>
                                    <UnoButton
                                        size="verySmall"
                                        onClick={() => setOpenInfosFundModal(true)}
                                        type='primary'>
                                        {"Infos"}
                                    </UnoButton>
                                    <UnoButton
                                        className={classes.spacingLeft2}
                                        size="verySmall"
                                        onClick={() => setOpenQuotizationAssetModal(true)}
                                        type='success'>
                                        {"Cotização"}
                                    </UnoButton>
                                    <span className={classes.spacingLeft2}>
                                        Últ. cota:
                                    </span>
                                    <span
                                        onClick={(e) => copyToClipboard(e, applyMaskAmountFreeDecimals(content.data.quotas_anbima[content.data.quotas_anbima.length - 1].valor_cota, false, 8))}
                                        className={[classes.bold, classes.spacingLeft1].join(' ')}>{applyMaskAmountFreeDecimals(content.data.quotas_anbima[content.data.quotas_anbima.length - 1].valor_cota, true, 8)}</span>
                                    <span className={classes.spacingLeft2}>
                                        Últ. PL:
                                    </span>
                                    <span
                                        onClick={(e) => content.data.quotas_anbima[content.data.quotas_anbima.length - 1].patrimonio_liquido != null ? copyToClipboard(e, applyMaskAmount(content.data.quotas_anbima[content.data.quotas_anbima.length - 1].patrimonio_liquido)) : null}
                                        className={[classes.bold, classes.spacingLeft1].join(' ')}>{content.data.quotas_anbima[content.data.quotas_anbima.length - 1].patrimonio_liquido != null ? applyMaskAmount(content.data.quotas_anbima[content.data.quotas_anbima.length - 1].patrimonio_liquido, true) : '-'}</span>
                                </div> : null
                        }

                    </Paper>

                    : null
                }


            </div>

            {
                tabValue == 0 ?


                    <div className={classes.main}>

                        {
                            //Verificar role
                            checkUserIsRole(currentUser,
                                [roles.admin,
                                roles.superadmin,
                                roles.advisor]) ?
                                <>
                                    {
                                        !content.data.is_poupanca && maybeAddSpecialClientQuota(content.data, content.ableToAddSpecialQuota) ?
                                            <MyAlertSpecialClientQuota
                                                role={currentUser.user ? currentUser.user.role : null}
                                                row={content.data}
                                                action={() => setOpenModalNewSpecialQuotaClient(true)} />
                                            : null
                                    }

                                    {!content.data.is_poupanca ?

                                        <>
                                            {
                                                content.data.subAssets ?

                                                    // exibir lista de alertas
                                                    content.data.subAssets.map((row, index) => (
                                                        <MyAlertBalance
                                                            key={'my-alert-balance-' + index}
                                                            role={currentUser.user ? currentUser.user.role : null}
                                                            row={row}
                                                            action={(param) => doConcilio(param)} />
                                                    ))

                                                    :

                                                    <MyAlertBalance
                                                        role={currentUser.user ? currentUser.user.role : null}
                                                        row={content.data}
                                                        action={(param) => doConcilio(param)} />
                                            }
                                        </> : null}
                                </> : null
                        }







                        {/* <TableContainer className={'mainTable'} component={Paper} elevation={0}>
                    <Table className={classes.table} size="small" aria-label="simple table"> */}

                        <MyTable
                            style={{ marginTop: '8px' }}
                            stickyHeader
                            infosBlack
                            sizeheaders="verySmall"
                            selectable={'true'}
                            size="small">
                            <colgroup>
                                <col width="5%" />
                                <col width="15%" />
                                <col width="17%" />
                                <col width="18%" />
                                <col width="20%" />
                                <col width="13%" />
                                <col width="22%" />
                                {/* <col width="10%" /> */}
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableTh} align="center"></TableCell>
                                    <TableCell className={classes.tableTh} align="center">Data</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Operação</TableCell>
                                    <TableCell className={classes.tableTh} align="center">
                                        <MySelect
                                            rawSelect
                                            value={selectedNumberAccountOption}
                                            verysmall
                                            onChange={(e) => doSelectNumberAccount(e.target.value)}
                                            options={numberAccountOptions.map((row, index) => (

                                                <option key={'account-number-option-' + index} value={index}>{row !== "Conta" ? applyMaskAccount(row) : row}</option>

                                            ))}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableTh} align="right">Cotas</TableCell>
                                    <TableCell className={classes.tableTh} align="center">DADOS DIA</TableCell>
                                    <TableCell className={classes.tableTh} align="right">Valor</TableCell>
                                    {/* <TableCell className={classes.tableTh} align="center"></TableCell> */}
                                </TableRow>
                            </TableHead>

                            {
                                content && content.data && content.data.transactions ?

                                    <TableBody>
                                        {
                                            //content.data.transactions.map((row, index) => (
                                            content.data.transactions.filter(
                                                el =>
                                                    (selectedRegimeType == 0 || el.account_regime_id == selectedRegimeType)
                                                    &&
                                                    (selectedNumberAccountOption == 0 || el.account == numberAccountOptions[selectedNumberAccountOption])
                                            ).map((row, index) => (


                                                <TableRow className={classes.detailRow}
                                                    key={'detail-transaction-' + index}
                                                    onClick={() =>

                                                        //Verificar role
                                                        checkUserIsRole(currentUser,
                                                            [roles.admin,
                                                            roles.superadmin,
                                                            roles.advisor]) ?

                                                            doEditTransaction(row, index) : null
                                                    }>

                                                    <TableCell align="center">
                                                        <Checkbox checked={row.checkedDAIR} onClick={(e) => {
                                                            row.checkedDAIR = true;
                                                            e.stopPropagation()
                                                        }} />
                                                    </TableCell>

                                                    <TableCell align="center" className={classes.selectableInfo}
                                                        onClick={(e) => copyToClipboard(e, moment.utc(row.transaction_date).format('DD/MM/YYYY'))}>
                                                        {moment.utc(row.transaction_date).format('DD/MM/YYYY')}
                                                    </TableCell>

                                                    <TableCell align="center" style={{ fontSize: '10px' }}>
                                                        {row.operation}
                                                    </TableCell>

                                                    <TableCell align="center" >
                                                        {applyMaskAccount(row.account)}
                                                    </TableCell>

                                                    <TableCell align="right" className={classes.selectableInfo} onClick={(e) => copyToClipboard(e, applyMaskAmountFreeDecimals(row.result_qtd_quotas))}>
                                                        {row && row.result_qtd_quotas ?
                                                            applyMaskAmountFreeDecimals(row.result_qtd_quotas) : '-'}
                                                    </TableCell>

                                                    <TableCell align="center"
                                                        className={classes.selectableInfo}>
                                                        <div>

                                                            <LightTooltip
                                                                title={getDataMapByDateTransaction(row.transaction_date, 'cota')}
                                                                placement="top" arrow>
                                                                <span className={[classes.clickableSpanCota].join(' ')}
                                                                    onClick={(e) => copyToClipboard(e,
                                                                        getDataMapByDateTransaction(row.transaction_date, 'cota')
                                                                    )}>COTA</span>
                                                            </LightTooltip>
                                                            <span style={{ margin: '0px 4px' }}>|</span>
                                                            <LightTooltip
                                                                title={getDataMapByDateTransaction(row.transaction_date, 'pl')}
                                                                placement="top" arrow>
                                                                <span className={[classes.clickableSpanPl].join(' ')}
                                                                    onClick={(e) => copyToClipboard(e,
                                                                        getDataMapByDateTransaction(row.transaction_date, 'pl')
                                                                    )}>PL</span>
                                                            </LightTooltip>

                                                        </div>
                                                    </TableCell>

                                                    <TableCell align="right" className={
                                                        row.operation == 'Aplicação' ? classes.green : (
                                                            row.operation == 'Resgate' ? classes.red : classes.blue
                                                        )
                                                    }>
                                                        {row && row.amount ? applyMaskAmount(row.amount, true) : null}
                                                    </TableCell>



                                                    {/* <TableCell align="center">
                                                        <Tooltip arrow placement={'left'} title={'Editar'}>
                                                            <IconButton size={'small'}
                                                                onClick={() => doEditTransaction(row, index)}>
                                                                <EditIcon />
                                                            </IconButton>

                                                        </Tooltip>

                                                    </TableCell> */}

                                                </TableRow>



                                            ))

                                        }
                                    </TableBody>
                                    : null
                            }

                        </MyTable>

                    </div>

                    :

                    <div className={classes.main}>


                        <MyTable
                            style={{ marginTop: '8px' }}
                            stickyHeader
                            sizeheaders="verySmall"
                            size="small">
                            <colgroup>
                                <col width="30%" />
                                <col width="30%" />
                                <col width="40%" />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Data</TableCell>
                                    <TableCell align="right">Valor cota</TableCell>
                                    <TableCell align="right">PL do dia</TableCell>
                                </TableRow>
                            </TableHead>

                            {
                                content && content.data && content.data.quotas_anbima ?

                                    <TableBody>
                                        {
                                            content.data.quotas_anbima.map((row, index) => (


                                                <TableRow className={classes.detailRow}
                                                    key={'detail-quota-' + index}>

                                                    <TableCell align="center">
                                                        {formatAnbimaDateToPt(row.data_referencia)}
                                                    </TableCell>

                                                    <LightTooltip
                                                        disableFocusListener={!row.valor_cota_default}
                                                        disableTouchListener={!row.valor_cota_default}
                                                        disableHoverListener={!row.valor_cota_default}
                                                        title={'Cota específica para esse cliente, o valor padrão da cota é: ' + (row.valor_cota_default ? applyMaskAmountFreeDecimals(row.valor_cota_default, true) : '-')} placement="right" arrow>
                                                        <TableCell align="right" className={[!row.replicated ? classes.blue : classes.warning, row.valor_cota_default ? classes.bold : ''].join(' ')}>

                                                            <>{row.valor_cota != null ? applyMaskAmountFreeDecimals(row.valor_cota, true, 8) : '-'}</>

                                                        </TableCell>
                                                    </LightTooltip>

                                                    <TableCell align="right" className={classes.green}>
                                                        {row.patrimonio_liquido != null ? applyMaskAmount(row.patrimonio_liquido, true) : '-'}
                                                    </TableCell>


                                                </TableRow>



                                            ))

                                        }
                                    </TableBody>
                                    : null
                            }

                        </MyTable>


                    </div>

            }


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }

            <NewSpecialClientQuotaModal
                open={openModalNewSpecialQuotaClient}
                asset={content.data}
                lastDayQuotaInMonth={content ? content.lastDayQuotaInMonth : null}
                month={clientOn.client.selectedMonth}
                year={clientOn.client.selectedYear}
                handleAction={() => setOpenModalNewSpecialQuotaClient(false)}>

            </NewSpecialClientQuotaModal>

            <QuotizationAssetModal
                open={openQuotizationAssetModal}
                asset={content
                    && content.data}
                handleCloseQuotizationModal={() => setOpenQuotizationAssetModal(false)}>

            </QuotizationAssetModal>

            <InfosFundModal
                open={openInfosFundModal}
                fund={content && content.data}
                handleCloseInfosFundModal={() => setOpenInfosFundModal(false)}>

            </InfosFundModal>

        </div >
    );
}




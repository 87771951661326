import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

//Router
import {
    Route,
    Switch
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

//Controllers

import { headerSearch, tabs, unoTheme } from '../../../../assets/styles/unoTheme';

import { MyTab, MyTabs } from "../../../utils/MyTabs";
import { path } from "../../../utils/routesPaths";
import DemonstrativeTab from './tabs/DemonstrativeTab';
import ReturnsIPC14Tab from './tabs/ReturnsIPC14Tab';
import { removeAccents, verifyClientPortfolioIsSgregated } from '../../../utils/utils';
import { TextField } from '@material-ui/core';
import MyToggleSegregationButton from '../../../utils/MyToggleSegregationButton';
import SearchBar from '../../../utils/SearchBar';
import MySelect from '../../../utils/MySelect';
import { listClientAccountsByRegime } from '../../../../API/account';
import allActions from '../../../../actions';
import UnoButton from '../../../utils/UnoButton';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '100px !important',
            width: 'auto',
        }
    },
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '16px',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    flexCenterDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: "12px",
        '& .ipc14-components': {
            display: 'flex',
            alignItems: 'center',
            gap: "12px",
        }
    },
});

export default function RiskScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const [tabValue, setTabValue] = useState(null);

    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        if (newValue == 0) {
            history.push(path.administrative.demonstratives.funds)
        } else if (newValue == 1) {
            history.push(path.administrative.demonstratives.returnsIPC14)
        }

    };

    //IPCA14
    const [segregation, setSegregation] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(undefined);
    const [selectedAccountIndex, setSelectedAccountIndex] = useState(undefined);
    const [clientAccounts, setClientAccounts] = useState([]);

    const handleSegregation = (event, newSegregation) => {

        // if (newSegregation != segregationOnScreen) {
        //     setSegregation(newSegregation);
        // }
        setSegregation(newSegregation);

    };

    const loadAccounts = async (clientId, segregation) => {
        const response = await listClientAccountsByRegime(clientId, segregation);

        console.log("RESPONSE: ", response)

        if (response.success) {

            setClientAccounts(response.body.rows)
            setSelectedAccountIndex(0);

        } else {

            console.log("error: ", response.error)

        }
    }

    useEffect(() => {
        console.log("selectedAccountIndex: ", selectedAccountIndex);
        if (clientAccounts.length) {
            const account = clientAccounts[selectedAccountIndex];
            console.log("account: ", account);
            setSelectedAccount(account);
        }
    }, [selectedAccountIndex])


    //FIM IPC14

    const [searchText, setSearchText] = useState("");

    const delayedChange = (text) => {
        setTimeout(() => {
            setSearchText(text)
        }, 800)
    }

    function changeSearch(e) {

        delayedChange(removeAccents(e.target.value.toUpperCase()))
        clearTimeout(delayedChange);
    }

    function doReport() {
        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'demonstrative',
            message: 'Gerando demonstrativo...'
        }))
    }

    useEffect(() => {
        if (!clientOn?.client) return;
        if (!tabValue) {
            handleChangeTabValue(null, 0)
        }
        if (tabValue === 0) {
            loadAccounts(clientOn.client.id, null); //segregação consolidada
        }
    }, [tabValue])


    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>

                <div className={[classes.tabs, classes.shortWidthTab].join(' ')} style={{ width: "auto" }}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label="Fundos" />
                        <MyTab label="Retornos (IPC14)" disabled />

                    </MyTabs>

                </div>


                <div className={classes.rightHeader}>

                    <TextField
                        style={{ width: "170px", minWidth: "180px" }}
                        onChange={(event) => changeSearch(event)}
                        className={classes.inputSearch}
                        label="Buscar fundo..."
                        variant="outlined"
                        size="small" />

                    {
                        tabValue === 1 && <div className='ipc14-components'>

                            <MySelect
                                customWidth={'200px'}
                                value={selectedAccountIndex}
                                // initblank={'true'}
                                onChange={(e) => setSelectedAccountIndex(e.target.value)}
                                options={clientAccounts.map((row, index) => (
                                    <option key={'account-ipc14-' + row.id} value={index}>
                                        {(row.agency ? 'Ag: ' + row.agency + " - " : "") + 'CC: ' + row.number_account + ' / ' + row.description}
                                    </option>
                                ))}

                            />
                            <MyToggleSegregationButton
                                marginRight
                                value={segregation}
                                handleValue={handleSegregation}
                                // disabled={innerLoad && !innerLoad.emptyMessage}
                                segregated={verifyClientPortfolioIsSgregated(clientOn)}
                            />
                        </div>

                    }

                    {
                        tabValue === 0 &&

                        <UnoButton
                            style={{ marginRight: '16px' }}
                            size={'small'}
                            onClick={() => doReport()}
                            type='primary'
                            startIcon={<PrintIcon />}>
                            {"Gerar relatório"}
                        </UnoButton>
                    }
                </div>


            </div>
            <Switch className={classes.switch}>
                <Route path={path.administrative.demonstratives.returnsIPC14}>
                    <ReturnsIPC14Tab searchText={searchText} selectedAccount={selectedAccount} />
                </Route>
                <Route path={path.administrative.demonstratives.funds}>
                    <DemonstrativeTab searchText={searchText} />
                </Route>

            </Switch>



        </div>
    );
}




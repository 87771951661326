// Data
import React, { useEffect, useState } from 'react';
import { checkFinalLabelDistribuitionTable, distributionOptionClassification, invertColumnOrder, isOptionValidToJustTwoWords } from '../../../../utils/distribution.js';

// Redux
import { useDispatch } from "react-redux";
import allActions from '../../../../../actions';

// Styles
import MyTable from '../../../../utils/MyTable';
import { useColors } from '../../../../../assets/styles/unoTheme';
import { applyMaskAmount } from '../../../../utils/utils';
import { showColumnOrderIcon } from '../../../../utils/distribution';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from './styles';

export default function MyDistributionTable({
  handleGroupByDistributionOptions,
  setColumnOrder,
  columnOrder, selectedDistributionOption, dataToTable, listClientAssets, totalLine
}) {
  const classes = useStyles();
  const colors = useColors();
  const dispatch = useDispatch();
  const { data, mainLabel } = dataToTable;
  const { classe } = distributionOptionClassification;


  const [propertyLabel, setPropertyLabel] = useState(classe);

  /**
     * Verificação se a coluna clicada já é a atualmente selecionada.
     * Se verdadeiro, nesse caso, muda-se a direção da ordenação.
     * Caso seja diferente, apenas aplica-se a order.
     * @param {String} order Ordem da coluna
     */
  function handleColumnOrder(currentColumnOrder) {
    let newColumnOrder = currentColumnOrder;
    if (currentColumnOrder == columnOrder) newColumnOrder = invertColumnOrder(currentColumnOrder);

    setColumnOrder(newColumnOrder);

    let justTwoWordsValue = isOptionValidToJustTwoWords(selectedDistributionOption);

    handleGroupByDistributionOptions(listClientAssets, selectedDistributionOption, justTwoWordsValue, newColumnOrder);
  }

  useEffect(() => {
    Object.entries(distributionOptionClassification).forEach(optionArray => {
      let option = optionArray[1];

      let isOptionSelectedOption = selectedDistributionOption === option;
      if (isOptionSelectedOption) setPropertyLabel(option);
    });

  }, [selectedDistributionOption, dataToTable])

  return (
    <>
      {data ?
        <div className={classes.divTable}>
          <MyTable selectable="true" stickyHeader size="small">
            <colgroup>
              <col width="37%" />
              <col width="25%" />
              <col width="30%" />
              <col width="8%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleColumnOrder('11')} align="left">{mainLabel} {showColumnOrderIcon('1', columnOrder)}</TableCell>
                <TableCell onClick={() => handleColumnOrder('21')} align="center">Percentual {showColumnOrderIcon('2', columnOrder)}</TableCell>
                <TableCell onClick={() => handleColumnOrder('31')} align="right">Valor {showColumnOrderIcon('3', columnOrder)}</TableCell>
                <TableCell align="center">Leg.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                return (
                  <TableRow
                    key={'row-' + index}
                    onClick={() => {
                      const classAssets = listClientAssets.filter((asset) => asset[propertyLabel] === row.label);

                      // console.log("propertyLabel: ", propertyLabel);
                      // console.log("CLASS ASSETS: ", classAssets);
                      // console.log("listClientAssets: ", listClientAssets);
                      // console.log("row.label: ", row.label);

                      dispatch(allActions.rightContentActions.setRightContent({
                        path: 'assetsFromFormat',
                        data: {
                          info: row,
                          assets: classAssets
                        },
                      }))
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {checkFinalLabelDistribuitionTable(row.formatedLabel)}
                    </TableCell>
                    <TableCell align="center">{row.percent}%</TableCell>
                    <TableCell align="right" className={colors.green}>{row.formatedAmount}</TableCell>
                    <TableCell align="center">
                      <div className={classes.legendTable} style={{ background: row.legColor }}></div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>

            {
              data && data.length > 0 ?

                <TableFooter className={classes.myTableFooter}>
                  <TableRow key={'footer-t-distribuition'}>
                    <TableCell align="left">Total</TableCell>
                    <TableCell align="center">
                      <span className={'spanTotal'}>100%</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className={'spanTotal'}>{applyMaskAmount(totalLine, true)}</span>
                    </TableCell>
                    <TableCell align="center"></TableCell>

                  </TableRow>
                </TableFooter>

                : null
            }

          </MyTable>
        </div>
        : null
      }
    </>
  )
}
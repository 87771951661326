import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { formatStringToDisplay, getConsultingId, getMonthName, getPrettyRole, getShortMonthName, getYearsByYearUntilNow, isAuthenticated } from '../utils/utils';
import { checkUserIsRole, roles, rolesSortById } from '../utils/user.js';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { BottomNavigation, BottomNavigationAction, Paper, SvgIcon } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { ENV, LOGOS_URL, isDevENV, APP_VERSION } from '../../API/config';

//Router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { unoTheme } from '../../assets/styles/unoTheme'

import LogoUno from '../../assets/imgs/logo_min_uno_branco.svg'
import LogoUnoMobile from '../../assets/imgs/logo_min_uno_azul.svg'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../actions'

import { me } from '../../API/user'
import {
    listAllActiveClients,
    listUserActiveClients
} from '../../API/client'

import { getDatesWithTrasactions, getYearsWithTrasactions } from '../../API/transaction'

import MyAlert from "../utils/MyAlert";

import MyFullLoad from "../utils/MyFullLoad";


//Backoffice
import InstituitionsScreen from "./administration/InstituitionsScreen";
import QuotaDetailScreen from "./administration/QuotaDetailScreen";
import FundsAndTitlesScreen from "./administration/FundsAndTitlesScreen";
import InflationRatesScreen from "./administration/InflationRatesScreen";
import AnbimaFundsScreen from "./administration/AnbimaFundsScreen";
import LogsScreen from "./administration/LogsScreen";
import FundsRanking from "./administration/FundsRanking";

//Backoffice
import ClientScreen from "./backOffice/ClientScreen";
import DetailClientScreen from "./backOffice/ClientScreen/DetailClientScreen";
import AdvisorsScreen from "./backOffice/AdvisorsScreen";
import NewAdvisorScreen from "./backOffice/NewAdvisorScreen";
import AdminLegislationSegmentsScreen from "./backOffice/AdminLegislationSegmentsScreen";
import AdminLegislationArticlesScreen from "./backOffice/AdminLegislationArticlesScreen";

//Analysis
import ArticlesScreen from "./analysis/ArticlesScreen";
import DistributionScreen from "./analysis/DistributionScreen";
import FundsAnalyticScreen from "./analysis/FundsAnalyticScreen";
import TitlesAnalyticScreen from "./analysis/TitlesAnalyticScreen";
import ReturnsScreen from "./analysis/ReturnsScreen";

//Investiments
import IncomeScreen from "./investimentos/IncomeScreen";
import PortfolioScreen from "./investimentos/PortfolioScreen";
import BalancesCollectScreen from "./investimentos/BalancesCollectScreen";
import BalancesValidationScreen from "./investimentos/BalancesValidationScreen";

//Administrative
import DemonstrativesScreen from "./administrative/Demonstratives/DemonstrativesScreen";
import FilesAndDocumentsScreen from "./administrative/FilesAndDocumentsScreen";

import Tooltip from '@material-ui/core/Tooltip';

import clsx from 'clsx';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AdministrationIcon from '@material-ui/icons/Storage';
import AnalysisIcon from '@material-ui/icons/InsertChart';
import RegistersIcon from '@material-ui/icons/RecentActors';
import AdministrativeIcon from '@material-ui/icons/Ballot';
import BackofficeIcon from '@material-ui/icons/PermDataSetting';

import NotificationsIcon from '@material-ui/icons/Notifications';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

//UnoButton
import UnoButton from '../utils/UnoButton'

import MySelect2 from '../utils/MySelect2'
import {
    getMonthsByArray,
    getYearsByDateUntilNow,
    formatPortfolioClosed,
    getMonthsUntilPortfolioInit,
    getUrlLogo,
    getTheLastDayCompleteMonthPt,
    isMonthAbleToQuotas,
    getNextDayAbleToQuotas
} from '../utils/utils'

import _ from 'lodash';

import { getNextMonthToLaunch } from '../../controllers/ClientController'

import Backdrop from '@material-ui/core/Backdrop';
import MyAutoComplete from "../utils/MyAutoComplete";
import RightContent from "./RightContent";
import ReportLoadModal from "../modals/ReportLoadModal";
import TargetsScreen from "./investimentos/TargetsScreen";
import BladeFundScreen from "./analysis/BladeFundScreen";

import MyInnerLoad, { codsInnerLoad } from '../utils/MyInnerLoad'
import SearchAccountsScreen from "./administration/SearchAccountsScreen";
import MyScrollDialog from "../utils/MyScrollDialog";
import MyButton from "../utils/MyButton";
import MyFormDialog from "../utils/MyFormDialog";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import menuItems from "../dashboard/menuItems";
import IncomeIcon from '@material-ui/icons/TrendingUp';
import { path, clientScreenAllowedPaths } from '../utils/routesPaths';

import moment from 'moment'
import ToolsScreen from "./administrative/ToolsScreen";
import CompareFundsScreen from "./analysis/CompareFundsScreen";
import StatusClientsScreen from "./registers/StatusClientsScreen";
import ActuarysClientsScreen from "./registers/ActuarysClientsScreen";
import ResetPassScreen from "../login/ResetPassScreen";
import LocalBenchmarksScreen from "./administration/LocalBenchmarksScreen";
import ClientsRentsScreen from "./administration/ClientsRentsScreen";
import RiskScreen from "./analysis/RiskScreen";
import { clientViewerPath } from "./rightContent/ClientViewer";
import AdminArticlesRulesScreen from "./registers/AdminArticlesRulesScreen";


const drawerCloseWidth = 80;
const drawerOpenWidth = 224;
const leftFirstDrawerWidth = 64;
const topMainHeight = 66;
//const rightContentWidth = '50%';
//RESPONSIVE_MOBILE_CODE
const bottomNavigattion = 40;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        background: unoTheme.menuContent,

        //RESPONSIVE_MOBILE_CODE
        '& .mobileBottomNavigation': {
            display: 'none !important',
            minHeight: '44px !important',
            maxHeight: '44px !important',
            height: '44px !important',
            borderTop: 'solid 1px #eeeeee',
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            padding: '4px',
            zIndex: '999',

            '@media (max-width: 768px)': {
                display: 'flex !important',
                alignItems: 'center',
                justifyContent: 'space-around'

            }
        }
    },
    logo: {
        width: '30px',
    },
    superContent: {
        display: 'flex',
        width: '100%',
        height: '100%',
        background: 'red',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },

    welcome: {
        display: "flex",
        alignItems: "center",

        "& .enviroment": {
            marginLeft: "16px",
            //RESPONSIVE_MOBILE_CODE
            '@media(max-width: 768px)': {
                display: 'none !important',
            }
        },

        //RESPONSIVE_MOBILE_CODE
        '& .welcomeLeft': {
            '& .welcomeClientLabelName': {
                textAlign: 'left',
                display: 'none',
            },
            '@media(max-width: 768px)': {

                '& .bemVindo, & .titleLema': {
                    fontSize: '12px',
                },

                '& .titleLema': {
                    marginLeft: '4px',
                },

                '& .welcomeLeft1': {
                    display: 'flex',
                    flexDirection: 'row',
                },

                '& .MuiTypography-h6': {
                    lineHeight: 'unset',
                },

                '& .welcomeClientLabelName': {
                    display: 'block',
                },
            }

        }

    },



    leftFirstDrawer: {
        width: leftFirstDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: unoTheme.mainColor,
        height: '100vh',
        position: 'fixed',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {
            display: 'none !important',
        }
    },
    listDrawer: {
        width: 'calc(100% - 20px)',
        '& .MuiButtonBase-root': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '0px',
        },

        '& .centerButtonMenu': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },

        '& .centerButtonMenu2': {
            marginTop: '0px',
            marginBottom: theme.spacing(2),
        },

        '& .MuiListItem-button': {
            borderRadius: '10px',
            '&:hover': {
                color: 'white',
                background: unoTheme.hoverMainColor,
                '& .MuiSvgIcon-root': {
                    fill: 'white'
                }
            }
        }
    },

    selectedMainMenu: {
        color: 'white',
        background: unoTheme.hoverMainColor,
        '& .MuiSvgIcon-root': {
            fill: 'white'
        }
    },

    listMenuDrawer: {
        width: 'calc(100% - 20px)',
        '& .MuiButtonBase-root': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .MuiListItemIcon-root': {
            marginRight: '28px',
            marginLeft: '28px',
            minWidth: '0px',
        },

        '& .centerButtonMenu': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },

        '& .MuiListItem-button': {
            borderRadius: '10px',
            width: '44px',
            height: '44px',
            '& .MuiSvgIcon-root': {
                fontSize: '23px',
                fill: unoTheme.iconMenuColor
            },
            '&:hover': {
                color: unoTheme.mainColor,
                background: 'white',
                '& .MuiSvgIcon-root': {
                    fill: unoTheme.mainColor,
                }
            }
        },


    },

    selectedSubMenu: {
        color: unoTheme.mainColor,
        background: 'white',
        '& .MuiSvgIcon-root': {
            fill: unoTheme.mainColor + ' !important',
        }
    },

    menuText: {
        marginLeft: theme.spacing(1),
    },

    realList: {
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    realListNameUno: {
        minHeight: '66px',
    },

    iconLeftFirstDrawer: {
        fontSize: 30,
        color: '#5E95F2'
    },

    dontShow: {
        visibility: 'hidden'
    },

    centerMenuLeftFirstDrawer: {
        background: 'red'
    },

    drawer: {
        width: drawerCloseWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'fixed',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {
            display: 'none !important',
        }
    },

    nameUno: {
        fontSize: '16px',
        fontWeight: 700,
        marginTop: '27px',
    },

    white: {
        color: 'white',
    },
    logoutItem: {
        marginBottom: '20px',
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingRight1: {
        marginRight: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    spacingRight2: {
        marginRight: theme.spacing(2)
    },

    closedMonth: {
        background: 'white',
        borderRadius: '10px',
        padding: '5px 14px',
    },


    //MenuContent
    menuContent: {
        marginLeft: leftFirstDrawerWidth,
        display: 'flex',
        height: '100%',
        width: '100%',
        background: unoTheme.menuContent,

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {
            marginLeft: '0px !important',
        }
    },


    //MainFatherContent
    mainFatherContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        transition: 'all .5s ease',
        overflow: 'hidden',

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {
            marginLeft: '0px !important',
        }
    },

    drawerOpen: {
        marginLeft: drawerOpenWidth,

    },
    drawerClose: {
        marginLeft: drawerCloseWidth,
    },

    topMain: {
        borderBottom: 'solid 1px #f4f4f4',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        height: topMainHeight,
        minHeight: topMainHeight,
        width: `calc(100% - ${leftFirstDrawerWidth}px)`,
        background: 'white',
        zIndex: 995,
        justifyContent: 'space-between',

        '& .bemVindo': {
            fontSize: '10px',
            margin: '0px',
            textAlign: 'left'

        },

        '& .titleLema': {
            fontSize: '16px',
            fontWeight: 600,
            color: unoTheme.mainColor,
            margin: '0px',
            textAlign: 'left'

        },

        '& .roleCurrentUser': {
            marginLeft: '8px',
            fontSize: '12px',
            fontWeight: 400,
            margin: '0px',

            //RESPONSIVE_MOBILE_CODE
            '@media(max-width: 768px)': {
                display: 'none !important',
            }
        },

        '& .iconMobile': {
            width: '10%',
            display: 'none',
            marginRight: '16px',
        },

        '& .topRightMobile': {
            display: 'none',

            '& #months-in-portfolio-mobile': {
                minWidth: '30px',
            },

            '& #years-in-portfolio-mobile': {
                minWidth: '35px',
            },
        },

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {

            '& .iconMobile': {
                display: 'block',
            },

            '& .topRightMobile': {
                display: 'flex',
                alignItems: 'center'
            },

            width: '100% !important',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            justifyContent: 'space-between',
        },


    },

    topMainRight: {
        display: 'flex',
        alignItems: 'center',

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {
            display: 'none !important',
        }

    },

    content: {
        zIndex: 996,
        flexGrow: 1,
        marginTop: topMainHeight,
        //padding: theme.spacing(3),
        background: unoTheme.menuContent,
        borderRadius: '10px 0px 0px 0px !important',
        //overflowY: 'scroll',
        '& .switch': {
            //background: 'red',
            transition: 'all .3s ease',
            //height: 'calc(100% - ' + (topMainHeight + 72) + 'px)',
            height: '100%',
            position: 'fixed',
            width: 'calc(100% - ' + (leftFirstDrawerWidth + drawerCloseWidth) + 'px)', //width = 100% menos os dois menus laterais
            //marginTop: '-65px',
            marginTop: '0px',

            '& .defaultScrollWithHeaderContent': {

                display: 'flex',
                height: `calc(100vh - ${topMainHeight + 72}px)`, //96 é o atual tamanho do headerSearch + 24px do marginBottom
                borderRadius: '10px 0px 0px 0px !important',
                background: 'white',
                marginBottom: '24px',
                background: 'white',
            },

            //RESPONSIVE_MOBILE_CODE
            '@media(max-width: 768px)': {

                '& .switchScreenRoot': {
                    height: `calc(100% - ${bottomNavigattion}px)`,
                },
                height: `calc(100% - ${bottomNavigattion}px)`,
                width: '100% !important',
            }

        },

    },

    autoCompleteClient: {
        width: '240px',
        '& .MuiFormControl-root': {
            margin: '0px',
            '& .MuiInputBase-root': {
                margin: '0px',
            }
        }
    },

    bold: {
        fontWeight: 'bold'
    },

    backDiv: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '8px',
        }
    },

    backdropDashboard: {
        zIndex: 997,
    },

    iconButton: {
        backgroundColor: unoTheme.mainColor,
        color: 'white',
        padding: '8px 10px',
        borderRadius: '10px',

        '&:hover': {
            background: unoTheme.hoverMainColor,
        },

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {
            display: 'none !important',
        }
    },
    minHeightMonths: {
        width: '140px',
    },

    logoClient: {
        marginLeft: '16px',
        width: '42px',
        borderRadius: '10px',
        border: 'solid 1px ' + unoTheme.borderInputColor,

        //RESPONSIVE_MOBILE_CODE
        '@media(max-width: 768px)': {
            display: 'none !important',
        }
    },

    //RESPONSIVE_MOBILE_CODE
    '@media(max-width: 768px)': {
        display: 'block !important',
    }


}));

const useRightContentStyles = rightContentWidth => makeStyles((theme) => ({

    rightExpansiveContent: {
        width: rightContentWidth,
        height: '100vh',
        background: 'white',
        position: 'fixed',
        right: 0,
        borderRadius: '0px',
        zIndex: 998,
        transition: 'all .5s ease',
    },

    rightExpansiveContentOpen: {
        marginRight: '0px',
    },

    rightExpansiveContentClose: {
        marginRight: '-' + rightContentWidth,
    },

}));

export default function UnoDashboard() {

    const classes = useStyles();
    const rightContentClass = useRightContentStyles('50%')();
    const extraSizeRightContentClass = useRightContentStyles('70%')();

    const history = useHistory();

    const location = useLocation();
    const [locationOnScreen, setLocationOnScreen] = useState(undefined);

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)
    const activeClients = useSelector(state => state.activeClients)
    const mainAlert = useSelector(state => state.mainAlert)
    const fullLoad = useSelector(state => state.fullLoad)
    const innerLoad = useSelector(state => state.innerLoad)
    const notifications = useSelector(state => state.notifications);
    /**
     * @type {import('../../reducers/scrollDialog.js').ScrollDialog}
     */
    const scrollDialog = useSelector(state => state.scrollDialog);

    const dispatch = useDispatch();

    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const { setScrollDialog } = allActions.scrollDialogActions;

    const openScrollDialog = useMemo(() => scrollDialog?.open, [scrollDialog]);
    /**
     * @type {import('../../reducers/scrollDialog.js').ScrollDialog}
     */
    const improvementsDialogType = useMemo(() => scrollDialog?.type, [scrollDialog]);
    const scrollDialogData = useMemo(() => scrollDialog.data, [scrollDialog])

    const [openImprovementsRegistrationDialog, setOpenImprovementsRegistrationDialog] = useState(false);
    const [openConfirmActionModal, setOpenConfirmActionModal] = useState(false);

    const [receivedImprovement, setReceivedImprovement] = useState(null);

    const [updateImprovementsDialog, setUpdateImprovementsDialog] = useState(false);

    const [deleteImprovementTrigger, setDeleteImprovementTrigger] = useState(false);

    // const [selectedMonth, setSelectedMonth] = useState([]);
    // const [selectedYear, setSelectedYear] = useState([]);
    const [formattedYearsMonths, setFormattedYearsMonths] = useState({});
    const [formattedPortfolioYearsMonths, setFormattedPortfolioYearsMonths] = useState({});
    const [showMonths, setShowMonths] = useState(true);
    const [showYears, setShowYears] = useState(true);

    const [nextDayAbleToQuotas, setNextDayAbleToQuotas] = useState('');

    const currentMonth = new Date().getMonth() + 1;
    const nextYearPolicy = new Date().getFullYear() + 1;

    const [nextYearPolicySelected, setNextYearPolicySelected] = useState(false);

    // const [months, setMonths] = useState([]);
    // const [years, setYears] = useState([]);

    const [openRightExpansiveContent, setOpenRightExpansiveContent] = useState(false);

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selectedMainMenu, setSelectedMainMenu] = React.useState('investimentMenu');
    //const [selectedMainMenu, setSelectedMainMenu] = React.useState('investimentMenu');

    const [backButton, setBackButton] = useState(false);

    //String que representa o que deverá ser rendenrizado na tela expansiva
    //Opções:
    //=== 'detailBalanceValidation'    
    const rightContent = useSelector(state => state.rightContent)

    function handleImprovementDeletion(action) {

        setOpenConfirmActionModal(false);
        if (action) {
            setDeleteImprovementTrigger(true)
            setOpenImprovementsRegistrationDialog(false)
            dispatch(setScrollDialog({
                open: true,
                type: 'notifications'
            }));
        }
    }

    async function getMe() {

        console.log("CALL ME")
        const responseMe = await me();
        console.log("RESPONSE ME: ", responseMe)
        if (responseMe
            && !responseMe.success
            && responseMe.body
            && (
                responseMe.body.name == "TokenExpiredError"
                || responseMe.body.name == "JsonWebTokenError"
                || responseMe.body.message == "Usuário inativo"
            )) {

            console.log("Realizar lógica que reloga e dá refresh no token")
            dispatch(allActions.userActions.logOut())

        } else if (responseMe && responseMe.success && !responseMe.body.active) {
            dispatch(allActions.userActions.logOut())
        } else {
            dispatch(allActions.userActions.setUser(responseMe.body))

        }

        if (
            responseMe.body.role == "ROLE_ADMIN"
            || responseMe.body.role == "ROLE_ADVISOR"
            || responseMe.body.role == "ROLE_MANAGER"
            || responseMe.body.role == "ROLE_SUBMANAGER"
            || responseMe.body.role == roles.superadmin.title
            || responseMe.body.isViewer
        ) {

            if (
                responseMe.body.role == "ROLE_MANAGER"
                || responseMe.body.role == "ROLE_SUBMANAGER"
                || responseMe.body.isViewer
            ) {

                console.log("RESPONSE ME MANAGER: ", responseMe.body);
                await getUserActiveClients(responseMe.body.id);

            } else {

                await getActiveClients(responseMe.body.consulting_id);

            }

            //history.push('/portfolio/assets');
            // history.push('/advisors');
            // history.push('/quotas')
            // history.push('/collectionOfBalances')
            // history.push('/transactions')
            // history.push('/articles')
        } else {
            console.log("GO TO PORTFOLIO")
            history.push('/portfolio');
        }

        setBackButton(false);
    }

    function doSelectMainMenu(menu) {

        //Sempre zerando a mensagem de inner load
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
            cod: codsInnerLoad.unoDashboard
        }));

        setSelectedMainMenu(menu)

        //Limpando a lista de submenus
        Object.entries(menuItems).map((element) => {
            menuItems[element[0]].forEach(element => {
                delete element.selected
            });
        })

        //Selecionando o primeiro menu da lista de submenus
        setBackButton(false);

        //let pathToRedirect = menuItems[menu][1].path;
        let pathToRedirect = null;
        const viewerCase = menu === 'administrativeMenu' && currentUser?.user?.isViewer;

        if (menu === 'investimentMenu' && clientOn && clientOn.client && !clientOn.client.portfolio_closed) {

            //Indo para menu de carteira pois ainda não tem tela de rentabilidade
            menuItems[menu][1].selected = true;
            pathToRedirect = menuItems[menu][1].path;
        } else if (viewerCase) {
            const FILES_SUBMENU_INDEX = 0;
            const TOOLS_SUBMENU_INDEX = 2;

            menuItems[menu][FILES_SUBMENU_INDEX].selected = true;
            pathToRedirect = menuItems[menu][FILES_SUBMENU_INDEX].path;
        } else {

            menuItems[menu][0].selected = true;

            pathToRedirect = menuItems[menu][0].path;
        };

        return history.push(pathToRedirect);
    };

    function updateSelectsMonthsAndYears() {

        let monthsUntilPortfolioInit = getMonthsUntilPortfolioInit(clientOn.client.portfolio_init, clientOn.client.portfolio_closed);
        setFormattedPortfolioYearsMonths(monthsUntilPortfolioInit);

        clientOn.client.yearsPortfolio = monthsUntilPortfolioInit.years;

        //Inicializando selectedPortfolioMonth e selectedPortfolioYear do client com os últimos registros obtidos            

        let monthsPortfolio = monthsUntilPortfolioInit.months;
        let yearsPortfolio = monthsUntilPortfolioInit.years;

        if (yearsPortfolio
            && monthsPortfolio) {

            if (monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]]) {

                clientOn.client.selectedPortfolioMonth = parseInt(monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]][
                    monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]].length - 1
                ]);
            }
        }

        clientOn.client.selectedPortfolioYear = parseInt(monthsUntilPortfolioInit.years[monthsUntilPortfolioInit.years.length - 1]);

        clientOn.client.monthsPortfolio = getMonthsByArray(monthsUntilPortfolioInit.months[
            //monthsUntilPortfolioInit.years[0]
            //monthsUntilPortfolioInit.years[monthsUntilPortfolioInit.years.length - 1]
            clientOn.client.selectedPortfolioYear
        ]);
    }

    function formatTransactionsMonthsAndYears(data, nextMonth, nextYear) {

        let yearsWithMonths = {}
        let years = [];

        console.log("data formatTransactionsMonthsAndYears: ", data);

        data.forEach(element => {

            let splitted = element.monthyear.split('/');
            let m = splitted[0]
            let y = splitted[1]
            if (!yearsWithMonths[y]) {
                yearsWithMonths[y] = [];
                years.push(parseInt(y));
            }

            if (!yearsWithMonths[y].includes(m)) {
                yearsWithMonths[y].push(parseInt(m));
                yearsWithMonths[y].sort(function sortfunction(a, b) {
                    return (a - b)
                });
            }
        });

        years.sort(function sortfunction(a, b) {
            return (a - b)
        });

        ///////////////////////////////////////////////////////
        ///Inicializando para o primeiro mês do primeiro ano///
        ///////////////////////////////////////////////////////

        // clientOn.client.months = getMonthsByArray(yearsWithMonths[years[0]]);
        // clientOn.client.years = years;        
        // clientOn.client.selectedMonth = parseInt(yearsWithMonths[years[0]][0]);
        // clientOn.client.selectedYear = parseInt(years[0]);

        ///////////////////////////////////////////////////////
        ///////Inicializando com o próximo mês aberto//////////
        ///////////////////////////////////////////////////////

        //Necessário testar qual o próximo mês a ser fechado pra utilizar na instancia do ano
        //clientOn.client.months = getMonthsByArray(yearsWithMonths[years[years.length - 1]]);
        let yearColeta = nextYear;

        const monthsColeta = getMonthsByArray(yearsWithMonths[yearColeta]);
        if (monthsColeta.length == 0) {
            clientOn.client.months = getMonthsByArray(yearsWithMonths[yearColeta - 1]);
        } else {
            clientOn.client.months = monthsColeta;
        }
        console.log("MONTHS COLETA: ", clientOn.client.months);
        clientOn.client.years = years;
        console.log("YEARS COLETA: ", clientOn.client.years);

        clientOn.client.yearsValidation = getYearsByDateUntilNow(clientOn.client.portfolio_init);
        if (clientOn.client.yearsValidation.length >= 2) {
            clientOn.client.selectedValidationYear = clientOn.client.yearsValidation[clientOn.client.yearsValidation.length - 1];
        } else {
            clientOn.client.selectedValidationYear = clientOn.client.yearsValidation[0];
        }


        //Inicializando selects que serão utilizados nas telas de carteira        
        if (clientOn.client.portfolio_init && clientOn.client.portfolio_closed) {

            updateSelectsMonthsAndYears();

        } else if (clientOn.client.portfolio_closed == null) {
            //Geralmente disparado no rest de carteira            
            clientOn.client.monthsPortfolio = [];
            clientOn.client.yearsPortfolio = [];
            clientOn.client.selectedPortfolioMonth = null;
            clientOn.client.selectedPortfolioYear = null;
        }

        //Inicializando mes e ano da coleta com a soma de 1 a última carteira fechada
        if (clientOn.client.selectedPortfolioMonth) {

            //const addMonth = isMonthAbleToQuotas(clientOn.client.selectedPortfolioMonth + 1, nextYear);
            const addMonth = isMonthAbleToQuotas(nextMonth, nextYear);
            if (addMonth) {
                clientOn.client.selectedMonth = nextMonth;
                clientOn.client.selectedYear = nextYear;
            } else {

                console.log("ENTROU XDF");
                clientOn.client.selectedMonth = clientOn.client.selectedPortfolioMonth;
                clientOn.client.selectedYear = clientOn.client.selectedPortfolioYear;
            }
            //clientOn.client.selectedMonth = clientOn.client.selectedPortfolioMonth == 12 ? 1 : (addMonth ? clientOn.client.selectedPortfolioMonth + 1 : clientOn.client.selectedPortfolioMonth);
            //clientOn.client.selectedYear = clientOn.client.selectedPortfolioMonth == 12 ? clientOn.client.selectedPortfolioYear + 1 : clientOn.client.selectedPortfolioYear;

        } else {

            clientOn.client.selectedMonth = parseInt(yearsWithMonths[years[0]][0]);
            clientOn.client.selectedYear = parseInt(years[0]);

        }

        clientOn.client.selectedAdministrativeMonth = parseInt(yearsWithMonths[years[0]][0]);
        clientOn.client.selectedAdministrativeYear = parseInt(years[0]);


        dispatch(allActions.clientActions.setClient(clientOn.client));

        return yearsWithMonths;
    }

    function handleChangeMonth(event) {
        //setSelectedMonth(event.target.value)
        clientOn.client.selectedMonth = parseInt(event.target.value);
        dispatch(allActions.clientActions.setClient(clientOn.client))

    }

    function handleChangeYear(event) {

        //setSelectedYear(event.target.value)
        //setMonths(getMonthsByArray(formattedYearsMonths[event.target.value]));
        clientOn.client.months = getMonthsByArray(formattedYearsMonths[event.target.value]);
        clientOn.client.selectedYear = parseInt(event.target.value);

        if (!clientOn.client.months.some(el => el.id == clientOn.client.selectedMonth)) {
            clientOn.client.selectedMonth = clientOn.client.months[0].id;
        }

        console.log("ALTERANDO ANO: ", parseInt(event.target.value))
        console.log("clientOn.client.months: ", clientOn.client.months)
        console.log("selectedMonth: ", parseInt(clientOn.client.selectedMonth))
        console.log("selectedYear: ", parseInt(clientOn.client.selectedYear))
        dispatch(allActions.clientActions.setClient(clientOn.client))

    }

    function handleChangeMonthAdministrative(event) {
        //setSelectedMonth(event.target.value)
        clientOn.client.selectedAdministrativeMonth = parseInt(event.target.value);
        dispatch(allActions.clientActions.setClient(clientOn.client))

    }

    function handleChangeYearAdministrative(event) {

        //setSelectedYear(event.target.value)
        //setMonths(getMonthsByArray(formattedYearsMonths[event.target.value]));
        clientOn.client.months = getMonthsByArray(formattedYearsMonths[event.target.value]);
        clientOn.client.selectedAdministrativeYear = parseInt(event.target.value);

        if (!clientOn.client.months.some(el => el.id == clientOn.client.selectedAdministrativeMonth)) {
            clientOn.client.selectedAdministrativeMonth = clientOn.client.months[0].id;
        }

        dispatch(allActions.clientActions.setClient(clientOn.client))

    }

    //Tela gerencial de fechamentos
    const initYearClosure = 2015;
    const yearsClosures = getYearsByYearUntilNow(initYearClosure, 1);
    const [selectedYearClosure, setSelectedYearClosure] = useState(new Date().getFullYear())
    function handleChangeYearClosures(event) {

        setSelectedYearClosure(event.target.value);
    }

    //Tela de APRS AVULSAS
    const initYearSingleAPRS = 2011;
    const yearsSingleAPRS = getYearsByYearUntilNow(initYearSingleAPRS);
    const [selectedYearSingleAPRS, setSelectedYearSingleAPRS] = useState(new Date().getFullYear())
    function handleChangeYearSingleAPRS(event) {

        setSelectedYearSingleAPRS(event.target.value);
    }

    function handleChangeValidationYear(event) {

        clientOn.client.selectedValidationYear = parseInt(event.target.value);

        console.log("ALTERANDO ANO VALIDATION YEAR: ", parseInt(event.target.value))
        dispatch(allActions.clientActions.setClient(clientOn.client))

    }

    function handleChangePortfolioMonth(event) {
        //setSelectedMonth(event.target.value)
        clientOn.client.selectedPortfolioMonth = parseInt(event.target.value);
        dispatch(allActions.clientActions.setClient(clientOn.client))

    }
    function handleChangePortfolioYear(event) {

        //setSelectedYear(event.target.value)
        //setMonths(getMonthsByArray(formattedYearsMonths[event.target.value]));
        // clientOn.client.portfolioMonths = getMonthsByArray(formatPortfolioClosed[event.target.value]);
        // console.log('clientOn.client.portfolioMonths: ', clientOn.client.portfolioMonths);

        if (event.target.value != clientOn.client.selectedPortfolioYear) {
            //console.log('formattedPortfolioYearsMonths.months: ', formattedPortfolioYearsMonths.months);
            clientOn.client.monthsPortfolio = getMonthsByArray(formattedPortfolioYearsMonths.months[
                //monthsUntilPortfolioInit.years[0]
                //monthsUntilPortfolioInit.years[monthsUntilPortfolioInit.years.length - 1]
                event.target.value
            ]);

            //Caso não haja esse mês lançado no ano deve-se deslocar para o primeiro mês presente no ano
            if (!formattedPortfolioYearsMonths.months[
                event.target.value
            ].includes(clientOn.client.selectedPortfolioMonth)) {
                clientOn.client.selectedPortfolioMonth = formattedPortfolioYearsMonths.months[
                    event.target.value
                ][0];
            }

            clientOn.client.selectedPortfolioYear = parseInt(event.target.value);
            dispatch(allActions.clientActions.setClient(clientOn.client))
        }


    }

    async function getUserActiveClients(userId) {

        const responseClients = await listUserActiveClients(userId);
        console.log("CLIENTS: ", responseClients)
        //setClients(responseClients.body.rows)
        if (responseClients
            && responseClients.body
            && responseClients.body.rows
            && responseClients.body.rows.length > 0) {

            console.log("Clients recebidos: ", responseClients.body.rows);
            dispatch(allActions.clientActions.setActiveClients(responseClients.body.rows))
            console.log("SETTING CLIENT ON: ", responseClients.body.rows[0])
            dispatch(allActions.clientActions.setClient(responseClients.body.rows[0]))

            //Caso o cliente não tenha carteira fechada ele é enviado pra tela de portfolio
            if (!responseClients.body.rows[0].portfolio_closed) {

                handleSelectSubMenu(1, '/portfolio/assets');

            } else {

                handleSelectSubMenu(0, '/investiments/income');

            }

        }
    }

    async function getActiveClients(consultingId) {

        const responseClients = await listAllActiveClients(consultingId);
        console.log("CLIENTS: ", responseClients)
        //setClients(responseClients.body.rows)
        if (responseClients
            && responseClients.body
            && responseClients.body.rows
            && responseClients.body.rows.length > 0) {

            console.log("Clients recebidos: ", responseClients.body.rows);
            dispatch(allActions.clientActions.setActiveClients(responseClients.body.rows))


            console.log("SETTING CLIENT ON: ", responseClients.body.rows[0])

            //Ultimo cliente selecionado
            let lastClientSelected = localStorage.getItem('last_client_selected');
            if (lastClientSelected != undefined && lastClientSelected != null) {

                let clientLocalStorage = responseClients.body.rows.filter(el => el.id == lastClientSelected);
                if (clientLocalStorage.length == 1) {

                    dispatch(allActions.clientActions.setClient(clientLocalStorage[0]))
                    //Caso o cliente não tenha carteira fechada ele é enviado pra tela de portfolio
                    if (!clientLocalStorage[0].portfolio_closed) {

                        handleSelectSubMenu(1, '/portfolio/assets');

                    } else {

                        handleSelectSubMenu(0, '/investiments/income');

                    }

                } else {

                    dispatch(allActions.clientActions.setClient(responseClients.body.rows[0]))
                    //Caso o cliente não tenha carteira fechada ele é enviado pra tela de portfolio
                    if (!responseClients.body.rows[0].portfolio_closed) {

                        handleSelectSubMenu(1, '/portfolio/assets');

                    } else {

                        handleSelectSubMenu(0, '/investiments/income');

                    }

                }

            } else {

                dispatch(allActions.clientActions.setClient(responseClients.body.rows[0]))

                //Caso o cliente não tenha carteira fechada ele é enviado pra tela de portfolio
                if (!responseClients.body.rows[0].portfolio_closed) {

                    handleSelectSubMenu(1, '/portfolio/assets');

                } else {

                    handleSelectSubMenu(0, '/investiments/income');

                }
            }

        } else {
            dispatch(allActions.clientActions.setActiveClients([]))
        }
    }

    const handleChangeClient = (event, newValue) => {
        //setSelectedClient(event.target.value)
        dispatch(allActions.clientActions.setClient(newValue))
    };

    const handleDrawer = (open) => {
        setOpen(open);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSelectSubMenu = (index, subMenuPath) => {

        //Sempre zerando a mensagem de inner load
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
            cod: codsInnerLoad.unoDashboard
        }));

        //Gerar relatório geral, sem alterar a tela atual
        if (subMenuPath === '/analysis/full_report') {

            if (clientOn && clientOn.client && clientOn.client.portfolio_closed != null) {
                dispatch(allActions.reportLoadActions.setReportLoad({
                    show: true,
                    reportType: 'fullReport',
                    message: 'Gerando o relatório geral...'
                }))
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: 'Esse cliente não possui nenhuma carteira lançada'
                }))
            }

        } else {

            Object.entries(menuItems).map((element) => {
                menuItems[element[0]].forEach(element => {
                    delete element.selected
                });
            });

            setBackButton(false);

            menuItems[selectedMainMenu][index].selected = true;

            return history.push(subMenuPath);
        };
    };

    function handleBack() {

        setBackButton(false)
        let menus = menuItems[selectedMainMenu].filter(element => element.selected);
        history.push(menus[0].path);

    }

    const handleMenuLogout = () => {

        localStorage.clear();
        dispatch(allActions.clientActions.setClient(null));
        console.log("TOKEN AS DESLOGAR: ", localStorage.getItem("token"));
        history.push('/login');
    };

    async function loadInfosClient(client) {

        let referenceDate = getTheLastDayCompleteMonthPt();

        console.log("LAST DAY COMPLETE MONTH: ", referenceDate);

        const splittedreferenceDate = referenceDate.split('/');
        const m = parseInt(splittedreferenceDate[1]);
        const y = parseInt(splittedreferenceDate[2]);
        if (!isMonthAbleToQuotas(m, parseInt(y))) {

            setNextDayAbleToQuotas(getNextDayAbleToQuotas(m, parseInt(y)));
            //referenceDate = getMonthAgo(parseInt(splittedreferenceDate[1]), parseInt(splittedreferenceDate[2]), 1);
            if (m == 1) {
                referenceDate = moment(new Date(y - 1, 12, 0)).format('MM/YYYY');
            } else {
                referenceDate = moment(new Date(y, m - 1, 0)).format('MM/YYYY');
            }
        } else {
            referenceDate = m + '/' + y;
        }

        const responseDatesToLounch = getMonthsUntilPortfolioInit(client.portfolio_init, referenceDate);
        console.log('responseDatesToLounch: ', responseDatesToLounch)

        //let realDates = responseDates.body.rows;
        let realDates = [];

        //formatando para realDates
        Object.entries(responseDatesToLounch.months).map(([year, months]) => {
            months.forEach(element => {
                realDates.push({ monthyear: element + "/" + year })
            });
        });

        //Garantindo a inclusão do mês após a última carteira fechada
        //ou do primeiro mês a ter a carteira lançada no caso de ainda não ter lançado nenhuma carteira        
        //let responseNextMonth = getNextMonthToLaunch(clientOn.client);
        let responseNextMonth = getNextMonthToLaunch(client);
        //Ex retorno:
        // {
        //     nextMonth: 1,
        //     nextYear: 2020,
        //     formatted: '1/2020'
        // }
        console.log("Next month: ", responseNextMonth);

        //Inclui o próximo mês apenas se for um mês propício a lançamento
        if (isMonthAbleToQuotas(responseNextMonth.nextMonth, responseNextMonth.nextYear)) {
            //Testando se já existe a data
            if ((realDates.filter(rd => rd.monthyear == responseNextMonth.formatted)).length == 0) {
                realDates.push({
                    monthyear: responseNextMonth.formatted
                });
            }
        }

        console.log("realDates: ", realDates);
        //Caso tenha datas com movimentação
        if (realDates && realDates.length > 0) {

            setFormattedYearsMonths(formatTransactionsMonthsAndYears(realDates, responseNextMonth.nextMonth, responseNextMonth.nextYear))

        } else {

            console.log("PASSOU YEARS")
            clientOn.client.yearsValidation = getYearsByDateUntilNow(clientOn.client.portfolio_init);
            if (clientOn.client.yearsValidation.length >= 2) {
                clientOn.client.selectedValidationYear = clientOn.client.yearsValidation[clientOn.client.yearsValidation.length - 1];
            } else {
                clientOn.client.selectedValidationYear = clientOn.client.yearsValidation[0];
            }
            dispatch(allActions.clientActions.setClient(clientOn.client));

        }

    }

    function handleBackTopButton() {

        console.log("LOCATION PATHNAME BACK: ", location.pathname);
        if (location && location.pathname.includes('/blade')) {
            history.goBack();
        } else {
            history.push('/newClient');
        };

    };

    const [bottomNavigationValue, setBottomNavigationValue] = useState(0);

    function handleMenuBottomNavigation(newValue) {
        setBottomNavigationValue(newValue);
        if (newValue === 1) {
            dispatch(allActions.userActions.logOut())
        }

    }

    function isTherePageThatDealsWithLocalization() {
        if (
            (location && location.pathname.includes(path.administration.clients.root))
            ||
            (location && location.pathname.includes(path.administration.funds.root))
            ||
            (location && location.pathname.includes(path.administration.client.details.root))
        ) {
            return true;
        }
        return false;
    };

    function isExtraSizeRightContent() {
        if (rightContent
            && (
                rightContent.path == 'detailBalanceCollection'
                || rightContent.path == 'fundsFromArticle'
                || rightContent.path == 'returnsFundsIpc14'
            )
        ) {
            return true;
        } else { return false };
    }

    useEffect(() => {

        console.log("USE EFFECT DASHBOARD: ", currentUser)

        if (!isAuthenticated()) {
            history.push('/login');
        }

        if (!currentUser.user
            && currentUser.loggedIn) { //jump of the cat
            console.log("PROCESSO 1")
            setBackButton(false)
            getMe();
        }

        //Recarregando a página pelo endpoint raiz
        if (currentUser
            //&& location.pathname == '/'
            && !currentUser.user
            && currentUser.loggedIn != false
            && isAuthenticated()) { //jump of the cat
            console.log("PROCESSO 2")
            setBackButton(false)
            getMe();
        }

        //Acabou de se logar
        if (currentUser
            && currentUser.user
            && !currentUser.user.role
            && currentUser.loggedIn) {
            console.log("PROCESSO 3")
            setBackButton(false)
            getMe();
        }

        if (currentUser && currentUser.loggedIn == false) {
            console.log("PROCESSO 4")
            setBackButton(false)
            handleMenuLogout();
        };

        const isCurrentPathNameLocationOnScreenPathName = location && locationOnScreen && location.pathname === locationOnScreen.pathname;
        if (((location && !locationOnScreen)
            ||
            (!isCurrentPathNameLocationOnScreenPathName))
            && (!isTherePageThatDealsWithLocalization())
        ) {
            let newBackButtonStatus = false;
            let newShowMonthsStatus = true;

            console.log("PROCESSO 5");
            setShowYears(true);
            setLocationOnScreen(location);

            if (location.pathname == '/transactions/balancesValidation') {

                newShowMonthsStatus = false;
                if (clientOn.client) {

                    clientOn.client.yearSelected = clientOn.client.yearsValidation[0];
                    dispatch(allActions.clientActions.setClient(clientOn.client));
                }

            } else if (location && location.pathname.includes('/blade')) {
                newBackButtonStatus = true;
            };
            setShowMonths(newShowMonthsStatus);
            setBackButton(newBackButtonStatus);
        }

        //BKP CASO DÊ ALGUM ERRO

        // if (clientOn.client != clientOnScreen
        //     //No caso de outra tela atualizar os dados
        //     || (clientOn.client && clientOn.client.updateInfosClient)) {

        //     delete clientOn.client.updateInfosClient
        //     dispatch(allActions.clientActions.setClient(clientOn.client));

        //     setClientOnScreen(clientOn.client)
        //     loadInfosClient(clientOn.client.id);

        // }

        //Quando ocorre a troca de cliente pelo autocomplete no topo da tela
        if ((clientOn.client && !clientOnScreen)
            ||
            (clientOn.client && clientOnScreen && clientOn.client.id != clientOnScreen.id)
            //No caso de outra tela atualizar os dados
            //No dispatch na tela deve se colocar updateInfosClient = true
            || (clientOn.client && clientOn.client.updateInfosClient)) {

            console.log("PROCESSO 6")

            delete clientOn.client.updateInfosClient
            dispatch(allActions.clientActions.setClient(clientOn.client));
            setClientOnScreen(_.cloneDeep(clientOn.client))


            //Caso não haja carteira fechada não é exibido o menu de rentabildade
            if (!clientOn.client.portfolio_closed
                && location.pathname == '/investiments/income') {

                handleSelectSubMenu(1, '/portfolio/assets')

            }

            console.log("CHAMANDO LOAD INFOS");
            //loadInfosClient(clientOn.client.id);
            loadInfosClient(clientOn.client);

            localStorage.setItem("last_client_selected", clientOn.client.id);

        }

        if (clientOn.client && !clientOnScreen
            || (clientOn.client
                && clientOnScreen
                && clientOn.client.id != clientOnScreen.id
            )
            //&& clientOn.client != clientOnScreen
            //No caso de outra tela atualizar os dados
            || (clientOn.client && clientOn.client.updateInfosPortfolio)) {

            console.log("PROCESSO 7");
            console.log("CURRENT USER PROCESSO 7: ", currentUser)

            //Inicializando selects que serão utilizados nas telas de carteira        
            if (clientOn.client.portfolio_init && clientOn.client.portfolio_closed) {

                updateSelectsMonthsAndYears();
            }

            delete clientOn.client.updateInfosPortfolio
            dispatch(allActions.clientActions.setClient(clientOn.client));

            setClientOnScreen(_.cloneDeep(clientOn.client))

        }


    }, [currentUser,
        clientOn,
        activeClients,
        location]);

    const badgeContent = useMemo(() => notifications?.length, [notifications]);

    return (
        <div className={classes.root}>
            {/* <CssBaseline /> */}
            <div className={classes.superContent}>
                <div className={classes.leftFirstDrawer}>
                    <div className={classes.listDrawer}>
                        <List className={[classes.realList, classes.realListNameUno]}>

                            <ListItemText key={'uno-item'} className={[classes.white, classes.nameUno].join(' ')}>
                                <Tooltip arrow title={APP_VERSION}>
                                    <img src={LogoUno} className={classes.logo} />
                                </Tooltip>
                            </ListItemText>

                        </List>
                    </div>
                    <div className={classes.listDrawer}>
                        <List className={classes.realList}>

                            <Tooltip button key={'investimentMenu'} title="Investimentos" enterDelay={100} leaveDelay={100} placement="right" arrow>
                                <ListItem
                                    className={selectedMainMenu == 'investimentMenu' ? classes.selectedMainMenu : ""}
                                    onClick={() => doSelectMainMenu('investimentMenu')}>
                                    <ListItemIcon>
                                        <AttachMoneyIcon className={classes.iconLeftFirstDrawer} />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>

                            <Tooltip key={'analyticsMenu'} title="Análises" enterDelay={100} leaveDelay={100} placement="right" arrow>
                                <ListItem button
                                    className={[

                                        selectedMainMenu == 'analyticsMenu' ? classes.selectedMainMenu : "",
                                        'centerButtonMenu'

                                    ].join(' ')}
                                    onClick={() => doSelectMainMenu('analyticsMenu')}>
                                    <ListItemIcon>
                                        <AnalysisIcon className={classes.iconLeftFirstDrawer} />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>

                            {
                                currentUser
                                    && currentUser.user
                                    && (
                                        currentUser.user.role === roles.admin.title
                                        ||
                                        currentUser.user.role === roles.advisor.title
                                        ||
                                        currentUser.user.role === roles.manager.title
                                        ||
                                        currentUser.user.role === roles.superadmin.title
                                    ) ?

                                    <Tooltip button key={'registersMenu'} title="Cadastros" enterDelay={100} leaveDelay={100} placement="right" arrow>
                                        <ListItem
                                            className={[selectedMainMenu == 'registersMenu' ? classes.selectedMainMenu : "", 'centerButtonMenu2'].join(' ')}
                                            onClick={() => doSelectMainMenu('registersMenu')}>
                                            <ListItemIcon>
                                                <AdministrationIcon className={classes.iconLeftFirstDrawer} />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip> : null
                            }

                            {
                                checkUserIsRole(currentUser, rolesSortById) ?

                                    <Tooltip button key={'administrativeMenu'} title="Administrativo" enterDelay={100} leaveDelay={100} placement="right" arrow>
                                        <ListItem
                                            className={[selectedMainMenu == 'administrativeMenu' ? classes.selectedMainMenu : "", 'centerButtonMenu2'].join(' ')}
                                            onClick={() => doSelectMainMenu('administrativeMenu')}>
                                            <ListItemIcon>
                                                <AdministrativeIcon className={classes.iconLeftFirstDrawer} />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip> : null
                            }

                            {
                                currentUser
                                    && currentUser.user
                                    && ((currentUser.user.role == "ROLE_ADMIN") ||
                                        currentUser.user.role === roles.superadmin.title) ?

                                    <Tooltip button key={'backofficeMenu'} title="Backoffice" enterDelay={100} leaveDelay={100} placement="right" arrow>
                                        <ListItem
                                            className={selectedMainMenu == 'backofficeMenu' ? classes.selectedMainMenu : ""}
                                            onClick={() => doSelectMainMenu('backofficeMenu')}>
                                            <ListItemIcon>
                                                <BackofficeIcon className={classes.iconLeftFirstDrawer} />
                                            </ListItemIcon>
                                        </ListItem>
                                    </Tooltip> : null
                            }
                        </List>
                    </div>
                    <div className={classes.listDrawer}>
                        <List className={[classes.realList, classes.logoutItem].join(' ')}>

                            <ListItem button key={'menu-logout'}
                                onClick={() => dispatch(allActions.userActions.logOut())}>
                                <ListItemIcon >
                                    <ExitToAppIcon className={classes.iconLeftFirstDrawer} />
                                </ListItemIcon>
                            </ListItem>

                        </List>
                    </div>
                </div>
                <div className={classes.menuContent}>
                    <div className={classes.drawer} /*onClick={() => handleDrawer(!open)}*/>
                        <div className={classes.listMenuDrawer}>
                            <List className={classes.realList}>
                                {menuItems[selectedMainMenu].map((row, index) => (
                                    <>
                                        {index == 0 ? <br /> : null}
                                        {

                                            //Caso a role do usuário pertença a uma das roles do menu 
                                            row.roles.includes(currentUser
                                                && currentUser.user
                                                && currentUser.user.role)

                                                ||//ou                                                

                                                //Seja um cliente específico liberado para o menu
                                                (clientOn
                                                    && clientOn.client && row.specificClientsIds
                                                    && row.specificClientsIds.includes(clientOn.client.id))

                                                ||//ou

                                                //Seja um usuário específico liberado para o menu
                                                (clientOn
                                                    && clientOn.client && row.specificUsersIds
                                                    && row.specificUsersIds.includes(currentUser?.user?.id))

                                                ?

                                                row.justPortfolioOn
                                                    && clientOn
                                                    && clientOn.client
                                                    && !clientOn.client.portfolio_closed ?
                                                    null
                                                    :
                                                    <>
                                                        <Tooltip key={'tooltip-' + row.name} title={row.name} enterDelay={100} leaveDelay={100} placement="right" arrow>
                                                            <ListItem button key={'item-menu-' + row.name}
                                                                className={row.selected ? classes.selectedSubMenu : ""}
                                                                onClick={() => handleSelectSubMenu(index, row.path)}>
                                                                <ListItemIcon>
                                                                    {row.icon ? row.icon : <HomeIcon />}
                                                                </ListItemIcon>
                                                                {open ? <ListItemText primary={row.name} /> : null}
                                                            </ListItem>
                                                        </Tooltip>
                                                        <br />
                                                    </>

                                                : null
                                        }

                                    </>
                                ))}

                            </List>
                        </div>

                    </div>
                    <div className={classes.topMain}>
                        {
                            backButton ?
                                <div className={classes.backDiv} onClick={() => handleBack()}>
                                    <UnoButton
                                        onClick={() => handleBackTopButton()}
                                        className={classes.spacingLeft2}
                                        type='default'
                                        startIcon={<NavigateBeforeIcon />}>
                                        {"Voltar"}
                                    </UnoButton>

                                </div>
                                :
                                <>

                                    <div className={classes.welcome}>

                                        <img src={LogoUnoMobile} className={'iconMobile'} />
                                        <div className="welcomeLeft">

                                            <div className="welcomeLeft1">
                                                <Typography className={'bemVindo'}>
                                                    {
                                                        'Bem vindo, '
                                                    }
                                                </Typography>
                                                <Typography variant='h6'>
                                                    {
                                                        currentUser && currentUser.user ?
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span className={'titleLema'}>{currentUser && currentUser.user && currentUser.user.name ? formatStringToDisplay(currentUser.user.name, 2) : ''}</span>
                                                                <span className={'roleCurrentUser'}>{'[' + getPrettyRole(currentUser.user.role) + ']'}</span>
                                                            </div>
                                                            : ""
                                                    }

                                                </Typography>
                                            </div>
                                            <Typography className="welcomeClientLabelName">
                                                {clientOn?.client?.label_name}
                                            </Typography>

                                        </div>

                                        <div className="enviroment">
                                            {isDevENV ? "DEV " : null}
                                            {ENV == "HOMOLOG" ? "HOMOLOG " : null}
                                            <span style={{ color: unoTheme.warningColorStrong, fontSize: '12px' }}>{checkUserIsRole(currentUser, [roles.admin, roles.superadmin, roles.advisor]) && nextDayAbleToQuotas != '' ?
                                                ' Próx lançamento: '
                                                // + getMonthName(nextDayAbleToQuotas.month()) + "/" + nextDayAbleToQuotas.year() + " > "
                                                + nextDayAbleToQuotas.format('DD/MM/YYYY') : ''}</span>
                                        </div>
                                    </div>
                                    <div className={classes.topMainRight}>

                                        {/* Meses coleta de saldo do cliente */}
                                        {
                                            clientOn.client
                                                && clientOn.client.months
                                                && clientOn.client.months.length > 0
                                                && location.pathname == '/transactions/balancesCollect'
                                                && showMonths ?
                                                <MySelect2
                                                    id={'months-in-collect'}
                                                    disabled={innerLoad.show
                                                        //&& innerLoad.loadMessage != "" 
                                                        && innerLoad.loadMessage == ""
                                                        && !clientOn.client.portfolio_closed}
                                                    value={clientOn.client.selectedMonth}
                                                    className={[classes.spacingLeft2, classes.minHeightMonths].join(' ')}
                                                    onChange={handleChangeMonth}
                                                    options={clientOn.client.months.map((row, index) => (
                                                        <option key={'month-dashboard-' + index} className={classes.optionNativeSelect} value={row.id}>
                                                            {row.name}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                        {/* Anos Coleta de saldo do cliente */}
                                        {
                                            clientOn.client
                                                && clientOn.client.years
                                                && clientOn.client.years.length > 0
                                                && location.pathname == '/transactions/balancesCollect'
                                                && showYears ?
                                                <MySelect2
                                                    id={'years-in-collect'}
                                                    disabled={innerLoad.show
                                                        //&& innerLoad.loadMessage != "" 
                                                        && innerLoad.loadMessage == ""
                                                        && !clientOn.client.portfolio_closed}
                                                    className={classes.spacingLeft2}
                                                    value={clientOn.client.selectedYear}
                                                    onChange={handleChangeYear}
                                                    options={clientOn.client.years.map((row, index) => (
                                                        <option key={'year-dashboard-' + index} className={classes.optionNativeSelect} value={row}>
                                                            {row}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                        {/* Meses da CARTEIRA do cliente */}
                                        {
                                            clientOn.client
                                                && clientOn.client.monthsPortfolio
                                                && clientOn.client.monthsPortfolio.length > 0
                                                && (location.pathname.includes('/portfolio')
                                                    || location.pathname.includes('/analysis')
                                                    || location.pathname.includes('/income')
                                                    || location.pathname.includes('/administrative/demonstrative'))

                                                //Não deve ser exibido na tela de fundos
                                                //&& !location.pathname.includes('/analysis/funds')
                                                && showMonths
                                                && !nextYearPolicySelected ?
                                                <MySelect2
                                                    disabled={innerLoad.show}
                                                    id={'months-in-portfolio'}
                                                    value={clientOn.client.selectedPortfolioMonth}
                                                    className={[classes.spacingLeft2, classes.minHeightMonths].join(' ')}
                                                    onChange={handleChangePortfolioMonth}
                                                    options={clientOn.client.monthsPortfolio.map((row, index) => (
                                                        <option key={'month-dashboard-' + index} className={classes.optionNativeSelect} value={row.id}>
                                                            {row.name}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                        {/* Anos da CARTEIRA do cliente */}
                                        {
                                            clientOn.client
                                                && clientOn.client.yearsPortfolio
                                                && clientOn.client.yearsPortfolio.length > 0
                                                && (location.pathname.includes('/portfolio')
                                                    || location.pathname.includes('/analysis')
                                                    || location.pathname.includes('/income')
                                                    || location.pathname.includes('/administrative/demonstrative'))
                                                //Não deve ser exibido na tela de fundos
                                                //&& !location.pathname.includes('/analysis/funds')
                                                && showYears
                                                && !nextYearPolicySelected ?
                                                <MySelect2
                                                    disabled={innerLoad.show}
                                                    id={'years-in-portfolio'}
                                                    className={classes.spacingLeft2}
                                                    value={clientOn.client.selectedPortfolioYear}
                                                    onChange={handleChangePortfolioYear}
                                                    options={clientOn.client.yearsPortfolio.map((row, index) => (
                                                        <option key={'year-dashboard-' + index} className={classes.optionNativeSelect} value={row}>
                                                            {row}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                        {/* Anos utilizados na tela de validação de saldos */}
                                        {
                                            clientOn.client
                                                && clientOn.client.yearsValidation
                                                && clientOn.client.yearsValidation.length > 0
                                                && (
                                                    // location.pathname == '/transactions/balancesValidation/funds'
                                                    // || location.pathname == '/transactions/balancesValidation/accounts'
                                                    location.pathname.includes('/transactions/balancesValidation')
                                                ) ?
                                                <MySelect2
                                                    className={classes.spacingLeft2}
                                                    //value={clientOn.client.selectedYear}
                                                    value={clientOn.client.selectedValidationYear}
                                                    onChange={handleChangeValidationYear}
                                                    options={clientOn.client.yearsValidation.map((row, index) => (
                                                        <option className={classes.optionNativeSelect} value={row}>
                                                            {row}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                        {/* Anos das APRS avulsas do cliente */}
                                        {
                                            clientOn.client
                                                && yearsSingleAPRS
                                                && yearsSingleAPRS.length > 0
                                                && location.pathname == '/administrative/tools' ?
                                                <MySelect2
                                                    id={'years-in-single-aprs'}
                                                    disabled={innerLoad.show
                                                        && innerLoad.loadMessage == ""}
                                                    className={classes.spacingLeft2}
                                                    value={selectedYearSingleAPRS}
                                                    onChange={handleChangeYearSingleAPRS}
                                                    options={yearsSingleAPRS.map((row, index) => (
                                                        <option key={'year-single-aprs-' + index} className={classes.optionNativeSelect} value={row}>
                                                            {row}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                        {/* Anos para a tela gerencial de fechamentos */}
                                        {
                                            clientOn.client
                                                && clientOn.client.years
                                                && clientOn.client.years.length > 0
                                                && (location.pathname == path.registers.statusClients.root
                                                    || location.pathname == path.registers.actuarysClients.root) ?
                                                <MySelect2
                                                    id={'years-in-closures'}
                                                    disabled={innerLoad.show
                                                        && innerLoad.loadMessage == ""
                                                        && !clientOn.client.portfolio_closed}
                                                    className={classes.spacingLeft2}
                                                    value={selectedYearClosure}
                                                    onChange={handleChangeYearClosures}
                                                    options={yearsClosures.map((row, index) => (
                                                        <option key={'year-in-closure-' + index} className={classes.optionNativeSelect} value={row}>
                                                            {row}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }


                                        {/* SELETOR DE ANO POLÍTICA */}
                                        {
                                            nextYearPolicySelected ?
                                                <MySelect2
                                                    disabled={true}
                                                    value={0}
                                                    className={[classes.spacingLeft2, classes.minHeightMonths].join(' ')}
                                                    options={
                                                        <option key={'month-next-policy'} className={classes.optionNativeSelect} value={0}>
                                                            {'Janeiro'}
                                                        </option>
                                                    }
                                                /> : null
                                        }
                                        {
                                            nextYearPolicySelected ?
                                                <MySelect2
                                                    disabled={true}
                                                    className={classes.spacingLeft2}
                                                    value={0}
                                                    options={
                                                        <option key={'policy-next-year'}
                                                            className={classes.optionNativeSelect}
                                                            value={0}>
                                                            {nextYearPolicy}
                                                        </option>
                                                    }
                                                /> : null
                                        }
                                        {
                                            clientOn.client
                                                && clientOn.client.yearsPortfolio
                                                && clientOn.client.yearsPortfolio.length > 0
                                                && clientOn.client.yearsPortfolio[clientOn.client.yearsPortfolio.length - 1] != nextYearPolicy
                                                && currentMonth >= 9 //Mostrado só a partir de setembro
                                                && (location.pathname.includes('/analysis/articles/list'))
                                                && !currentUser?.user?.isViewer
                                                ?
                                                <UnoButton
                                                    style={{ marginLeft: '16px' }}
                                                    type={'primaryClean'}
                                                    onClick={() => setNextYearPolicySelected(!nextYearPolicySelected)}>
                                                    {!nextYearPolicySelected ? 'Visualizar política ' + nextYearPolicy : 'Retornar para ' + clientOn.client.selectedPortfolioYear}
                                                </UnoButton> : null
                                        }



                                        {

                                            clientOn && clientOn.client ?
                                                null
                                                :
                                                <>

                                                    {
                                                        activeClients && activeClients.length > 0 ?
                                                            <Typography variant={'body1'}>
                                                                {'Selecione o cliente >'}
                                                            </Typography>
                                                            : null
                                                    }

                                                </>
                                        }

                                        {/* //LOGO MUNICIPIO */}
                                        {
                                            clientOnScreen && clientOnScreen.logo_path ? <img className={classes.logoClient} src={getUrlLogo(clientOnScreen)}></img> : null
                                        }
                                        <div className="clientAutoCompleteContainer">

                                            {
                                                activeClients && activeClients.length > 1 ?


                                                    <MyAutoComplete
                                                        disabled={innerLoad.show && innerLoad.loadMessage != "" && innerLoad.loadMessage != null}
                                                        options={activeClients}
                                                        autoComplete
                                                        customWidth={'320px'}
                                                        className={[classes.spacingLeft2].join(' ')}
                                                        value={
                                                            clientOn && clientOn.client ? clientOn.client : null
                                                        }
                                                        onChange={(event, newValue) => {
                                                            console.log("New value: ", newValue)
                                                            handleChangeClient(event, newValue)
                                                        }}
                                                        placeholder={"Selecione o cliente"}
                                                        getOptionLabel={(option) => (ENV === "DEV" || ENV === "HOMOLOG" ? option.id + " / " : "") + option.label_name}
                                                        getOptionSelected={(option, value) => option.id === value.id}
                                                        renderOption={(option) => (
                                                            <React.Fragment key={'autocomplete-' + option.id}>
                                                                <span>{option.label_name}</span>
                                                            </React.Fragment>
                                                        )}
                                                    />
                                                    :
                                                    <>
                                                        {
                                                            activeClients && activeClients.length == 1 ?
                                                                <Typography className={classes.spacingLeft2}>{
                                                                    activeClients[0].city_name
                                                                    +
                                                                    " - "
                                                                    +
                                                                    activeClients[0].uf
                                                                }</Typography> : null
                                                        }

                                                    </>

                                            }
                                        </div>


                                        <IconButton
                                            className={[classes.spacingLeft2, classes.iconButton].join(' ')}
                                            onClick={() => dispatch(setScrollDialog({
                                                open: true,
                                                type: 'notifications'
                                            }))}>
                                            <Badge badgeContent={badgeContent} color="error">
                                                <NotificationsIcon />
                                            </Badge>
                                        </IconButton>

                                    </div>

                                    <div className="topRightMobile">
                                        {/* Meses da CARTEIRA do cliente MOBILE*/}
                                        {
                                            clientOn.client
                                                && clientOn.client.monthsPortfolio
                                                && clientOn.client.monthsPortfolio.length > 0
                                                && (location.pathname.includes('/portfolio')
                                                    || location.pathname.includes('/analysis')
                                                    || location.pathname.includes('/income')
                                                    || location.pathname.includes('/administrative/demonstrative'))

                                                //Não deve ser exibido na tela de fundos
                                                //&& !location.pathname.includes('/analysis/funds')
                                                && showMonths
                                                && !nextYearPolicySelected ?
                                                <MySelect2
                                                    verysmall
                                                    disabled={innerLoad.show}
                                                    id={'months-in-portfolio-mobile'}
                                                    value={clientOn.client.selectedPortfolioMonth}
                                                    className={[classes.spacingLeft2].join(' ')}
                                                    onChange={handleChangePortfolioMonth}
                                                    options={clientOn.client.monthsPortfolio.map((row, index) => (
                                                        <option key={'month-dashboard-' + index} className={classes.optionNativeSelect} value={row.id}>
                                                            {getShortMonthName(row.id)}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                        {/* Anos da CARTEIRA do cliente MOBILE*/}
                                        {
                                            clientOn.client
                                                && clientOn.client.yearsPortfolio
                                                && clientOn.client.yearsPortfolio.length > 0
                                                && (location.pathname.includes('/portfolio')
                                                    || location.pathname.includes('/analysis')
                                                    || location.pathname.includes('/income')
                                                    || location.pathname.includes('/administrative/demonstrative'))
                                                //Não deve ser exibido na tela de fundos
                                                //&& !location.pathname.includes('/analysis/funds')
                                                && showYears
                                                && !nextYearPolicySelected ?
                                                <MySelect2
                                                    verysmall
                                                    disabled={innerLoad.show}
                                                    id={'years-in-portfolio-mobile'}
                                                    className={classes.spacingLeft2}
                                                    value={clientOn.client.selectedPortfolioYear}
                                                    onChange={handleChangePortfolioYear}
                                                    options={clientOn.client.yearsPortfolio.map((row, index) => (
                                                        <option key={'year-dashboard-' + index} className={classes.optionNativeSelect} value={row}>
                                                            {row}
                                                        </option>
                                                    ))}
                                                /> : null
                                        }

                                    </div>
                                </>
                        }


                    </div>

                    <div className={
                        clsx(classes.mainFatherContent, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })
                    }>
                        {/* className={classes.mainFatherContent} */}
                        <main className={classes.content}>
                            <div className={`switch ${mainAlert.show ? 'mainAlert' : ''}`}>

                                <Switch className={classes.switch}>


                                    {/* BACKOFFICE */}
                                    <Route path={path.administration.clients.root}>
                                        <ClientScreen
                                            setBackButton={setBackButton}
                                            locationOnScreen={locationOnScreen}
                                            setLocationOnScreen={setLocationOnScreen}
                                            setShowMonths={setShowMonths}
                                            setShowYears={setShowYears}
                                        />
                                    </Route>
                                    <Route path="/administration/client/details">
                                        <DetailClientScreen
                                            setBackButton={setBackButton}
                                            locationOnScreen={locationOnScreen}
                                            setLocationOnScreen={setLocationOnScreen}
                                            setShowMonths={setShowMonths}
                                            setShowYears={setShowYears}
                                        />
                                    </Route>
                                    <Route path="/administration/advisors">
                                        <AdvisorsScreen />
                                    </Route>
                                    <Route path="/administration/newAdvisor">
                                        <NewAdvisorScreen />
                                    </Route>

                                    <Route path="/administration/instituitions">
                                        <InstituitionsScreen />
                                    </Route>
                                    <Route path="/administration/logs">
                                        <LogsScreen
                                            locationOnScreen={locationOnScreen}
                                            setLocationOnScreen={setLocationOnScreen}
                                            setShowMonths={setShowMonths}
                                            setShowYears={setShowYears}
                                        />
                                    </Route>
                                    <Route path="/administration/fundsRanking">
                                        <FundsRanking />
                                    </Route>

                                    <Route path={path.administration.clientsRents.root}>
                                        <ClientsRentsScreen />
                                    </Route>

                                    <Route path="/administration/localBenchmarks">
                                        <LocalBenchmarksScreen />
                                    </Route>

                                    <Route path="/administration/searchAccounts">
                                        <SearchAccountsScreen />
                                    </Route>
                                    {/* <Route path="/administration/funds">
                                        <FundsScreen />
                                    </Route> */}
                                    <Route path={path.administration.funds.root}>
                                        <FundsAndTitlesScreen
                                            setBackButton={setBackButton}
                                            locationOnScreen={locationOnScreen}
                                            setLocationOnScreen={setLocationOnScreen}
                                            setShowMonths={setShowMonths}
                                            setShowYears={setShowYears}
                                        />
                                    </Route>
                                    <Route path="/administration/inflation_rates">
                                        <InflationRatesScreen />
                                    </Route>
                                    <Route path="/administration/anbima">
                                        {/* <QuotasScreen /> */}
                                        <AnbimaFundsScreen />
                                    </Route>

                                    {/* ANALYSIS */}
                                    <Route path="/analysis/articles">
                                        <ArticlesScreen nextYearPolicySelected={nextYearPolicySelected} />
                                    </Route>
                                    <Route path="/analysis/returns">
                                        <ReturnsScreen />
                                    </Route>
                                    <Route path={path.analysis.funds}>
                                        <FundsAnalyticScreen />
                                    </Route>
                                    <Route path={path.analysis.titles}>
                                        <TitlesAnalyticScreen />
                                    </Route>
                                    <Route path="/blade/my">
                                        <BladeFundScreen />
                                    </Route>
                                    <Route path="/blade/all">
                                        <BladeFundScreen />
                                    </Route>
                                    <Route path="/analysis/distribuition">
                                        <DistributionScreen />
                                    </Route>
                                    <Route path={path.analysis.compareFunds}>
                                        <CompareFundsScreen />
                                    </Route>
                                    <Route path={path.analysis.risk.root}>
                                        <RiskScreen />
                                    </Route>

                                    {/* REGISTERS */}
                                    <Route path={path.registers.statusClients.root}>
                                        <StatusClientsScreen selectedYearClosure={selectedYearClosure} />
                                    </Route>
                                    <Route path={path.registers.actuarysClients.root}>
                                        <ActuarysClientsScreen selectedYearClosure={selectedYearClosure} />
                                    </Route>

                                    <Route path="/administration/admin_legislation_segments">
                                        <AdminLegislationSegmentsScreen />
                                    </Route>
                                    <Route path="/administration/admin_legislation_articles">
                                        <AdminLegislationArticlesScreen />
                                    </Route>
                                    <Route path="/administration/admin_articles_rules">
                                        <AdminArticlesRulesScreen />
                                    </Route>

                                    {/* INVESTIMENTS */}
                                    <Route path="/transactions/balancesCollect">
                                        <BalancesCollectScreen />
                                    </Route>
                                    <Route path="/transactions/balancesValidation">
                                        <BalancesValidationScreen />
                                    </Route>
                                    <Route path="/investiments/income">
                                        <IncomeScreen handleSelectSubMenu={handleSelectSubMenu} />
                                    </Route>
                                    <Route path="/portfolio">
                                        <PortfolioScreen />
                                    </Route>
                                    <Route path="/investiments/targets">
                                        <TargetsScreen />
                                    </Route>

                                    <Route path="/quotaDetail">
                                        <QuotaDetailScreen />
                                    </Route>
                                    {/* <Route path="/importExcel">
                                        <ImportExcel />
                                    </Route> */}

                                    {/* ADMINISTRATIVE */}
                                    <Route path={path.administrative.demonstratives.root}>
                                        <DemonstrativesScreen />
                                    </Route>
                                    <Route path={path.administrative.accreditation}>
                                        <FilesAndDocumentsScreen />
                                    </Route>
                                    <Route path={path.administrative.tools}>
                                        <ToolsScreen selectedYearSingleAPRS={selectedYearSingleAPRS} />
                                    </Route>

                                </Switch>

                            </div>

                            {
                                innerLoad
                                    && innerLoad.show
                                    && !fullLoad.show ?
                                    <MyInnerLoad
                                        innerLoad={innerLoad}
                                        size={'small'} /> : null
                            }

                            {/* <MyInnerLoad innerLoad={{
                                show: true,
                                loadMessage: "Carregando..."
                            }}
                                size={'small'} /> */}


                        </main>

                        <BottomNavigation
                            showLabels
                            className={"mobileBottomNavigation"}
                            value={bottomNavigationValue}
                            onChange={(event, newValue) => {
                                handleMenuBottomNavigation(newValue);
                            }}>
                            <BottomNavigationAction label="Dashboard" icon={<IncomeIcon />} />
                            <BottomNavigationAction label="Sair" icon={<ExitToAppIcon />} />
                        </BottomNavigation>


                    </div>


                    <MyFullLoad fullLoad={fullLoad} />
                </div>


            </div >

            <Backdrop
                transitionDuration={500}
                onClick={() => rightContent?.path === clientViewerPath ? dispatch(allActions.rightContentActions.update()) : dispatch(allActions.rightContentActions.setRightContent(null))}
                className={classes.backdropDashboard} open={rightContent != null
                    && rightContent.action != "update"} />
            <ReportLoadModal />

            <Paper
                elevation={3}
                className={
                    clsx(!isExtraSizeRightContent() ? rightContentClass.rightExpansiveContent : extraSizeRightContentClass.rightExpansiveContent, {
                        [!isExtraSizeRightContent() ? rightContentClass.rightExpansiveContentOpen : extraSizeRightContentClass.rightExpansiveContentOpen]: rightContent != null,
                        [!isExtraSizeRightContent() ? rightContentClass.rightExpansiveContentClose : extraSizeRightContentClass.rightExpansiveContentClose]: rightContent == null,
                    })
                }>
                {/* content contém as informações trazidas do redux */}
                <RightContent content={rightContent} />
            </Paper>

            <MyScrollDialog
                open={openScrollDialog}
                data={scrollDialogData}
                type={improvementsDialogType}
                update={updateImprovementsDialog}
                handleListUpdate={() => setUpdateImprovementsDialog(false)}
                handleClose={() => dispatch(setScrollDialog({
                    open: false,
                    type: improvementsDialogType
                }))}
                handleOpenRegistration={(improvement) => {
                    setOpenImprovementsRegistrationDialog(true)
                    setReceivedImprovement(improvement)
                }} />

            <MyFormDialog
                open={openImprovementsRegistrationDialog}
                receivedImprovement={receivedImprovement}
                deleteTrigger={deleteImprovementTrigger}
                handleListUpdate={() => setUpdateImprovementsDialog(true)}
                handleDeleteImprovement={() => setOpenConfirmActionModal(true)}
                handleDeleteTrigger={() => setDeleteImprovementTrigger(false)}
                handleClose={() => {
                    setOpenImprovementsRegistrationDialog(false)
                    dispatch(setScrollDialog({
                        open: true,
                        type: 'notifications'
                    }));
                }} />

            <ConfirmActionModal
                open={openConfirmActionModal}
                title={'Excluir melhoria'}
                message={'Tem certeza de que deseja excluir essa melhoria?'}
                handleAction={handleImprovementDeletion}>
            </ConfirmActionModal>

            <MyAlert alert={mainAlert} />


        </div >
    );
}

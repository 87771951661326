import API from './config'

export const BalancesValidationsAPI = {

    async getBalancesByMonthAndYear(clientId, month, year) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/balances_validations/getBalancesByMonthAndYear?client_id=${clientId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async getSumBalancesByMonthAndYear(clientId, month, year) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/balances_validations/getSumBalancesByMonthAndYear?client_id=${clientId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                data: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async getAllClientsSumBalancesByMonthAndYearAndConsultingId(month, year, consultingId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/balances_validations/getAllClientsSumBalancesByMonthAndYearAndConsultingId?consulting_id=${consultingId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                data: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async getBalancesByPeriodAndTitleAssetsIds(clientId, startDate, endDate) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/balances_validations/getBalancesByPeriodAndTitleAssetsIds?client_id=${clientId}&start_date=${startDate}&end_date=${endDate}`, config);

            return {
                success: true,
                data: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    }


}
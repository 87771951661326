import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useRef, useState } from "react";
import {
    applyMaskAmountFreeDecimals,
    checkAssetsAndSubAssetsSameBalances,
    checkAssetsAndSubAssetsSameFinalQuotas,
    checkIsSameBalance,
    checkIsSameQuotas,
    getBusinessDaysInMonth,
    getFirstDayMonth,
    getInMonthRentFundInPortfolio,
    getLastDayMonthAgo,
    getLastMonthYearAbleToQuotas,
    getMonthFundRentInMoney,
    getMonthsByArray,
    getPortfolioMonthRentInMoney,
    getTitleCod,
    isDateBeforeInit,
    portfolioClosedToDate,
    stringPtDateToMoment
} from '../../utils/utils';

import Fade from '@material-ui/core/Fade';

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';

//Utils
import {
    applyMaskAmount,
    getMonthName,
    isDateAfterClosedMonth,
    isFileForClientOn,
    justNumbers,
    verifyClientPortfolioIsSgregated
} from '../../utils/utils';

import moment from 'moment';
import { codsInnerLoad } from '../../utils/MyInnerLoad';

//Api
import {
    createBalanceValidation,
    getPortfolioClosed,
    saveClientRent,
    updateClientClosedMonth
} from '../../../API/client';
import {
    getAllClientAssets,
    getAllClientFunds,
    getAllFunds,
    getFundByCnpj
} from '../../../API/fund';
import {
    getAllClientTitles, getClientAssetsTitles
} from '../../../API/title';
import {
    checkAlreadyImportedMonth,
    createTransaction,
    getOperations
} from '../../../API/transaction';


import Checkbox from '@material-ui/core/Checkbox';

//Grid
import { Divider, Typography } from "@material-ui/core";

//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

//Import excel
import { getJsDateFromExcel } from 'excel-date-to-js';
import XLSX from 'xlsx';
import { SheetJSFT } from '../../utils/types';

import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import PublishIcon from '@material-ui/icons/Publish';

//UnoButton
import { headerSearch, tabs, unoTheme } from '../../../assets/styles/unoTheme';
import UnoButton from '../../utils/UnoButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DoneIcon from '@material-ui/icons/Done';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import _ from 'lodash';
import AmortizationsToCreateModal from "../../modals/AmortizationsToCreateModal";
import DataImportModal from "../../modals/DataImportModal";
import InconsistentFundsModal from "../../modals/InconsistentFundsModal";
import OffAssetsValidateModal from "../../modals/OffAssetsValidateModal";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

import { getClientLastQuota } from '../../../API/client';
import { doGetAccountIdByNumberAccountAndCnpjAsset } from '../../../controllers/AccountController';
import { getAssetInfos, NO_INIT_QUOTA } from '../../../controllers/AssetsController';
import { doSaveFundClientRent, groupFundsBySegmentAndArticle, validatorFundsClientRents } from '../../../controllers/FundController';
import { checkAssetTitleDivergentBalance, doSaveTitleClientRent, GENERIC_ASSET_TITLES_TYPE, isGenericTitle, needQuotasTitle, OUT_OF_MARKET_RENT_TITLE, verifyTitlesAmortizations } from '../../../controllers/TitleController';

import Backdrop from '@material-ui/core/Backdrop';

import { saveLogPortfolioLaunch } from "../../../controllers/LogActionController";
import { sendLaunchPortofolioEmail } from "../../../controllers/MailController";
import { getRentInMoneyMonthTitle, getRentMonthTitle } from "../../../controllers/TitleController";
import InconsistentTitlesModal from '../../modals/InconsistentTitlesModal';
import { MyTab, MyTabs } from "../../utils/MyTabs";
import MyToggleSegregationButton from "../../utils/MyToggleSegregationButton";
import { MARKING_CURVE } from "../../utils/paramsToTitles";
import { checkUserIsRole, roles } from "../../utils/user";
import TransactionsTable from "./TransactionsTable";
import DefaultScrollWithHeaderContent from '../../utils/DefaultScrollWithHeaderContent';
import { SystemValidationsController } from '../../../controllers/SystemValidationsController';

const rightDivWidth = 450;

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #E6E8ED",
        color: "#4A4A4A"
    }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },

    headerSearch: headerSearch,

    iconWhite: {
        color: 'white',
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    headerDetail: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        }
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    input: {
        display: 'none',
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    optionNativeSelect: {
        padding: "10px !important"
    },
    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    tableTh: {
        fontWeight: 'bold'
    },
    cellStatusApr: {
        paddingTop: '13px',
    },
    textCenter: {
        textAlign: 'center',
    },
    textAutoComplete: {
        fontSize: '14px',
    },
    bold: {
        fontWeight: 'bold',
    },
    monthYear: {
        color: unoTheme.secondaryColor
    },

    //RightDiv
    mainRoot: {
        height: '100%',
        width: '100%',
        transition: 'all .3s ease',
    },
    rightDiv: {
        height: '100%',
        minWidth: rightDivWidth,
        width: rightDivWidth,
        background: 'white',
        transition: 'all .3s ease',
        position: 'fixed',
        right: 0,
        //paddingRight: theme.spacing(2),
        marginRight: rightDivWidth * (-1),
    },
    rightDivOpen: {
        height: '100%',
        //marginRight: rightDivWidth - 24,
        marginRight: rightDivWidth,
        borderRight: `solid 24px ${unoTheme.menuContent}`,
    },
    rightDivClose: {
        height: '100%',
        marginRight: '0px',
    },
    rightDivContentOpen: {
        marginRight: '0px',

    },
    rightDivContentClose: {
        marginRight: rightDivWidth * (-1),
    },
    leftRightDiv: {
        background: unoTheme.menuContent,
        height: '100%',
        width: '24px',
        minWidth: '24px',
    },
    mainRightDiv: {
        float: 'right',
        height: '100%',
        //width: rightDivWidth - 24,
        //minWidth: rightDivWidth - 24,        
        width: rightDivWidth,
        minWidth: rightDivWidth,
    },
    openButton: {
        transition: 'all .3s ease',
    },
    rightDivButtonOpen: {
        //opacity: 0,
        display: 'none',
        cursor: 'unset'
    },
    rightDivButtonClose: {
        //opacity: 1,
        display: 'flex',
        cursor: 'pointer',
    },
    headerRightDiv: {
        display: "flex",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        },

        '& .MuiIconButton-root': {
            padding: '8px',
        }
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    formRightDiv: {
        textAlign: 'left',
        padding: theme.spacing(2),
    },

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },

        '& .totalLineInvestiments': {
            '& .MuiTableCell-root': {
                borderBottom: 'unset',
                color: 'black',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .totalLinePL': {
            '& .MuiTableCell-root': {
                borderBottom: 'unset',
                color: 'white',
                background: unoTheme.mainColor,
            },
        },

        '& .disponibilityLine': {
            '& .MuiTableCell-footer': {
                color: 'black',
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },

    tableWithFooter: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

    footerTable: {
        color: 'white',
        margin: '0px 18px 18px 18px',
        borderRadius: '10px',
        minHeight: '32px',
        backgroundColor: unoTheme.mainColor,
        width: 'calc(100% - 48px)',
        height: '32px',
        display: 'flex',
        alignItems: 'center',

        '& .MuiTypography-root': {
            fontSize: '12px',
        },

        '& .d': {
            padding: '0px 24px 0px 16px',
        },
        '& .d1': {
            textAlign: 'left',
            width: "35%"
        },
        '& .d2': {
            width: "15%"
        },
        '& .d3': {
            width: "15%",
            textAlign: 'right',

        },
        '& .d4': {
            width: "10%"
        },
        '& .d5': {
            width: "10%"
        },
        '& .d6': {
            width: "15%"
        },
    },

    lounchedWalletLabel: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        color: `${unoTheme.successColor} !important`,
        '& span': {
            marginRight: '5px',
        }
    },

    tabs: tabs,

    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },


}));

export const useSpeedDial = () => {
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const useStyles = makeStyles(() => ({
        speedDial: {
            position: 'absolute',
            bottom: unoTheme.bottomFab,
            right: theme.spacing(4),
            display: 'flex',
            alignItems: 'baseline',
            flexDirection: 'column',

            '& .MuiFab-primary': {
                backgroundColor: unoTheme.mainColor,
                '&:hover': {
                    background: unoTheme.hoverMainButtonColor,
                }
            },

            '& .MuiSpeedDialAction-staticTooltipLabel': {
                padding: '4px 8px',
                whiteSpace: 'nowrap',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '14px'
            }

        },

        speedDial2: {
            position: 'absolute',
            right: theme.spacing(4),
            display: 'flex',
            alignItems: 'baseline',
            flexDirection: 'column',

            '& .MuiFab-primary': {
                backgroundColor: unoTheme.mainColor,
                '&:hover': {
                    background: unoTheme.hoverMainButtonColor,
                }
            },

            '& .MuiSpeedDialAction-staticTooltipLabel': {
                padding: '4px 8px',
                whiteSpace: 'nowrap',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '14px'
            }

        },
    }))

    return {
        transitionDuration,
        useStyles
    }
}

export default function BalancesCollectScreen(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [innerLoad, setInnerLoad] = useState({ emptyMessage: "", loadMessage: "Carregando informações...", size: "small" });

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)
    const rightContent = useSelector(state => state.rightContent)
    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const [clientAccounts, setClientAccounts] = React.useState([]);
    const [transactions, setTransactions] = React.useState(undefined);
    const [totalAmountTransactions, setTotalAmountTransactions] = React.useState(undefined);
    const [totalAmountTransactionsApplications, setTotalAmountTransactionsApplications] = React.useState(undefined);
    const [totalAmountTransactionsRescues, setTotalAmountTransactionsRescues] = React.useState(undefined);

    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

    };

    //Import Excel
    const inputFile = useRef(null);
    const [file, setFile] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [dataListFormatted, setDataListFormatted] = useState(undefined);
    const [dataToInsert, setDataToInsert] = useState(undefined);
    //dataToOffAssetsModal
    const [dataOffAssets, setDataOffAssets] = useState(undefined);

    const [totalResultAmount, setTotalResultAmount] = useState(0);
    const [totalBeforeAmount, setTotalBeforeAmount] = useState(0);

    const [totalDisponibilityInAccounts, setTotalDisponibilityInAccounts] = useState({
        now: 0,
        before: 0
    });

    const [invalidQuotas, setInvalidQuotas] = useState(null);

    const [showImportModal, setShowImportModal] = useState(false);
    const [fundsToInconsistentFundsModal, setFundsToInconsistentFundsModal] = useState([]);
    const [showInconsistentFundsModal, setShowInconsistentFundsModal] = useState(false);
    //OffAssetsModal
    const [showOffAssetsValidateModal, setShowOffAssetsValidateModal] = useState(false);
    //AmortizationsModal
    const [showAmortizationToCreateModal, setShowAmortizationToCreateModal] = useState(false);
    const [amortizationsToCreate, setAmortizationsToCreate] = useState(null);

    //To new transaction
    //Lançar carteira
    const [allFunds, setAllFunds] = useState(undefined)
    const [clientFunds, setClientFunds] = useState(undefined)
    const [clientTitles, setClientTitles] = useState(undefined)
    const [clientAssets, setClientAssets] = useState(undefined)
    const [clientTitleAssets, setClientTitleAssets] = useState(undefined)
    const [clientOperations, setClientOperations] = useState(undefined)
    const [assetsFormatted, setAssetsFormatted] = useState(undefined)
    //Ativos com saldo zerados
    const [offAssets, setOffAssets] = useState(undefined)
    const [fullDiaryPl, setFullDiaryPl] = useState(undefined)
    const [initPortfolioQuota, setInitPortfolioQuota] = useState(undefined)
    const [rentPortfolioInMonth, setRentPortfolioInMonth] = useState(undefined)
    const [rentPortfolioInMonthMoney, setRentPortfolioInMonthMoney] = useState(undefined)

    //Checando se o saldo de algum título está muito divergente em valores de PU
    const [enableDivergentTitles, setEnableDivergentTitles] = useState(false);
    const [dataToInconsistentTitlesModal, setDataToInconsistentTitlesModal] = useState();

    const [returnColumnType, setReturnColumnType] = useState('money')

    const [checkedJustToConciliations, setCheckedJustToConciliations] = useState(false)

    const initSizeTable = 20;
    const [currentSizeTable, setCurrentSizeTable] = useState(initSizeTable);
    const handleChangeCheckBoxJustConciliations = (event) => {
        setCheckedJustToConciliations(event.target.checked);
    };

    function changeReturnColumnType() {
        if (returnColumnType == 'money') {
            setReturnColumnType('percent');
        } else {
            setReturnColumnType('money');
        }
    }

    const { transitionDuration, useStyles: speedDialUseStyles } = useSpeedDial();
    const speedDialClasses = speedDialUseStyles();

    async function getDataToCreateTransaction() {

        const response1 = await getAllClientAssets(clientOn.client.id);
        if (response1.success) {

            //console.log("CLIENT ASSETS: ", response1.body.rows);
            //setListClientAssets(response1.body.rows);
            setClientAssets(response1.body.rows);
            //fillClientAssets(response1.body.rows);

            const responseAllFunds = await getAllFunds();

            if (responseAllFunds.success) {
                setAllFunds(responseAllFunds.body.rows)
                //fillAllFunds(responseAllFunds.body.rows)
            }
        } else {
            console.log("error: ", response1.error)
        }

        const responseClientTitleAssets = await getClientAssetsTitles(null, clientOn.client.id, null);
        if (responseClientTitleAssets.success) {

            setClientTitleAssets(responseClientTitleAssets.body.rows);

        } else {
            console.log("error: ", response1.error)
        }

        const promises = [];
        promises.push(new Promise(async (resolve, reject) => {
            const responseFunds = await getAllClientFunds(clientOn.client.id);

            if (responseFunds.success) {
                resolve(responseFunds);
            } else {
                resolve(false);
            }

        }))
        promises.push(new Promise(async (resolve, reject) => {
            const responseTitles = await getAllClientTitles(clientOn.client.id);

            if (responseTitles.success) {
                resolve(responseTitles);
            } else {
                resolve(false);
            }

        }))

        const responseFundsAndTitlesPromises = await Promise.all(promises);

        //const responseFunds = await getAllClientFunds(clientOn.client.id);
        if (responseFundsAndTitlesPromises[0]) {

            setClientFunds(responseFundsAndTitlesPromises[0].body.rows)

        } else {
            console.log("error: ", responseFundsAndTitlesPromises.error)
        }
        if (responseFundsAndTitlesPromises[1]) {

            setClientTitles(responseFundsAndTitlesPromises[1].body.rows)

        } else {
            console.log("error: ", responseFundsAndTitlesPromises.error)
        }

        const response2 = await getOperations();
        if (response2.success) {
            //setOperations(response2.body.rows)
            setClientOperations(response2.body.rows)
            //fillClientOperations(response2.body.rows)
        } else {
            console.log("error: ", response2.error)
        }

    }

    function resetImportVariables() {

        console.log("Reset")
        setFile(undefined);
        setData(undefined);
        setDataListFormatted(undefined);

    }

    function handleChangeFile(e) {

        console.log("Change file")
        const files = e.target.files;
        if (files && files[0]) {
            setFile(files[0])
        }

        e.target.value = null;

    };

    function handleFile() {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);

            console.log("FILE CONVERTIDO");

            /* Update state */
            //setData([])
            //console.log("DATA: ", data);
            setData(data);
            setFile(undefined);

        };

        if (rABS) {
            //reader.readAsBinaryString(this.state.file);
            reader.readAsBinaryString(file);
        } else {
            //reader.readAsArrayBuffer(this.state.file);
            reader.readAsArrayBuffer(file);
        };
    }

    function checkIsClientAssetByCnpjAndAccount(listLocalClientAssets, cnpj, accountId) {

        // console.log("CNPJ: ", cnpj)
        // console.log("ACCOUNT: ", accountId)
        // console.log("TO COMPARE: ", listLocalClientAssets);
        let assets = listLocalClientAssets.filter(el => el.cnpj == cnpj && el.account_id == accountId)
        if (assets && assets.length == 1) {
            //console.log("ASSET JA EXISTENTE OK!")
            return assets[0].id;
        } else {
            return null;
        }
    }

    function verifyColumnsExcel(transactionLoop) {

        console.log("TESTE DE COLUNAS: ", transactionLoop);
        let invalidColumns = [];
        if (!transactionLoop['CC']) {
            invalidColumns.push('CC');
        }
        if (!transactionLoop['CNPJ']) {
            invalidColumns.push('CNPJ');
        }
        if (!transactionLoop['DATA']) {
            invalidColumns.push('DATA');
        }
        if (!transactionLoop['TIPO']) {
            invalidColumns.push('TIPO');
        }
        if (!transactionLoop['VALOR']) {
            invalidColumns.push('VALOR');
        }
        //Removido devido o caso em que existem resgates automáticos
        //A quantidade de cotas não é informada
        //Caso encontrado no cliente João Pessoa

        // if (!transactionLoop['QUANTIDADE']) {
        //     invalidColumns.push('QUANTIDADE');
        // }

        return invalidColumns;
    }

    function verifyTransactionAfterPorfolioClosed(monthClosed, transactionDate) {

        //Verifica-se se a o mês da data da transação é menor ou igual ao mês de carteira fechada
        //Caso seja deve-se retornar false, não inserindo na lista a ser inserido no banco
        //Esse método serve para evitar duplicações e alterações de carteiras já fechadas e validadas

        //No caso da carteira ainda não possuir nenhum mÊs fechado
        if (monthClosed == null) {
            return true;
        }

        if (parseInt(transactionDate.split("/")[1]) <= monthClosed) {
            return false;
        } else {
            return true;
        }

    }

    //Faz a verificação da data formatada da linha da tabela do excel
    function verifyingDate(dateToVerify, monthsToInsert, yearsToInsert, initYear, initMonth, invalidMonths) {
        //Guardando o mês pra saber se é uma planilha anual ou mensal
        let testMonth = dateToVerify.substring(3, 5);
        //Teste para saber se existe algum erro na data de transação,
        //algum registro apontando pra alguma data de anos diferentes
        let testYear = dateToVerify.substring(6);

        //Verificando se existem movimeentações para antes do início da carteira                    
        if (initYear == testYear && parseInt(testMonth) <= initMonth) {

            if (!invalidMonths.includes(parseInt(testMonth))) {
                invalidMonths.push(parseInt(testMonth));
            }

        }

        if (!monthsToInsert.includes(testMonth)) {

            monthsToInsert.push(testMonth);

        }

        console.log("YEAR TEST: ", testYear)
        if (!yearsToInsert.includes(testYear)) {
            yearsToInsert.push(testYear)
        }
    }

    async function doPreInserts(transactions, invalidColumns, invalidDates) {

        console.log("DO PRE INSERTS")

        let initYear = moment.utc(clientOn.client.portfolio_init).format('YYYY');
        let initMonth = parseInt(moment.utc(clientOn.client.portfolio_init).format('MM'));
        //Obtendo mês da carteira fechada para fazer validação de segurança impedindo que sejam importadas movimentações
        //de meses que já foram fechados e validados
        //Ex clientOn.client.portfolio_closed: 8/2021
        let monthClosed = clientOn.client.portfolio_closed == null ? null : parseInt(clientOn.client.portfolio_closed.split('/')[0]);
        let yearClosed = clientOn.client.portfolio_closed == null ? null : parseInt(clientOn.client.portfolio_closed.split('/')[1]);
        const portfolioClosed = clientOn.client.portfolio_closed;

        let listLocalClientAssets = clientAssets ? clientAssets : [];
        let invalidsAccounts = [];
        let accountsWhite = 0;
        let invalidsFunds = [];
        let invalidMonths = [];
        let invalidNoCnpjs = 0; //linhas sem informação de cnpj

        let assetsToCreate = [];

        let mapFunds = [];

        let transactionToInsert = [];

        //Utilizado para verificar a validade da planilha, com movimentações apenas do mesmo ano
        let yearsToInsert = [];

        //Se houver apenas 1 mês inserido na lista indica que a planilha é uma planilha mensal
        let monthsToInsert = [];

        //Verificar inconsistência na sequência, caso hajam criações de ativos de mês anterior eles devem vir todos no topo da lista       

        for (let i = 0; i < transactions.length; i++) {

            let transactionLoop = transactions[i];

            //Normalizando CNPJ, retirando possíveis espaços
            if (transactionLoop['CNPJ'] != null) {

                transactionLoop['CNPJ'] = transactionLoop['CNPJ'].toString().trim();

            } else {

                invalidNoCnpjs++;

            }


            //Realizar a verificação das colunas da planilha
            if (i === 0) {

                //Caso retorne false uma ou mais colunas necessárias não foram informadas
                invalidColumns = verifyColumnsExcel(transactionLoop);

            }

            // let accountLoop = getAccountIdByAccountNumber(justNumbers(transactionLoop['CC']));
            //console.log("Account: ", transactionLoop['CC']);

            if (transactionLoop['CC']) {

                transactionLoop['CC'] = parseInt(justNumbers(transactionLoop['CC']))
                transactionLoop['CC'] = transactionLoop['CC'].toString().trim()
                //transactionLoop.account_id = getAccountIdByAccountNumber(transactionLoop['CC']);
                transactionLoop.account_id = await getAccountIdByAccountNumberAndFundCnpj(transactionLoop['CC'], transactionLoop['CNPJ']);

            } else {

                transactionLoop.account_id = 'NOT_FOUND';
                accountsWhite++;

            }
            if (transactionLoop.account_id == 'NOT_FOUND') {

                if (transactionLoop['CC']) {
                    if (!invalidsAccounts.includes(justNumbers(transactionLoop['CC']))) {
                        invalidsAccounts.push(justNumbers(transactionLoop['CC']));
                    }
                }

            } else {

                let fundId = undefined;

                if (mapFunds[transactionLoop['CNPJ'] + "=" + transactionLoop['CC']]) { //Fundo já mapeado

                    fundId = mapFunds[transactionLoop['CNPJ'] + "=" + transactionLoop['CC']];
                    //let assetId = checkIsClientAssetByCnpjAndAccount(listLocalClientAssets, transactionLoop['CNPJ'], transactionLoop.account_id)
                    const transactionFormatted = {

                        //asset_id: assetId,
                        account_id: transactionLoop.account_id,
                        operation_id: transactionLoop['TIPO'],
                        amount: transactionLoop['VALOR'],
                        description: transactionLoop['DESCRICAO'] ? transactionLoop['DESCRICAO'].substring(0, 1000) : "",
                        cnpj: transactionLoop['CNPJ'],
                        transaction_date: transactionLoop['dataFormatada']
                    };

                    //console.log("Transaction formatted: ", transactionFormatted);

                    verifyingDate(transactionLoop['dataFormatada'],
                        monthsToInsert,
                        yearsToInsert,
                        initYear,
                        initMonth,
                        invalidMonths);

                    //if (verifyTransactionAfterPorfolioClosed(monthClosed, yearClosed, transactionFormatted.transaction_date)) {
                    if (isDateAfterClosedMonth(transactionFormatted.transaction_date, portfolioClosed)) {
                        transactionToInsert.push(transactionFormatted);
                    }

                    //console.log("INCLUINDO " + i + ": ", transactionFormatted);

                } else {

                    console.log("Tentando incluir 1: ", transactionLoop);

                    let responseFundByCnpj = await getFundByCnpj(transactionLoop['CNPJ']);

                    let testMonth = transactionLoop['dataFormatada'].substring(3, 5);
                    //Teste para saber se existe algum erro na data de transação,
                    //algum registro apontando pra alguma data de anos diferentes
                    let testYear = transactionLoop['dataFormatada'].substring(6);

                    //Verificando se existem movimeentações para antes do início da carteira
                    if (initYear == testYear && parseInt(testMonth) <= initMonth) {

                        if (!invalidMonths.includes(parseInt(testMonth))) {
                            invalidMonths.push(parseInt(testMonth));
                        }

                    }

                    verifyingDate(transactionLoop['dataFormatada'],
                        monthsToInsert,
                        yearsToInsert,
                        initYear,
                        initMonth,
                        invalidMonths);

                    console.log("responseFundByCnpj: ", responseFundByCnpj);

                    //Existe o fundo cadastrado na base de dados
                    if (responseFundByCnpj.success && responseFundByCnpj.body.rows[0] != undefined) {

                        fundId = responseFundByCnpj.body.rows[0].id;

                        //Checando se o cliente já possui esse Ativo
                        // console.log("LOCAL ASSETS: ", listLocalClientAssets);
                        // console.log("transactionLoop['CNPJ']: ", transactionLoop['CNPJ']);
                        // console.log("transactionLoop.account_id: ", transactionLoop.account_id);
                        let assetId = checkIsClientAssetByCnpjAndAccount(listLocalClientAssets, transactionLoop['CNPJ'].toString().trim(), transactionLoop.account_id)

                        console.log("ASSSET ID: ", assetId);

                        // console.log("COMPARE: ");
                        // console.log(parseInt(transactionLoop['dataFormatada'].substring(6)))
                        // console.log(parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY')))
                        // console.log("Result: ", parseInt(transactionLoop['dataFormatada'].substring(6))
                        //     > parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY')));


                        if (!assetId &&
                            (assetsToCreate
                                .filter(el => el.fund_id == fundId && el.account_id == transactionLoop.account_id)).length == 0) {
                            //Checando se já não é um fundo a ser criado
                            //Caso não possua o ativo desse ser criado                        
                            const asset = {
                                account_id: transactionLoop.account_id,
                                number_account: transactionLoop['CC'],
                                fund_id: fundId,
                                balance: transactionLoop['VALOR'],
                                fundInfos: responseFundByCnpj.body.rows[0],
                                asset_init: transactionLoop['dataFormatada'],
                                quota_amount: transactionLoop['QUANTIDADE'],
                                transaction_date: transactionLoop['dataFormatada'],
                            }

                            //console.log("ACCOUNT NO ASSET: ", asset.account_id)

                            //Se data de movimentação for uma data antes do início da carteira o
                            //asset é criado e não é considerado como movimentação
                            //senão entra no IF abaixo e é criado o asset mas ele também uma movimentação

                            if (parseInt(transactionLoop['dataFormatada'].substring(6)) //Compara o ano da linha do excel 
                                > parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY'))) { //Com o ano de inicio da carteira

                                console.log("COMPARANDO: " + "[" + parseInt(transactionLoop['dataFormatada'].substring(6)) + "]" +
                                    ">" + "[" + parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY')) + "]")

                                const transactionFormatted = {

                                    //asset_id: assetId,
                                    account_id: asset.account_id,
                                    operation_id: transactionLoop['TIPO'],
                                    amount: transactionLoop['VALOR'],
                                    description: transactionLoop['DESCRICAO'] ? transactionLoop['DESCRICAO'].substring(0, 1000) : "",
                                    cnpj: transactionLoop['CNPJ'],
                                    transaction_date: transactionLoop['dataFormatada']

                                };

                                //console.log("TRANSACTION TAMBEM ASSET: ", transactionFormatted)

                                //Teste para saber se existe algum erro na data de transação,
                                //algum registro apontando pra alguma data de anos diferentes
                                let testYear = transactionLoop['dataFormatada'].substring(6);
                                //console.log("YEAR TEST: ", testYear)
                                if (!yearsToInsert.includes(testYear)) {
                                    yearsToInsert.push(testYear)
                                }

                                // if (verifyTransactionAfterPorfolioClosed(monthClosed, yearClosed, transactionFormatted.transaction_date)) {
                                if (isDateAfterClosedMonth(transactionFormatted.transaction_date, portfolioClosed)) {
                                    transactionToInsert.push(transactionFormatted);
                                }

                                //console.log("INCLUINDO " + i + ": ", transactionFormatted);

                                //No caso em que o asset é uma movimentação o saldo de criação é 0
                                asset.balance = 0.0;
                                asset.isTransaction = true; //utilizado pra controlar a criação do balance_validation
                                //quando é uma movimentação cria-se apenas um blance_validation aberto para o mês da movimentação
                            }

                            //console.log("ASSET TO CREATE: ", asset);
                            assetsToCreate.push(asset);

                        } else if (parseInt(transactionLoop['dataFormatada'].substring(6)) //Compara o ano da linha do excel, o ano deve ser maior ou igual ao ano de início da carteira 
                            >= parseInt(initYear)) {
                            //> parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY'))) {

                            const transactionFormatted = {
                                asset_id: assetId,
                                account_id: transactionLoop.account_id,
                                operation_id: transactionLoop['TIPO'],
                                amount: transactionLoop['VALOR'],
                                cnpj: transactionLoop['CNPJ'],
                                description: transactionLoop['DESCRICAO'] ? transactionLoop['DESCRICAO'].substring(0, 1000) : "",
                                transaction_date: transactionLoop['dataFormatada']
                            };

                            //console.log("REALMENTE INCLUINDO: ", transactionFormatted);

                            // if (verifyTransactionAfterPorfolioClosed(monthClosed, yearClosed, transactionFormatted.transaction_date)) {
                            if (isDateAfterClosedMonth(transactionFormatted.transaction_date, portfolioClosed)) {
                                transactionToInsert.push(transactionFormatted);
                            }
                        }

                        mapFunds[transactionLoop['CNPJ'] + "=" + transactionLoop['CC']] = fundId;

                    } else {

                        console.log("Tentando incluir 2: ", transactionLoop['CNPJ']);
                        console.log("ERROR: ", responseFundByCnpj.body)

                        let filtered = invalidsFunds.filter(el => el.cnpj == transactionLoop['CNPJ']);
                        if (filtered && filtered.length == 0) {
                            invalidsFunds.push({
                                fund_name: transactionLoop['FUNDO'],
                                cnpj: transactionLoop['CNPJ']
                            });
                        }

                    }

                }

            }


        }

        if (invalidColumns.length > 0
            || invalidsAccounts.length > 0
            || invalidsFunds.length > 0
            || accountsWhite > 0
            || yearsToInsert.length > 1
            || invalidMonths.length > 0
            || invalidDates.length > 0
            || invalidNoCnpjs > 0
        ) {
            console.log("Não será possível realizar as inserções")
            console.log("Colunas não encontradas: ", invalidColumns)
            console.log("Contas: ", invalidsAccounts)
            console.log("Contas em branco: ", accountsWhite)
            console.log("Fundos: ", invalidsFunds)
            console.log("Anos: ", yearsToInsert)
            console.log("invalidMonths: ", invalidMonths)
            console.log("invalidDates: ", invalidDates)
            console.log("invalidNoCnpjs: ", invalidNoCnpjs)
            //console.log("Meses: ", monthsToInsert)
            // dispatch(allActions.mainAlertActions.setMainAlert({
            //     show: true,
            //     type: 'warning',
            //     message: "Existem contas ou fundos não cadastradas, Contas: " + invalidsAccounts + " / " + "Fundos: " + invalidsFunds + " / " + "Anos:" + yearsToInsert
            // }))
        }
        //else {
        // console.log(`
        //     **********************
        //     * Realizar inserções *
        //     * ********************
        //     `)

        //checando se o ano já foi importado caso a planilha seja válida
        let alreadyImported = false;

        //lista dos meses que ainda não foram importados
        let newMonthsToInsert = [];
        //lista de meses a serem removidos da lista de inserção
        let monthsToRemoveFromInsert = [];

        //Meses que precisarão ser limpos pois surgiram novas transações
        const needCleanMonth = [];
        if (monthsToInsert.length > 0 && yearsToInsert.length == 1) {
            for (let i = 0; i < monthsToInsert.length; i++) {

                let responseAlreadyImported = await checkAlreadyImportedMonth(monthsToInsert[i].toString(),
                    yearsToInsert[0],
                    clientOn.client.id);

                console.log("responseAlreadyImported:", responseAlreadyImported);
                if (responseAlreadyImported.success) {

                    const transactionsInMonth = transactionToInsert.filter(el => el.transaction_date.substring(3, 5) == monthsToInsert[i].toString())

                    if (responseAlreadyImported.body
                        && responseAlreadyImported.body.rowCount == 0) {
                        //&& responseAlreadyImported.body.rowCount != transactionsInMonth.length) {

                        // if (responseAlreadyImported.body.rowCount != 0 && transactionsInMonth != 0) {
                        //     needCleanMonth.push(parseInt(monthsToInsert[i]));
                        // }

                        console.log("ADICIONANDO: ", monthsToInsert[i]);
                        newMonthsToInsert.push(monthsToInsert[i]);

                    } else {
                        monthsToRemoveFromInsert.push(monthsToInsert[i]);
                    }
                }

            }

        }

        console.log("TRANSACTIONS TO INSERT: ", transactionToInsert);
        console.log("needCleanMonth: ", needCleanMonth);

        //Caso existam meses a serem cadastrados

        //##################################################################
        //##################################################################
        //TROCAR TESTE DE JA INSERÇÃO DE MESES POR TESTE DE INSERÇÃO DE TRANSAÇÃO
        //let newTransactionsToInsert = [];
        let newTransactionsToInsert = transactionToInsert;
        if (newMonthsToInsert) {

            if (monthsToRemoveFromInsert.length > 0) {

                //Filtrando pelos meses que devem ser retirados, o filtro retorna tudos os itens que não se encontram na lista de meses a serem retirados
                newTransactionsToInsert = transactionToInsert.filter((el) => !monthsToRemoveFromInsert.includes(el.transaction_date.substring(3, 5)));

            } else {
                newTransactionsToInsert = transactionToInsert;
            }
        }
        //##################################################################
        //##################################################################

        //Guardando os dados a serem inseridos e exibindo modal de confirmação
        setDataToInsert({

            client: clientOn.client,

            assets: assetsToCreate,
            //transactions: transactionToInsert,
            needCleanMonth: needCleanMonth,
            transactions: newTransactionsToInsert,

            invalidColumns: invalidColumns,
            invalidsAccounts: invalidsAccounts,
            invalidsFunds: invalidsFunds,
            invalidMonths: invalidMonths,
            accountsWhite: accountsWhite,
            yearsToInsert: yearsToInsert,
            monthsToInsert: monthsToInsert,
            newMonthsToInsert: newMonthsToInsert,
            invalidDates: invalidDates,
            invalidNoCnpjs: invalidNoCnpjs,

            alreadyImported: alreadyImported,

        });

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
            message: ""
        }))

        setShowImportModal(true);

        // }
    }

    async function doInserts(data) {

        let allSuccess = true;

        let transactionsToInsert = data.transactions
        let listLocalClientAssets = clientAssets ? clientAssets : [];

        //Caso hajam meses que necessitaram ser limpos, array com os que já foram
        const monthsAlreadyDeleted = [];

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Cadastrando Movimentações..."
        }))

        console.log("TRANSACTIONS TO INSERT: ", transactionsToInsert);

        for (let i = 0; i < transactionsToInsert.length; i++) {

            //console.log("####################################################");
            // const monthTransaction = parseInt(transactionsToInsert[i].transaction_date.substring(3, 5));
            // if (!monthsAlreadyDeleted.includes(monthTransaction) && data.needCleanMonth.includes(monthTransaction)) {
            //     const responseDeleteTransactionsInMonth = await deleteClientTransactionsInMonth(data.client.id, monthTransaction);
            // }

            let transactionLoop = transactionsToInsert[i];

            if (!transactionLoop.asset_id) {
                let assetId = checkIsClientAssetByCnpjAndAccount(listLocalClientAssets, transactionLoop.cnpj, transactionLoop.account_id)
                transactionLoop.asset_id = assetId;
            }


            console.log("transactionLoop: ", transactionLoop);

            //console.log("Incluindo: ", transactionLoop)
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando movimentações... " + parseFloat((i + 1) / transactionsToInsert.length * 100).toFixed(2) + "%"
            }))

            let responseInsertTransaction = await createTransaction(transactionLoop);

            if (!responseInsertTransaction.success) {
                allSuccess = false;
            }
            console.log("Sucesso: ", responseInsertTransaction.success)

        }

        if (allSuccess) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Movimentações cadastradas com sucesso!"
            }))
        } else {
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "Erro ao cadastrar uma ou mais movimentações"
            }))
        }

        setData(undefined)
        setDataListFormatted(undefined)

        setClientAccounts([])
        setTransactions(undefined)
        setAssetsFormatted(undefined);
        setFullDiaryPl(undefined);
        setInitPortfolioQuota(undefined);
        setRentPortfolioInMonth(undefined);
        setRentPortfolioInMonthMoney(undefined);
        setCheckedJustToConciliations(false);
        setDataToInconsistentTitlesModal();
        setEnableDivergentTitles(false)

        //console.log(getAssetInfos());
        //getClientAccountsAndTransactions(clientOn.client.id, clientOn.client.selectedMonth, clientOn.client.selectedYear);
        // dispatch(allActions.innerLoadActions.setInnerLoad({
        //     show: true,
        //     loadMessage: 'Carregando informações...',
        //     cod: codsInnerLoad.balancesCollectionScreen
        // }));
        setInnerLoad({ loadMessage: "Carregando informações" })

        console.log("POS UPLOAD MOVS");
        console.log('clientOn.client.selectedMonth: ', clientOn.client.selectedMonth);
        console.log('clientOn.client.selectedYear: ', clientOn.client.selectedYear);

        loadInfosAssets(clientOn.client.id,
            clientOn.client.selectedMonth,
            clientOn.client.selectedYear,
            null,
            true, clientOn.client.portfolio_init);

        //get datas das transações
        // const responseDates = await getDatesWithTrasactions(clientOn.client.id);

        // if (responseDates.success) {

        //     console.log("RESPONSE DATES: ", responseDates.body.rows);
        //     if (responseDates.body.rows && responseDates.body.rows.length > 0) {
        //         formatTransactionsMonthsAndYears(responseDates.body.rows)
        //     }

        // }

        //Atualizar datas dos selects de data da Dashboard
        //Não há mais necessidade de atualização de selects de data;
        //clientOn.client.updateInfosClient = true;
        //dispatch(allActions.clientActions.setClient(clientOn.client));


        getDataToCreateTransaction();
    }

    function getAccountIdByAccountNumber(accountNumber) {

        //console.log("PROCURANDO ACC: ", accountNumber)
        let accounts = clientAccounts.filter(el => el.number_account == accountNumber);
        //console.log("ACCOUNTS: ", clientAccounts)

        if (accounts.length == 1) {
            return accounts[0].id
        } else {
            return "NOT_FOUND"
        }

    }

    async function getAccountIdByAccountNumberAndFundCnpj(accountNumber, cnpj) {

        //console.log("PROCURANDO ACC: ", accountNumber)
        //let accounts = clientAccounts.filter(el => el.number_account == accountNumber && el.cod == instituitionCod);
        const accounts = clientAccounts.filter(el => el.number_account == accountNumber);
        console.log("ACCOUNTS: ", clientAccounts);

        if (accounts.length == 1) {

            return accounts[0].id

        } else if (accounts.length > 1) {

            const accountAsset = await doGetAccountIdByNumberAccountAndCnpjAsset(accountNumber, cnpj);
            return accountAsset;

        } else {

            return "NOT_FOUND";

        }

    }

    function handleModalImport(doImport) {

        handleCloseFab(false);
        setShowImportModal(false)
        resetImportVariables();
        if (doImport) {
            doInserts(dataToInsert);
        }
    }

    function handleModalOffAssets(doAction) {

        setShowOffAssetsValidateModal(false)
        if (doAction) {

            launchWallet(true);
        }

    }

    async function handleAmortizationToCreateModal(doAction) {

        setShowAmortizationToCreateModal(false)
        if (doAction) {

            let allSuccess = true;
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: 'Criando amortizações...'
            }));

            for (let i = 0; i < amortizationsToCreate.length; i++) {

                const amortization = amortizationsToCreate[i];

                const amortizationToCreate = {
                    title_asset_id: amortization.titleAssetId,
                    operation_id: 3, //amortização
                    amount: parseFloat(amortization.valueAmortization),
                    transaction_date: moment.utc(amortization.dateCoupon).format("DD/MM/YYYY"),
                }

                const response = await createTransaction(amortizationToCreate);
                if (response.success) {

                    //Amortização criada com sucesso

                } else {

                    allSuccess = false;

                }
            }

            if (allSuccess) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Amortizações criadas com sucesso!"
                }))

            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: "Falha ao criar uma ou mais das amortizações!"
                }))

            }

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }));

            // dispatch(allActions.innerLoadActions.setInnerLoad({
            //     show: true,
            //     loadMessage: 'Carregando informações...',
            //     cod: codsInnerLoad.balancesCollectionScreen
            // }));
            setInnerLoad({ loadMessage: "Carregando informações" })

            setClientAccounts([])
            setTransactions(undefined)
            setAssetsFormatted(undefined);
            setSegregationOnScreen(segregation);

            setFullDiaryPl(undefined);
            setInitPortfolioQuota(undefined);
            setRentPortfolioInMonth(undefined);
            setRentPortfolioInMonthMoney(undefined);
            setCheckedJustToConciliations(false);
            setDataToInconsistentTitlesModal();
            setEnableDivergentTitles(false)

            loadInfosAssets(clientOn.client.id,
                clientOn.client.selectedMonth,
                clientOn.client.selectedYear,
                segregation,
                true, clientOn.client.portfolio_init);
        }

    }

    const useRowStyles = makeStyles({
        green: {
            color: unoTheme.greenMoney,
        },
        red: {
            color: unoTheme.errorColor,
        },
        blue: {
            color: "blue",
            fontWeight: 'bold'
        },
        tableTh: {
            fontWeight: 'bold'
        },
        rowFooter: {
            '& > *': {
                borderBottom: 'unset',
            }
        },
        statusOk: {
            fontSize: '18px',
            verticalAlign: 'middle',
            color: unoTheme.greenMoney,
            fill: unoTheme.greenMoney,
        },
        statusPendent: {
            fontSize: '18px',
            verticalAlign: 'middle',
            color: unoTheme.warningColor,
            fill: unoTheme.warningColor,
        },

        labelConc: {
            fontSize: '0.7em',
            color: unoTheme.defaultTextColor,
        },

        offAssetRow: {
            backgroundColor: '#fff93657 !important',
        },

        needCheckRentRow: {
            backgroundColor: '#fff93657 !important',
        }
    });

    function getLastDayQuotaInMonthByOtherAssets() {

        let maxLength = null;
        let model = null;
        if (assetsFormatted && assetsFormatted.length) {
            assetsFormatted.forEach(assetLoop => {

                const row = assetLoop.quotas_anbima;
                if (row && row.length) {
                    if (model == null) {

                        maxLength = row.length;
                        model = _.cloneDeep(row);

                    } else if (model != null) {
                        if (row.length > maxLength) {
                            maxLength = row.length;
                            model = _.cloneDeep(row);
                        }
                    }
                }

            })

        }
        if (model && model.length) {

            const lastDayQuotaInMonth = model[model.length - 1].data_referencia;
            console.log("RETURN LAST DAY QUOTA: ", lastDayQuotaInMonth);
            return lastDayQuotaInMonth;
        }

        return null;

    }

    function checkAbleMonthToLoauch() {
        return clientOn
            && clientOn.client
            && clientOn.client.selectedYear && clientOn.client.selectedMonth
            && isDateAfterClosedMonth(moment.utc(new Date(clientOn.client.selectedYear, parseInt(clientOn.client.selectedMonth) - 1, 1)).format("DD/MM/YYYY"),
                clientOn.client.portfolio_closed,
                false)
            && !isDateBeforeInit(getFirstDayMonth(clientOn.client.selectedMonth, clientOn.client.selectedYear), clientOn.client.portfolio_init);
    }

    const renderReturnColumn = (row, returnColumnType) => {

        const isTitleAsset = row.title_id;
        if (isTitleAsset) {

            if (isGenericTitle(row))
                return <TableCell align='right'>-</TableCell>

            return <TableCell align="right">{

                row.noQuotas ? ("-") :
                    (returnColumnType == 'percent' ?

                        (!row.noBeforeQtdQuota ? applyMaskAmount(getRentMonthTitle(row)) + "%" : "-")
                        :
                        (
                            (!row.noBeforeQtdQuota ?

                                (

                                    //row.balance_now != undefined && row.totalPeriodRent ?                                            
                                    row.balance_now != undefined ?
                                        //applyMaskAmount(row.balance_now * row.totalPeriodRent / 100, true)
                                        applyMaskAmount(getRentInMoneyMonthTitle(row), true)
                                        :
                                        "-"
                                )

                                :

                                "-"
                            )
                        )
                    )

            }</TableCell>
        }

        return <TableCell align="right">{

            (returnColumnType == 'percent' ?
                (!row.noBeforeQtdQuota ? applyMaskAmount(getInMonthRentFundInPortfolio(row), null, true) + "%" : "-")
                :
                (
                    (!row.noBeforeQtdQuota ?

                        (

                            row.balance_now != undefined ?
                                applyMaskAmount(getMonthFundRentInMoney(row), true)
                                :
                                "-"
                        )

                        :

                        "-"
                    )
                )
            )

        }</TableCell>
    }

    function Row(props) {
        const { row, index, offAsset } = props;
        const classes = useRowStyles();

        //console.log("offAsset: ", offAsset);

        let infosConc = [];

        //Caso seja um fundo de diversos assets
        if (row.subAssets && row.subAssets.length > 0) {

            for (let i = 0; i < row.subAssets.length; i++) {
                const subAsset = row.subAssets[i];
                if (!checkIsSameBalance(subAsset.pl, subAsset.balance_now) || !checkIsSameQuotas(subAsset.quota_amount_now, subAsset.qtd_quotas_sensibilized_final)) {
                    //console.log("SUBASSET: ", subAsset)
                    infosConc.push(<p key={'aprover-' + subAsset.id + '-' + i} style={{ marginBottom: (i != row.subAssets.length - 1 ? '12px' : '6px') }}>{`${subAsset.agency ? 'Ag:' + subAsset.agency + " -" : ""}` + " cc: " + subAsset.number_account + ": "}<span style={{ marginLeft: '5px', marginRight: '5px' }}>{subAsset.balance_now_conc ? <CheckCircleIcon className={classes.statusOk} /> : <ReportProblemIcon className={classes.statusPendent} />}</span>{subAsset.balance_now_conc ? 'Por: ' + subAsset.conc_name : ''}</p>)
                    if (i != row.subAssets.length - 1) {
                        infosConc.push(<Divider key={'divider-' + subAsset.id + '-' + i} />)
                    }
                }

            }
        } else {

            //Caso seja apenas um asset
            const subAsset = row;
            if (!checkIsSameBalance(subAsset.pl, subAsset.balance_now) || !checkIsSameQuotas(subAsset.quota_amount_now, subAsset.qtd_quotas_sensibilized_final)) {
                infosConc.push(<p key={'aprover-' + subAsset.id} style={{ marginBottom: '12px' }}>{`${subAsset.agency ? 'Ag:' + subAsset.agency + " -" : ""}` + " cc: " + subAsset.number_account + ": "}<span style={{ marginLeft: '5px', marginRight: '5px' }}>{subAsset.balance_now_conc ? <CheckCircleIcon className={classes.statusOk} /> : <ReportProblemIcon className={classes.statusPendent} />}</span>{subAsset.balance_now_conc ? 'Por: ' + subAsset.conc_name : ''}</p>)
            }

        }

        // console.log("BALANCE BEFORE: ", parseFloat(row.balanceBefore))
        // console.log("RESULT AMOUNT: ", row.resultAmount)
        // console.log("BALANCE NOW: ", parseFloat(row.balanceNow))        
        //console.log("ROW: ", row)        

        //corrigindo -0,00
        if (row.pl < 0 && (row.pl * (-1) < 0.01)) {
            row.pl = 0;
        }

        const isSegmentRow = row.labelSegment;
        const isArticleRow = row.labelArticle;

        const isTitleAsset = row.title_id;
        if (isTitleAsset) {
            // console.log("IS TITLE");
            // console.log("row.title_id: ", row.title_id);
            row.titleCod = getTitleCod(row);
            row.taxDescription = '';

            if (row.indexer) {
                if (row.indexer == "CDI") {
                    row.taxDescription += row.indexer + (row.indexer_percent ? ": " + applyMaskAmount(row.indexer_percent) + "%" : '');
                } else {
                    row.taxDescription += row.indexer;
                }
            } else if (row.pre_tax) {
                row.taxDescription += "Taxa pré: " + applyMaskAmount(row.pre_tax) + "%";
            }

            if (row.marking == MARKING_CURVE) {
                if (row.rentPercentInMonth > OUT_OF_MARKET_RENT_TITLE) {
                    row.needCheckRent = true;
                } else {
                    row.needCheckRent = false;
                }
            }


        }

        if (isSegmentRow) {
            return (
                <React.Fragment>
                    <TableRow className={"divisor-table-1"}>
                        <TableCell align="left">
                            {isSegmentRow}
                        </TableCell>
                        <TableCell align="right"></TableCell>

                        <TableCell align="right">
                        </TableCell>
                        <TableCell align="center" >
                        </TableCell>
                        <TableCell align="left">
                        </TableCell>
                        <TableCell align="right">

                        </TableCell>
                    </TableRow>
                </React.Fragment >
            )
        }

        if (isArticleRow) {
            return (
                <React.Fragment>
                    <TableRow className={"divisor-table-2"}>
                        <TableCell align="left">
                            {isArticleRow}
                        </TableCell>
                        <TableCell align="right"></TableCell>

                        <TableCell align="right">
                        </TableCell>
                        <TableCell align="center" >
                        </TableCell>
                        <TableCell align="left">
                        </TableCell>
                        <TableCell align="right">

                        </TableCell>
                    </TableRow>
                </React.Fragment >
            )
        }

        const needConciliation = row.balance_now == null
            || !checkAssetsAndSubAssetsSameBalances(row)
            || !checkAssetsAndSubAssetsSameFinalQuotas(row);

        if (needConciliation) {
            row.needConciliation = true;
        } else {
            row.needConciliation = false;
        }

        if (row) {
            return (
                <React.Fragment>
                    <TableRow
                        className={[classes.root, offAsset ? classes.offAssetRow : '', isTitleAsset && row.needCheckRent ? classes.needCheckRentRow : ''].join(' ')}
                        onClick={() => !offAsset ? dispatch(allActions.rightContentActions.setRightContent({
                            path: isTitleAsset ? 'detailTitleBalanceCollection' : 'detailBalanceCollection',
                            data: row,
                            selectedMonth: clientOn.client.selectedMonth,
                            selectedYear: clientOn.client.selectedYear,
                            accounts: clientAccounts,
                            assets: clientAssets,
                            titleAssets: clientTitleAssets,
                            operations: clientOperations,
                            lastDayQuotaInMonth: row.special ? getLastDayQuotaInMonthByOtherAssets() : null,
                            ableToAddSpecialQuota: row.special ? checkAbleMonthToLoauch() : null,
                        })) : null}>

                        <TableCell align="left">
                            {isTitleAsset ?
                                <div>
                                    <div>{row.titleCod}</div>
                                    {
                                        row.label_title_type == GENERIC_ASSET_TITLES_TYPE ?
                                            <div style={{ color: 'black', fontWeight: '900', fontSize: '12px' }}>{row.asset_name}</div>
                                            :
                                            <div style={{ color: 'black', fontWeight: '900', fontSize: '12px' }}>{row.marking} <span style={{ fontSize: '10px', color: 'blue' }}>( Título [Tax: {row.taxDescription}])</span></div>
                                    }
                                </div>
                                :
                                <div>
                                    <div>{row.fund_name}</div>
                                    <div style={{ color: 'black', fontWeight: '900', fontSize: '12px' }}>{row.cnpj} {row.special ? <span style={{ fontSize: '10px', color: 'blue' }}>( ESPECIAL )</span> : null}</div>
                                </div>
                            }


                        </TableCell>

                        {/* Saldo anterior */}
                        {
                            row.balance_before == null ?
                                <TableCell align="right">{'Não informado'}</TableCell>
                                :
                                <TableCell align="right"
                                    className={row.balance_before < 0 ? classes.red : classes.green}>{applyMaskAmount(row.balance_before, true)}</TableCell>
                        }

                        {/* Saldo atual sensibilizado */}
                        {/* Caso tenha sido conciliado deve ser exibido o saldo validado */}
                        {

                            row.balance_now_conc || checkAssetsAndSubAssetsSameBalances(row) ?

                                //Se conciliado exibe o saldo validado
                                <TableCell align="right"
                                    className={row.balance_now < 0 ? classes.red : classes.green}>{applyMaskAmount(row.balance_now, true)}</TableCell>
                                :

                                //Caso não tenha sido validado exibe o sensibilizado
                                <>
                                    {
                                        //Caso não haja saldo anterior apresenta-se apenas o valor das movimentações
                                        row.balance_before == null || row.noBeforeQtdQuota ?
                                            // <TableCell align="right"
                                            //     className={row.resultAmount < 0 ? classes.red : classes.green}>{applyMaskAmount(row.resultAmount, true)}</TableCell>
                                            //Caso não haja saldo anterior ou quantidade de quota anterior não exibe-se nada
                                            <TableCell align="right">-</TableCell>
                                            :
                                            //Caso haja se exibe o PL ou o resultAmount                                        
                                            //Se o pl for igual a 0 não houve conta, exibe-se o result amount sensibilizado com o balance-before
                                            <>
                                                {

                                                    row.pl != null ?
                                                        <TableCell align="right"
                                                            className={row.pl < 0 ?
                                                                classes.red
                                                                :
                                                                classes.green
                                                            }>{

                                                                applyMaskAmount(row.pl, true)
                                                            }</TableCell>
                                                        :

                                                        <TableCell align="right">Não informado</TableCell>
                                                }

                                            </>


                                    }
                                </>
                        }

                        {/* Icon validado ou não */}
                        {
                            offAsset ?

                                <TableCell align="center" className='noPaddingCell'>
                                    {'-'}
                                </TableCell>
                                :
                                <>
                                    {

                                        //Caso o saldo não conste na validação de saldos ou o saldo valdido seja diferente do pl
                                        //Ou o saldo de cotas esteja diferente dos demais
                                        //Deverá ser exibido o icon alerta amarelo (verde apenas caso o saldo tenha sido conciliado)
                                        needConciliation

                                            ?

                                            <>
                                                {

                                                    //Para o caso de não informe na tela de validação de saldo a info tooltip é Validação de saldo não informada
                                                    row.balance_now == null || row.noBeforeQtdQuota ?

                                                        <LightTooltip title={row.noBeforeQtdQuota ? "Quantidade de cotas não informada" : "Validação de saldo não informada"} placement="right" arrow>
                                                            <TableCell align="center" className='noPaddingCell'>
                                                                <ReportProblemIcon className={classes.statusPendent} />
                                                            </TableCell>
                                                        </LightTooltip>

                                                        :

                                                        <>
                                                            {

                                                                //No caso de o saldo estar diferente mas ter sido conciliado
                                                                //Apresenta-se o icon verde
                                                                row.balance_now_conc ?
                                                                    <TableCell align="center" className='noPaddingCell'>
                                                                        <CheckCircleIcon className={classes.statusOk} />
                                                                    </TableCell>
                                                                    :

                                                                    //Caso em que o PL é mesmo difenrete do saldo validado e ainda não foi conciliado
                                                                    //icon amarelo                                                                                                    
                                                                    <LightTooltip title={
                                                                        row.noQuotasCod == '404' ? 'Erro ao buscar as cotas do fundo, virifique se ele possui o cadastro do código ANBIMA' : applyMaskAmountFreeDecimals(row.pl - row.balance_now, true)
                                                                    } placement="right" arrow>
                                                                        <TableCell align="center" className='noPaddingCell'>
                                                                            <ReportProblemIcon className={classes.statusPendent} />
                                                                        </TableCell>
                                                                    </LightTooltip>
                                                            }
                                                        </>
                                                }

                                            </>

                                            :

                                            //Caso em que o saldo validado informado já é igual ao saldo sensibilizado
                                            <TableCell align="center" className='noPaddingCell'>
                                                <CheckCircleIcon className={classes.statusOk} />
                                            </TableCell>

                                    }
                                </>
                        }


                        {/* CONCILIADO  */}
                        <TableCell align="left"
                            className={classes.labelConc}>

                            {
                                offAsset ?

                                    <span>Resgatado Total</span>

                                    :

                                    <LightTooltip title={<div>{infosConc}</div>} placement="bottom" arrow>

                                        <span>{
                                            row.balance_now_conc ?

                                                'Conciliado'

                                                :
                                                (
                                                    //checkIsSameBalance(row.pl, row.balance_now) ?
                                                    checkAssetsAndSubAssetsSameBalances(row) ?
                                                        ''
                                                        :
                                                        (row.inProgressConc ? 'Em conciliação' : 'Aguardando conciliação')
                                                )

                                        }</span>

                                    </LightTooltip>




                            }
                        </TableCell>


                        {/* Retorno mensal */}
                        {renderReturnColumn(row, returnColumnType)}

                    </TableRow>
                </React.Fragment >
            );
        }


    }

    function formatTransactionsMonthsAndYears(data) {

        let yearsWithMonths = {}
        let years = [];
        data.forEach(element => {

            let splitted = element.monthyear.split('/');
            let m = splitted[0]
            let y = splitted[1]
            if (!yearsWithMonths[y]) {
                yearsWithMonths[y] = [];
                years.push(parseInt(y));
            }

            if (!yearsWithMonths[y].includes(m)) {
                yearsWithMonths[y].push(parseInt(m));
                yearsWithMonths[y].sort(function sortfunction(a, b) {
                    return (a - b)
                });
            }
        });

        years.sort(function sortfunction(a, b) {
            return (a - b)
        });

        //setYears(years);
        //setMonths(getMonthsByArray(yearsWithMonths[years[0]]))
        clientOn.client.months = getMonthsByArray(yearsWithMonths[years[0]]);
        clientOn.client.years = years;

        //Inicializando selectedMonth e selectedYear do client com os primeiros registros obtidos
        clientOn.client.selectedMonth = parseInt(yearsWithMonths[years[0]][0]);
        clientOn.client.selectedYear = parseInt(years[0]);
        dispatch(allActions.clientActions.setClient(clientOn.client));

        return yearsWithMonths;
    }

    function handleActionDivergentTitles(action) {

        setDataToInconsistentTitlesModal();

        if (action) {
            setEnableDivergentTitles(true);
        } else {
            setEnableDivergentTitles(false);
        }
    }

    useEffect(() => {
        if (enableDivergentTitles) {
            launchWallet();
        }
    }, [enableDivergentTitles])

    //validatedOffAssets: ativos que estão com saldos não informados para o mês em questão de
    //mas que poderão ser salvos automaticmante como 0
    async function launchWallet(validatedOffAssets) {

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Lançando carteira..."
        }))



        //Obtendo portfolioClosed do cliente pois algúem pode ter lançado em outra tela
        const responsePortfolioClosed = await getPortfolioClosed(clientOn.client.id);
        if (!responsePortfolioClosed.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: 'Falha ao obter data de fechamento da carteira'
            }))
            return;
        }

        let currentPortfolioClosed = responsePortfolioClosed.body;
        if (clientOn.client.portfolio_closed) {
            clientOn.client.portfolio_closed = clientOn.client.portfolio_closed.trim();
        }
        if (currentPortfolioClosed) {
            currentPortfolioClosed = currentPortfolioClosed.trim();
        }
        if (clientOn.client.portfolio_closed != currentPortfolioClosed) {
            clientOn.client.portfolio_closed = currentPortfolioClosed;
            dispatch(allActions.clientActions.setClient(clientOn.client));
        }

        //Checar se o último mês validado é mesmo o 
        //anterior a esse que está tentando-se fechar portfolio_closed

        console.log("CLOSED MONTH: ", clientOn.client.portfolio_closed)
        let splittedClosedMonth = null
        let nextMonth = 0
        let nextYear = 0

        if (clientOn.client.portfolio_closed) {
            //No caso de já haver uma carteira fechada
            splittedClosedMonth = clientOn.client.portfolio_closed.split("/")
        } else {

            //Ainda não havia carteira fechada
            splittedClosedMonth = moment.utc(clientOn.client.portfolio_init).format("MM/YYYY").split("/")
        }

        nextMonth = parseInt(splittedClosedMonth[0]) + 1
        nextYear = parseInt(splittedClosedMonth[1])

        //No caso do mês ser 12
        if (nextMonth == 13) {
            nextMonth = 1;
            nextYear++;
        }

        const divergentNTNBBalancesTitles = [];

        if (clientOn.client.selectedMonth != nextMonth
            || clientOn.client.selectedYear != nextYear) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: 'A próxima carteira a ser fechada deve ser a do mês ' + getMonthName(nextMonth) + "/" + nextYear
            }))

        } else {

            if (fullDiaryPl == NO_INIT_QUOTA) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: 'Falha ao lançar carteira, cota inicial não encontrada!'
                }))

                return;
            }

            const clientId = clientOn.client.id;
            const monthRent = clientOn.client.selectedMonth;
            const yearRent = clientOn.client.selectedYear;


            let formattedMonth = clientOn.client.selectedMonth + "/" + clientOn.client.selectedYear

            //Verificar se os ativos do mês possuem seus saldos validados e/ou conciliados            
            let allConc = true;
            let accountsWithNoBalance = []; //verificar se todas as contas possuem saldo informado no mês de lançamento
            //let accountsWithNoBalanceToCreate = []; //verificar se todas as contas possuem saldo informado no mês de lançamento
            let assetsNoInfos = [];
            let allQtdQuotasOk = true;
            let allQtdQuotasNoOk = [];

            let noQuotasNow = [] //Verificar se existem cotas comdinheiro salvas localmente
            let noQuotasBefore = [] //Verificar se existem cotas comdinheiro salvas localmente

            let assetsNoQtdQuotasValidation = [];


            const justAssetsFormatted = assetsFormatted.filter(el => !el.labelArticle && !el.labelSegment);

            console.log("ASSETS TO LAUNCH: ", justAssetsFormatted);
            console.log("offAssets: ", offAssets);
            console.log("fullDiaryPl: ", fullDiaryPl);

            justAssetsFormatted.forEach(element => {

                //Verificando se os ativos possuem as informações cadastrais
                //if (!element.special && !element.dados_cadastrais) {
                const isTitleAsset = element.title_id;
                if (!isTitleAsset) {
                    if (!element.dados_cadastrais && !element.no_infos_api) {
                        assetsNoInfos.push(element);
                    }
                }

                if (!isTitleAsset) {

                    if (
                        //!element.special &&
                        //(!element.quotas_anbima || (element.quotas_anbima.filter(el => !el.replicated).length === 0))
                        (!element.quotas_anbima || (element.quotas_anbima.filter(el => !el.replicated).length === 0))
                    ) {

                        noQuotasNow.push(element);

                    }

                    if (!element.is_poupanca && (!element.quotas_anbima_before || element.quotas_anbima_before.length === 0)) {
                        if (element.uno_date_quota_init) {
                            console.log("IS BEFORE: ", moment.utc(element.uno_date_quota_init).isBefore(getLastDayMonthAgo(monthRent, yearRent)))
                            console.log(moment.utc(element.uno_date_quota_init));
                            console.log(getLastDayMonthAgo(monthRent, yearRent));
                            if (moment.utc(element.uno_date_quota_init).isBefore(getLastDayMonthAgo(monthRent, yearRent))) {
                                noQuotasBefore.push(element);
                            }
                        } else {
                            noQuotasBefore.push(element);
                        }

                    }

                    if (element.quota_amount_now == null || !element.quota_amount_before == null) {
                        assetsNoQtdQuotasValidation.push(element);
                    }

                } else {

                    if (needQuotasTitle(element)) {
                        if (!element.title_local_quotas || element.title_local_quotas.length === 0) {

                            noQuotasNow.push(element);

                        }

                        if (!element.title_local_quotas_before || element.title_local_quotas_before.length === 0) {
                            noQuotasBefore.push(element);
                        }
                    }


                    //Verificando se existem saldos muito divergentes em NTNB
                    if (checkAssetTitleDivergentBalance(element)) {
                        divergentNTNBBalancesTitles.push(element);
                    }
                }

                //Caso tenha elementos segregados deve verificar os saldos de cada elemento
                if (element.subAssets) {

                    element.subAssets.forEach(elementSegregated => {

                        if (!checkIsSameBalance(elementSegregated.pl, elementSegregated.balance_now)
                            || !checkIsSameQuotas(elementSegregated.quota_amount_now, elementSegregated.qtd_quotas_sensibilized_final)) {

                            console.log("ENTROU NO SAME BALANCE");


                            if (!elementSegregated.balance_now_conc) {
                                console.log("ENTROU NO CONC");
                                console.log("ELEMENTE ASSET: ", element);
                                //console.log("E não tem balance_conc: ", elementSegregated.balance_now_conc);
                                allConc = false;
                            }

                        }

                        //Verificando se o saldo atual de cotas é igual ao informado na validação
                        // if (!checkIsSameQuotas(elementSegregated.quota_amount_now, elementSegregated.qtd_quotas_sensibilized_final)) {
                        //     allQtdQuotasOk = false;
                        //     allQtdQuotasNoOk.push(elementSegregated);
                        // }

                    });

                } else {

                    if (!checkIsSameBalance(element.pl, element.balance_now)
                        || !checkIsSameQuotas(element.quota_amount_now, element.qtd_quotas_sensibilized_final)) {
                        //if (element.balance_before + element.resultAmount != element.balance_now) {

                        if (!element.balance_now_conc) {
                            //console.log("E não tem balance_conc: ", element.balance_now_conc);
                            allConc = false;
                        }

                    }

                    // if (!checkIsSameQuotas(element.quota_amount_now != element.qtd_quotas_sensibilized_final)) {
                    //     allQtdQuotasOk = false;
                    //     allQtdQuotasNoOk.push(element);
                    // }
                }

                console.log("ALL CONC: ", allConc)
            });


            if (divergentNTNBBalancesTitles.length && !enableDivergentTitles) {

                setDataToInconsistentTitlesModal({
                    message: 'Alguns ativos de título apresetaram saldos divergentes',
                    titles: divergentNTNBBalancesTitles
                });
                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))
                return;

            }


            //verificando se os saldos de todas as contas foram informados para esse mês
            accountsWithNoBalance = clientAccounts.filter(el =>
                stringPtDateToMoment('01/' + clientOn.client.selectedMonth.toString().padStart(2, '0') + '/' + clientOn.client.selectedYear, true)
                    .isSameOrAfter(el.account_init, 'month') && el.balance_now == null);

            //Verificando se a conta está atrelada a algum fundo em questão
            //Apenas as contas que possuem ativo relacionado
            // let idsAccountsNoBalanceToCreate = [];
            // preAccountsWithNoBalance.forEach(acc => {
            //     if (justAssetsFormatted.some(el => el.account_id == acc.id) && !idsAccountsNoBalance.includes(acc.id)) {
            //         accountsWithNoBalanceToCreate.push(acc);
            //         idsAccountsNoBalance.push(acc.id);
            //     }
            // });

            validatorFundsClientRents(clientId, monthRent, yearRent, justAssetsFormatted);

            // console.log("ON LAUNCH fullDiaryPl: ", fullDiaryPl);

            // return;

            //Veriricando se existem títulos com rentabilidades destoantes

            if (
                justAssetsFormatted.some(el => el.title_id && el.needCheckRent)
                &&
                !checkUserIsRole(currentUser, [roles.superadmin])
            ) {
                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: 'Existem títulos com rentablidades superiores a ' + OUT_OF_MARKET_RENT_TITLE
                }))
                return;
            }

            if (allConc
                && assetsNoInfos.length == 0
                && allQtdQuotasOk
                && noQuotasNow.length == 0
                && noQuotasBefore.length == 0
                && accountsWithNoBalance.length == 0
                && assetsNoQtdQuotasValidation.length == 0
                && !justAssetsFormatted.some(fund => fund.validatedRentFund === false)) {

                console.log("FECHAR: ", formattedMonth)

                //Atualizar balances_validations com o totalPeriodRent dos ativos, necessário para evitar processamento desnecessário em outras telas 
                let allSuccessUpdateRents = true;

                ///////////////////////////////////////////
                //Incluir assets zerados nas atualizações//
                ///////////////////////////////////////////

                //Checar se existem ativos totalmente resgatados sem a informação de saldo 0 para o mês 
                let needValidateOffAssets = false;
                if (!validatedOffAssets && offAssets && offAssets.length > 0) {

                    let noBalancesOffAssets = offAssets.filter(el => el.balance_now == null);
                    if (noBalancesOffAssets && noBalancesOffAssets.length > 0) {

                        needValidateOffAssets = true;
                        //Chama o modal de aviso
                        setDataOffAssets({
                            offAssets: offAssets,
                            month: nextMonth
                        })
                        setShowOffAssetsValidateModal(true);
                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))
                    }

                }

                if (!needValidateOffAssets || validatedOffAssets) {
                    if (offAssets && offAssets.length > 0) {

                        for (let k = 0; k < offAssets.length; k++) {
                            const oa = offAssets[k];

                            //Caso não haja balance_now_id o saldo não foi informado deve ser salvo o balance_validation zerado para esse ativo
                            if (oa.balance_now_id == null
                                && stringPtDateToMoment('01/' + clientOn.client.selectedMonth.toString().padStart(2, '0') + '/' + clientOn.client.selectedYear, true)
                                    .isSameOrAfter(oa.asset_init, 'month')) {
                                let responseOaBalance = await createBalanceValidation({
                                    account_id: null,
                                    asset_id: oa.id,
                                    balance: 0,
                                    quota_amount: 0,
                                    balance_validation_id: undefined,
                                    month_validation: clientOn.client.selectedMonth,
                                    year_validation: clientOn.client.selectedYear,
                                });

                                if (responseOaBalance.success) {
                                    console.log("SUBINDO SALDO CRIADO AUTOMATICAMENTE");
                                    // let responseOaRent = await updateRentInBalancesValidation(oa.balance_now_id, 0, null);
                                    // if (responseOaRent.success) {

                                    //     //Rentabilidade cadastrada com sucesso
                                    // } else {
                                    //     console.error("Falha ao salvar saldo de ativo resgatado totalmente");
                                    // }
                                } else {
                                    console.error("Falha ao salvar saldo de ativo resgatado totalmente");
                                }
                            }



                        }

                    }

                    //Verificando se é necessário o salvamento da cota inicial da carteira
                    if (clientOn.client.portfolio_closed == null) {
                        const responseRent = await saveClientRent(initPortfolioQuota);
                        console.log("RESPOSTA SAVING FIRST QUOTA: ", responseRent);
                    }



                    const fundsFormatted = justAssetsFormatted.filter(el => !el.title_id) //filter para que não sejam considerados os títulos
                    for (let i = 0; i < fundsFormatted.length; i++) {

                        const assetLoop = fundsFormatted[i]
                        const responseRentFund = await doSaveFundClientRent(assetLoop.validatedRentFund);

                    }

                    console.log('justAssetsFormatted.filter(el => el.title_id): ', justAssetsFormatted.filter(el => el.title_id));

                    //Salvando rentabilidades dos títulos
                    const titlesFormatted = justAssetsFormatted.filter(el => el.title_id) //filter para que não sejam considerados os títulos
                    for (let i = 0; i < titlesFormatted.length; i++) {

                        const assetTitleLoop = titlesFormatted[i]
                        const responseRentTitle = await doSaveTitleClientRent(clientId, monthRent, yearRent, assetTitleLoop);

                    }

                    //Caso as rentabilidades tenham sido salvas corretamente
                    if (allSuccessUpdateRents) {

                        if (fullDiaryPl) {


                            Object.entries(fullDiaryPl).map(async ([key, row]) => {

                                //adicionando informações necessárias
                                let clientRent = {
                                    client_id: clientOn.client.id,
                                    date_quota: key,
                                    month: clientOn.client.selectedMonth,
                                    year: clientOn.client.selectedYear,
                                    new_pl: row.newPl,
                                    ...row,
                                }

                                //console.log("Salvando rent diário: ", clientRent);
                                let response = await saveClientRent(clientRent)
                                if (!response.success) {
                                    allSuccessUpdateRents = false;
                                }

                            })

                        } else {
                            if (justAssetsFormatted && justAssetsFormatted.length === 0 && offAssets && offAssets.length > 0) {

                                //Cliente que não possui nenhuma aplicação no mês, mas deve ser lançado a rentabilidade da carteira
                                let responselastQuota = await getClientLastQuota(clientId, monthRent, yearRent);
                                //console.log('responselastQuota: ', responselastQuota);
                                const daysToRent = getBusinessDaysInMonth(monthRent, yearRent);
                                //console.log('daysToRent: ', daysToRent);
                                if (responselastQuota.success
                                    && responselastQuota.body
                                    && responselastQuota.body.length === 1) {
                                    for (let i = 0; i < daysToRent.length; i++) {
                                        const day = daysToRent[i];

                                        //adicionando informações necessárias
                                        let clientRent = {
                                            ...responselastQuota.body[0],
                                            date_quota: day,
                                            month: monthRent,
                                            year: yearRent,
                                            rent_day: 0,
                                        }

                                        //console.log("Salvando rent diário: ", clientRent);
                                        let response = await saveClientRent(clientRent)
                                        if (!response.success) {
                                            allSuccessUpdateRents = false;
                                        }
                                    }
                                }

                            }
                        }

                        //return;


                        let response = await updateClientClosedMonth(clientOn.client.id,
                            formattedMonth.toString().trim())
                        if (response.success) {

                            dispatch(allActions.fullLoadActions.setFullLoad({
                                show: false,
                            }))
                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'success',
                                message: 'Carteira de [ ' + getMonthName(formattedMonth) + '/' + clientOn.client.selectedYear + ' ] lançada com sucesso!'
                            }))

                            clientOn.client.portfolio_closed = formattedMonth;
                            //Atualizar datas dos selects de data da Dashboard
                            clientOn.client.updateInfosPortfolio = true;

                            console.log("CLIENT ON: ", clientOn);
                            //Avançando automaticamente a tela de coleta para o próximo mês
                            let nextMonth = clientOn.client.selectedMonth + 1;
                            if (nextMonth == 13
                                || (nextMonth > clientOn.client.months.length) //visto que os meses estão em um array começando por 0, se o nextMonth for 7 ele tem que ser <= a months.length
                            ) {

                                //Caso seja dezembro mantem-se no mesmo ano e espera-se a troca do usuário
                                //ou no caso de ser um mês que não possui movimntações
                                nextMonth--;

                            }

                            const oldMonth = clientOn.client.selectedMonth;
                            clientOn.client.selectedMonth = nextMonth;

                            //Registrando logAction

                            const responseLogAction = await saveLogPortfolioLaunch(currentUser.user.id, clientId, portfolioClosedToDate(formattedMonth));

                            dispatch(allActions.clientActions.setClient(clientOn.client))

                            //Enviando Email de lançamento de carteira
                            const lastMonthYear = getLastMonthYearAbleToQuotas();
                            console.log('lastMonthYear: ', lastMonthYear);
                            console.log('clientOn.client.selectedMonth: ', clientOn.client.selectedMonth);
                            console.log('clientOn.client.selectedYear: ', clientOn.client.selectedYear);
                            if (lastMonthYear.month == oldMonth
                                && lastMonthYear.year == clientOn.client.selectedYear) {
                                sendLaunchPortofolioEmail(clientOn.client.id, clientOn.client.selectedMonth, clientOn.client.selectedYear);
                            }
                            //END EMAIL

                        } else {

                            dispatch(allActions.fullLoadActions.setFullLoad({
                                show: false,
                            }))
                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'error',
                                message: 'Erro ao fechar carteira de ' + formattedMonth
                            }))
                        }
                    } else {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))
                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: 'Erro ao atualizar rentabilidades dos saldos'
                        }))
                    }
                }



            } else {

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                if (!allQtdQuotasOk) {

                    console.log("QUOTAS NO OK: ", allQtdQuotasNoOk);
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'warning',
                        message: 'Existem ativos com quantidade de COTAS atuais diferentes do que foi informado na VALIDAÇÃO'
                    }))

                } else {

                    if (!allConc) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'warning',
                            message: 'Os saldos de todos os ativos devem estar validados e/ou conciliados'
                        }))

                    } else {


                        if (accountsWithNoBalance.length > 0) {
                            console.log("ACCOUNTS NO BALANCE: ", accountsWithNoBalance);
                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'warning',
                                message: 'Algumas contas não possuem saldo informado para o período ' + getMonthName(formattedMonth) + '/' + clientOn.client.selectedYear + ', verifique em Ativos e Contas > Aba Contas',
                            }))
                        } else {

                            if (assetsNoInfos.length > 0) {

                                console.log("ASSETS NO INFOS: ", assetsNoInfos);
                                setFundsToInconsistentFundsModal({
                                    message: 'Alguns ativos ainda não possuem Dados Cadastrais salvos localmente',
                                    funds: assetsNoInfos,
                                })
                                setShowInconsistentFundsModal(true);

                                // dispatch(allActions.mainAlertActions.setMainAlert({
                                //     show: true,
                                //     type: 'warning',
                                //     message: 'Alguns ativos ainda não possuem Dados Cadastrais salvos localmente'
                                // }))

                            } else if (justAssetsFormatted.some(fund => fund.validatedRentFund === false)) {

                                console.log("FUNDOS COM RENT FUND INCONSISTENTES: ", justAssetsFormatted.filter(fund => fund.validatedRentFund === false));
                                dispatch(allActions.mainAlertActions.setMainAlert({
                                    show: true,
                                    type: 'warning',
                                    message: 'Alguns fundos possuem inconsistência na rentabilidade'
                                }))

                            } else if (noQuotasNow.length > 0) {

                                setFundsToInconsistentFundsModal({
                                    message: 'Fundos sem informações de cotas mensais para o mês atual',
                                    funds: noQuotasNow,
                                })
                                setShowInconsistentFundsModal(true);

                                // dispatch(allActions.mainAlertActions.setMainAlert({
                                //     show: true,
                                //     type: 'warning',
                                //     message: 'Existem fundos sem informações de cotas mensais para o mês atual'
                                // }))

                            } else if (noQuotasBefore.length > 0) {

                                setFundsToInconsistentFundsModal({
                                    message: 'Fundos sem informações de cotas mensais para o mês anterior',
                                    funds: noQuotasBefore,
                                })
                                setShowInconsistentFundsModal(true);
                                // console.log('noQuotasBefore: ', noQuotasBefore);
                                // dispatch(allActions.mainAlertActions.setMainAlert({
                                //     show: true,
                                //     type: 'warning',
                                //     message: 'Existem fundos sem informações de cotas mensais para o mês anterior'
                                // }))

                            } else if (assetsNoQtdQuotasValidation.length > 0) {
                                setFundsToInconsistentFundsModal({
                                    message: 'Fundos sem informação de quantidade de cota para o mês atual e/ou anterior',
                                    funds: assetsNoQtdQuotasValidation,
                                })
                                setShowInconsistentFundsModal(true);
                            }
                        }



                    }
                }

            }

        }



    }

    //SpeedDial
    const [direction, setDirection] = React.useState('up');
    const [openFab, setOpenFab] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    const handleDirectionChange = (event) => {
        setDirection(event.target.value);
    };

    const handleHiddenChange = (event) => {
        setHidden(event.target.checked);
    };

    const handleCloseFab = () => {
        setOpenFab(false);
    };

    const handleOpenFab = (openFab) => {
        setOpenFab(openFab);
    };

    function doClickinputFile() {
        handleCloseFab();
        inputFile.current.click()
    }

    const [appearFab, setAppearFab] = useState(true);

    // const {
    //     fileToImport,
    //     doResetImportVariables,
    //     cleanResetImportVariables
    // } = props    

    async function loadInfosAssets(clientId, month, year, accountRegimeId, doQuotas, portfolioInit) {

        //Caso o month e o year sejam undefined (quando ainda não existem movimentações cadastradas)
        //dentro do percusso desse método deve se parar no momento em que trás as contas        
        let responseInfosAssets = await getAssetInfos(clientId,
            month,
            year,
            accountRegimeId,
            doQuotas, portfolioInit);

        if (responseInfosAssets.success) {

            //Atualizando componentes da tela
            setClientAccounts(responseInfosAssets.accounts);

            setOffAssets(responseInfosAssets.offAssets);

            //adicionando offAssets aos assets formatted com propriedade que informa que não
            //estão ativos nas rentabilizações

            //setAssetsFormatted(responseInfosAssets.assetsFormatted);
            const formatteds = groupFundsBySegmentAndArticle(responseInfosAssets.assetsFormatted);
            setAssetsFormatted(formatteds);

            // const checkConsistencyRents = [];
            // formatteds.forEach(element => {

            //     const rentMonth = getInMonthRentFundInPortfolio(element);
            //     const rentMoneyMonth = getRentInMoneyMonthTitle(element);
            //     if ((rentMonth > 0 && rentMoneyMonth < 0) || (rentMonth < 0 && rentMoneyMonth > 0)) {
            //         checkConsistencyRents.push({
            //             cnpj: element.cnpj,
            //             r1: rentMonth,
            //             r2: rentMoneyMonth,
            //         })
            //     }

            // });
            // console.log("CHECAGEM CONSISTENCIA RENTABILIDADES: ", checkConsistencyRents);

            setFullDiaryPl(responseInfosAssets.fullDiaryPl);
            setInitPortfolioQuota(responseInfosAssets.initPortfolioQuota);

            if (responseInfosAssets.fullDiaryPl && responseInfosAssets.initPortfolioQuota) {
                if (Object.values(responseInfosAssets.fullDiaryPl).length) {

                    setRentPortfolioInMonth(((Object.values(responseInfosAssets.fullDiaryPl).pop().quota_value / responseInfosAssets.initPortfolioQuota.quota_value) - 1) * 100);
                }

            }
            setTotalResultAmount(responseInfosAssets.totalResultAmount);
            setTotalBeforeAmount(responseInfosAssets.totalBeforeAmount);
            setTotalDisponibilityInAccounts(responseInfosAssets.totalDisponibilityInAccounts);

            //Indicar se transação irregular. Caso não faça parte dos ativos do mês.
            //Se faz necessário pois houve caso em que foi cadastrada movimentação de título que não estava vigente na carteira
            responseInfosAssets.transactions.forEach(element => {
                if (element.title_id) {
                    if (!responseInfosAssets.assetsFormatted.some(el => el.id == element.title_asset_id)) {
                        element.invalidTransaction = true;
                    }
                } else {

                    if (!responseInfosAssets.assetsFormatted.some(el =>
                        el.id == element.asset_id
                        ||
                        (el.subAssets && el.subAssets.some(el => el.id === element.asset_id))
                    )) {
                        element.invalidTransaction = true;
                    }


                }
            });

            setTransactions(responseInfosAssets.transactions);
            const resultTrasactionsAmounts = responseInfosAssets.transactions.reduce(
                (previous, current) => previous + (
                    current.operation_id == 1 ? parseFloat(current.amount) : parseFloat(current.amount) * (-1)
                ),
                0
            );
            const resultTrasactionsApplications = responseInfosAssets.transactions.filter(el => el.operation_id === 1).reduce(
                (previous, current) => previous + parseFloat(current.amount),
                0
            );
            const resultTrasactionsResues = responseInfosAssets.transactions.filter(el => el.operation_id === 2).reduce(
                (previous, current) => previous + parseFloat(current.amount),
                0
            );
            setTotalAmountTransactions(resultTrasactionsAmounts);
            setTotalAmountTransactionsApplications(resultTrasactionsApplications);
            setTotalAmountTransactionsRescues(resultTrasactionsResues);

            setRentPortfolioInMonthMoney(getPortfolioMonthRentInMoney(responseInfosAssets.totalResultAmount, resultTrasactionsAmounts, responseInfosAssets.totalBeforeAmount));
            setInvalidQuotas(responseInfosAssets.invalidQuotas);

            console.log("INFO ASSETS: ", responseInfosAssets);


            //Verificar role
            if (checkUserIsRole(currentUser,
                [roles.admin,
                roles.superadmin,
                roles.advisor])) {

                const amortizationsToCreate = verifyTitlesAmortizations(responseInfosAssets.assetsFormatted.filter(el => el.title_id));
                console.log('amortizationsToCreate: ', amortizationsToCreate)
                if (amortizationsToCreate && amortizationsToCreate.length) {


                    setAmortizationsToCreate(amortizationsToCreate);
                    setShowAmortizationToCreateModal(true)
                }
            }


        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: responseInfosAssets.message,
            }))
        }

        if (responseInfosAssets
            && responseInfosAssets.assetsFormatted
            && responseInfosAssets.assetsFormatted.length == 0
            // && responseInfosAssets.offAssets
            // && responseInfosAssets.offAssets.length == 0
        ) {

            setInnerLoad({
                emptyMessage: 'Nenhuma informação lançada para '
                    + getMonthName(clientOn.client.selectedMonth) + "/" + clientOn.client.selectedYear
            })
            // dispatch(allActions.innerLoadActions.setInnerLoad({
            //     show: true,
            //     emptyMessage: 'Nenhuma informação lançada para '
            //         + getMonthName(clientOn.client.selectedMonth) + "/" + clientOn.client.selectedYear,
            //     cod: codsInnerLoad.balancesCollectionScreen
            // }));

        } else {

            // dispatch(allActions.innerLoadActions.setInnerLoad({
            //     show: true,
            //     emptyMessage: 'Ainda sem informações de ativos para '
            //         + getMonthName(clientOn.client.selectedMonth) + "/" + clientOn.client.selectedYear
            // }));

            console.log("REMOVENDO INNER LOAD 1");
            // dispatch(allActions.innerLoadActions.setInnerLoad({
            //     show: false,
            //     cod: codsInnerLoad.balancesCollectionScreen
            // }));
            setInnerLoad(null)

        }



    }

    function doCreateAsset() {

        if (clientAccounts && clientAccounts.length > 0) {
            dispatch(allActions.rightContentActions.setRightContent({
                path: 'newAsset',
                data: {
                    allFunds: allFunds,
                    accounts: clientAccounts,
                }
            }))
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: 'Para cadastrar um ativo você precisa cadastrar pelo menos uma conta'
            }))
        }
    }

    function doCreateAssetTitle() {

        if (clientAccounts && clientAccounts.length > 0) {
            dispatch(allActions.rightContentActions.setRightContent({
                path: 'newAssetTitle',
                data: {
                    accounts: clientAccounts,
                }
            }))
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: 'Para cadastrar um ativo você precisa cadastrar pelo menos uma conta'
            }))
        }
    }

    function updateConcilio(balanceId) {

        if (balanceId) {
            console.log("Update concilio: ", balanceId);
            let newAssetsFormatted = [...assetsFormatted];

            for (let i = 0; i < newAssetsFormatted.length; i++) {
                const assetLoop = newAssetsFormatted[i];

                if (assetLoop.subAssets && assetLoop.subAssets.length > 0) {

                    for (let j = 0; j < assetLoop.subAssets.length; j++) {

                        let subAssetLoop = assetLoop.subAssets[j]
                        //console.log("SubAsset: ", subAssetLoop.balance_now_id);
                        if (subAssetLoop.balance_now_id == balanceId) {
                            subAssetLoop.balance_now_conc = true;
                            //console.log("SubAsset: ", subAssetLoop);
                        }

                    }

                } else {
                    if (assetLoop.balance_now_id == balanceId) {
                        assetLoop.balance_now_conc = true;
                    }
                }

            }

            newAssetsFormatted.forEach(element => {

                //console.log("///////");
                if (element.subAssets) {
                    //Conciliando conciliações
                    let allConc = true;
                    let groupConciliaded = false;

                    element.subAssets.forEach(elementSegregated => {

                        if (!checkIsSameBalance(elementSegregated.pl, elementSegregated.balance_now)
                            || !checkIsSameQuotas(elementSegregated.quota_amount_now, elementSegregated.qtd_quotas_sensibilized_final)) {

                            if (!elementSegregated.balance_now_conc) {

                                allConc = false;
                            }

                            if (elementSegregated.balance_now_conc) {

                                element.inProgressConc = true;
                                groupConciliaded = true;
                            }

                        }

                        //console.log("elementSegregated.balance_now_conc [" + elementSegregated.balance_now_id + "]: ", elementSegregated.balance_now_conc);

                    });

                    if (allConc && groupConciliaded) {
                        //if (allConc) {
                        element.balance_now_conc = true;
                    } else if (!allConc) {
                        element.balance_now_conc = false;
                    }
                }


                //console.log("element.balance_now_conc: ", element.balance_now_conc);

            });

            //Tem que reprocessar o concilio do asset pai
            console.log("ASSETS POS CONCILIO: ", newAssetsFormatted);

            setAssetsFormatted(newAssetsFormatted);
        }

    }

    const [segregation, setSegregation] = React.useState(null);
    const [segregationOnScreen, setSegregationOnScreen] = React.useState(null);

    const handleSegregation = (event, newSegregation) => {

        if (newSegregation != segregationOnScreen) {
            setSegregation(newSegregation);
        }

    };

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }

    function downloadBlob(blob, name = 'file.txt') {
        if (
            window.navigator &&
            window.navigator.msSaveOrOpenBlob
        ) return window.navigator.msSaveOrOpenBlob(blob);

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = data;
        link.download = name;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }

    function generateValidationRentExcel(data) {

        const sheetName = "Validação";
        const columns = ['DATA', 'VALOR COTA CARTEIRA', 'RESULT_APP_RESG (R$)', 'ADD_COTAS', 'PL'];

        // dispatch(allActions.innerLoadActions.setInnerLoad({
        //     show: true,
        //     loadMessage: 'Gerando planilha...',
        // }));
        setInnerLoad({ loadMessage: "Gerando planilha..." })

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Validação de rentabilidades",
            Author: "UNO",
        }

        wb.SheetNames.push(sheetName);

        const ws_data = [];

        ws_data.push(columns)

        Object.entries(data).map(([date, el]) => {
            ws_data.push([date, el.quota_value, el.result_app_rescues, el.add_quotas, el.newPl]);
        });

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets[sheetName] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        downloadBlob(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'Validacao_Rentabilidades.xlsx');

        // dispatch(allActions.innerLoadActions.setInnerLoad({
        //     show: false,
        // }));
        setInnerLoad(null)

    }

    async function processPreInsertTransactions(transactions, invalidColumns, invalidDates, assets, accounts, clientOn) {
        const dataToInsert = await SystemValidationsController.doPreInsertsTransactions(transactions, invalidColumns, invalidDates, assets, accounts, clientOn, dispatch);
        setDataToInsert(dataToInsert);
        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
            message: ""
        }))

        setShowImportModal(true);
    }

    useEffect(() => {

        console.log("USE EFFECT COLETA")

        if (clientOn
            && clientOn.client &&
            (
                !clientOnScreen ||

                (
                    (clientOnScreen.id != clientOn.client.id)
                    ||
                    (
                        clientOnScreen.id == clientOn.client.id
                        &&
                        (
                            (
                                clientOnScreen.selectedMonth != clientOn.client.selectedMonth
                                ||
                                clientOnScreen.selectedYear != clientOn.client.selectedYear
                            )
                            ||
                            (rightContent && rightContent.action == 'update') //update
                            ||
                            (segregation != segregationOnScreen) //update
                        )
                    )
                )
            )
        ) {

            console.log("PASSOU SET YEARS")

            // dispatch(allActions.innerLoadActions.setInnerLoad({
            //     show: true,
            //     loadMessage: 'Carregando informações...',
            //     cod: codsInnerLoad.balancesCollectionScreen
            // }));
            setInnerLoad({ loadMessage: "Carregando informações" })

            setClientAccounts([])
            setTransactions(undefined)
            setAssetsFormatted(undefined);
            setSegregationOnScreen(segregation);

            setFullDiaryPl(undefined);
            setInitPortfolioQuota(undefined);
            setRentPortfolioInMonth(undefined);
            setCheckedJustToConciliations(false);
            setDataToInconsistentTitlesModal();
            setEnableDivergentTitles(false);

            if (clientOn.client.selectedMonth && clientOn.client.selectedYear) {

                setClientOnScreen(_.cloneDeep(clientOn.client));

                //getClientAccountsAndTransactions(clientOn.client.id, clientOn.client.selectedMonth, clientOn.client.selectedYear);            
                console.log("CHAMOU LOAD INFOS ASSETS: ", clientOn.client.id);
                console.log("MONTH: ", clientOn.client.selectedMonth);
                console.log("YEAR: ", clientOn.client.selectedYear);

                loadInfosAssets(clientOn.client.id,
                    clientOn.client.selectedMonth,
                    clientOn.client.selectedYear,
                    segregation,
                    true, clientOn.client.portfolio_init);
                getDataToCreateTransaction();

                console.log("RIGHT CONTENT: ", rightContent);
                dispatch(allActions.rightContentActions.setRightContent(null))
            }


        } else if (rightContent
            && rightContent.action
            && rightContent.action.task == 'updateConcilio') {

            // rightContent.action = {
            //     task: 'updateConcilio',
            //     data: {
            //          balanceId: balanceId,                
            //     }
            // }

            console.log("RIGHT CONTENT: ", rightContent);
            updateConcilio(rightContent.action.data.balanceId);
            //dispatch(allActions.rightContentActions.setRightContent(null))

        }

        //Inicio import Excel
        if (file) {

            console.log("HANDLE FILE")
            if (clientOn && clientOn.client) {

                const responseValidFile = isFileForClientOn(file, clientOn.client, 'MOVS')
                if (responseValidFile.success) {

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: true,
                        message: "Validando planilha..."
                    }))

                    handleFile();

                } else {

                    setDataToInsert({

                        client: clientOn.client,
                        currentFileName: file.name,
                        rightFileName: responseValidFile.rightName + '.xlsm',


                    });

                    setShowImportModal(true);
                }
            }

        }

        //if (data && data.length > 0 && !dataListFormatted && importYear) {
        if (data && data.length > 0 && !dataListFormatted) {

            console.log("DATA: ", data);

            let dataLFormatted = [];
            let invalidColumns = [];
            let invalidDates = [];
            for (let i = 0; i < data.length; i++) {

                //console.log("Convertendo: ", data[i]);

                //Verificando se há alguma coluna invalida
                if (i === 0) {
                    invalidColumns = verifyColumnsExcel(data[i]);
                    if (invalidColumns.length > 0) {
                        break;
                    }
                }

                if (data[i]['DATA'] != undefined && data[i]['DATA'] != null && data[i]['DATA'] != "") {
                    //console.log("data[i]['DATA']: ", data[i]['DATA']);
                    if (Number.isInteger(data[i]['DATA'])) {
                        data[i].dataFormatada = moment.utc(getJsDateFromExcel(data[i]['DATA'])).format('DD/MM/YYYY');

                        dataLFormatted.push(data[i]);
                    } else {

                        invalidDates.push(data[i]['DATA']);
                        console.log("INVALID DATES: ", invalidDates);
                        //data[i].dataFormatada = data[i]['DATA'];
                    }


                }
                //data[i].dataFormatada = moment.utc(getJsDateFromExcel(data[i].DataOperacao)).add(1, 'days').format('DD/MM/YYYY');
                //delete data[i]['DATA'],                

            }

            //console.log(dataMonthFormatted);
            console.log("MOVIMENTAÇÕES VÁLIDAS: ", dataLFormatted);

            setDataListFormatted({
                invalidDates: invalidDates,
                data: dataLFormatted,
                invalidColumns: invalidColumns
            });

        } else if (data && data.length == 0) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
                message: ""
            }))

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "A planilha não possui dados"
            }))

        }

        if (dataListFormatted
            && clientOnScreen.id == clientOn.client.id) {

            //doInsertsTransactions(dataListFormatted);
            doPreInserts(dataListFormatted.data, dataListFormatted.invalidColumns, dataListFormatted.invalidDates);
            //processPreInsertTransactions(dataListFormatted.data, dataListFormatted.invalidColumns, dataListFormatted.invalidDates, clientAssets, clientAccounts, clientOn);

        }
        //Fim import excel

    }, [
        clientOn,
        file,
        dataListFormatted,
        rightContent,
        segregation
    ])

    /**
     * @type {import('@material-ui/lab').SpeedDialActionProps[]}
     */
    const speedDialActions = [
        {
            htmlFor: 'file',
            key: 'import-fab',
            icon: <PublishIcon />,
            tooltipOpen: true,
            tooltipTitle: 'Importar movimentações',
            onClick: () => doClickinputFile()
        },
        {
            key: 'print-fab',
            icon: <PrintIcon />,
            tooltipOpen: true,
            tooltipTitle: 'Imprimir relatório',
            onClick: () => dispatch(allActions.rightContentActions.setRightContent({
                path: 'newAsset',
                data: {
                    allFunds: allFunds,
                    accounts: clientAccounts,
                }
            }))
            ,
        },
        {
            key: 'asset-create-fab',
            icon: <AddIcon />,
            tooltipOpen: true,
            tooltipTitle: 'Cadastrar ativo',
            onClick: () => doCreateAsset()
        },
        {
            key: 'asset-title-create-fab',
            icon: <AddIcon />,
            tooltipOpen: true,
            tooltipTitle: 'Cadastrar título',
            onClick: () => doCreateAssetTitle()
        },
        {
            key: 'transaction-create-fab',
            icon: <AddIcon />,
            tooltipOpen: true,
            tooltipTitle: 'Cadastrar movimentação',
            onClick: () => {
                if (clientFunds && clientFunds.length > 0) {
                    dispatch(allActions.rightContentActions.setRightContent({
                        path: 'newTransaction',
                        data: {
                            accounts: clientAccounts,
                            funds: clientFunds,
                            titles: clientTitles,
                            assets: clientAssets,
                            titleAssets: clientTitleAssets,
                            operations: clientOperations,
                        }
                    }))
                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'warning',
                        message: 'Para criar uma movimentação você precisa primeiro criar um ATIVO',
                    }))
                }
            }
        },
    ]

    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={classes.tabs}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label="Coleta de saldos" />
                        <MyTab disabled={innerLoad && !innerLoad.emptyMessage} label={"Movimentações [ " + (transactions ? transactions.length : '-') + " ]"} />

                    </MyTabs>


                </div>



                {
                    tabValue == 1 ?
                        <>
                            {transactions && transactions.length && totalAmountTransactionsApplications
                                && (!innerLoad)
                                ?
                                <div style={{
                                    whiteSpace: 'nowrap',
                                    marginRight: '15px'
                                }}>
                                    {'App: '}<span className={classes.green}>{applyMaskAmount(totalAmountTransactionsApplications, true)}</span>
                                </div>
                                : null
                            }
                        </> : null
                }

                {
                    tabValue == 1 ?
                        <>
                            {transactions && transactions.length && totalAmountTransactionsRescues
                                && (!innerLoad)
                                ?
                                <div style={{
                                    whiteSpace: 'nowrap',
                                    marginRight: '15px'
                                }}>
                                    {'Res: '}<span className={classes.red}>{applyMaskAmount(totalAmountTransactionsRescues, true)}</span>
                                </div>
                                : null
                            }
                        </> : null
                }

                {
                    tabValue == 1 ?
                        <>
                            {transactions && transactions.length && totalAmountTransactions
                                && (!innerLoad)
                                ?
                                <div style={{
                                    whiteSpace: 'nowrap',
                                    marginRight: '15px'
                                }}>
                                    {'Dif Movs: '}<span className={
                                        totalAmountTransactions < 0 ?
                                            classes.red : classes.green}>{applyMaskAmount(totalAmountTransactions, true)}</span>
                                </div>
                                : null
                            }
                        </> : null
                }

                {
                    rentPortfolioInMonth
                        && (!innerLoad)
                        && !segregation
                        ?
                        <div style={{
                            whiteSpace: 'nowrap',
                            marginRight: '15px'
                        }}>
                            {'Rent: '}<span className={
                                rentPortfolioInMonthMoney < 0 ?
                                    classes.red : classes.green}>{applyMaskAmount(rentPortfolioInMonthMoney)}</span><span className={
                                        rentPortfolioInMonth < 0 ?
                                            classes.red : classes.green}> ({applyMaskAmount(rentPortfolioInMonth, null) + "%"})</span>
                        </div>
                        : null
                }


                {
                    tabValue === 0 ?
                        <div className={classes.rightHeader}>

                            <MyToggleSegregationButton
                                marginRight
                                value={segregation}
                                handleValue={handleSegregation}
                                disabled={innerLoad && !innerLoad.emptyMessage}
                                segregated={verifyClientPortfolioIsSgregated(clientOn)}
                            />

                            {
                                clientOn
                                    && clientOn.client
                                    && clientOn.client.selectedYear && clientOn.client.selectedMonth
                                    && isDateAfterClosedMonth(moment.utc(new Date(clientOn.client.selectedYear, parseInt(clientOn.client.selectedMonth) - 1, 1)).format("DD/MM/YYYY"),
                                        clientOn.client.portfolio_closed,
                                        false)
                                    && !isDateBeforeInit(getFirstDayMonth(clientOn.client.selectedMonth, clientOn.client.selectedYear), clientOn.client.portfolio_init)
                                    ?
                                    <>
                                        {

                                            (
                                                //Verificar role
                                                checkUserIsRole(currentUser, [roles.admin, roles.superadmin, roles.advisor])

                                                &&

                                                (clientOn
                                                    && clientOn.client
                                                    && ((assetsFormatted && assetsFormatted.length > 0) || (offAssets && offAssets.length > 0))
                                                    && !invalidQuotas)
                                            ) ?
                                                <UnoButton
                                                    size={'small'}
                                                    className={classes.spacingLeft1}
                                                    onClick={() => launchWallet()}
                                                    type='success'
                                                    startIcon={<AccountBalanceWalletIcon />}>
                                                    {"Lançar Carteira"}
                                                </UnoButton>
                                                :
                                                <>

                                                    {invalidQuotas ?
                                                        <UnoButton
                                                            size={'small'}
                                                            className={classes.spacingLeft1}
                                                            onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                                                path: 'invalidQuotas',
                                                                data: {
                                                                    month: clientOn.client.selectedMonth,
                                                                    year: clientOn.client.selectedYear,
                                                                    clientFunds: clientFunds,
                                                                    clientTitles: clientTitles,
                                                                    invalidQuotas: invalidQuotas,
                                                                }
                                                            }))}
                                                            type='error'>
                                                            {"Cotas inconsistentes"}
                                                        </UnoButton>
                                                        : null}
                                                </>

                                        }

                                    </>
                                    :
                                    <>

                                        {


                                            clientOn
                                                && clientOn.client
                                                && clientOn.client.selectedYear && clientOn.client.selectedMonth ?
                                                <>
                                                    {
                                                        isDateBeforeInit(getFirstDayMonth(clientOn.client.selectedMonth, clientOn.client.selectedYear), clientOn.client.portfolio_init) ?
                                                            <Typography variant={'h6'} className={classes.lounchedWalletLabel}>
                                                                <span>{"Início da carteira: " + moment.utc(clientOn.client.portfolio_init).format('MM/YYYY')}</span>
                                                            </Typography>

                                                            :

                                                            <>
                                                                {/* {
                                                                    checkUserIsRole(currentUser, [roles.superadmin]) &&
                                                                    <UnoButton
                                                                        isIconButton
                                                                        onClick={() => generateValidationRentExcel(fullDiaryPl)}
                                                                        size='small'
                                                                        type='warning'
                                                                    />
                                                                } */}

                                                                <Typography variant={'h6'} className={classes.lounchedWalletLabel}>
                                                                    <span>{"Carteira lançada "}</span><DoneIcon />
                                                                </Typography>
                                                            </>

                                                    }

                                                </> : null
                                        }

                                    </>


                            }

                        </div>
                        : null
                }



            </div>

            <DefaultScrollWithHeaderContent innerLoad={innerLoad}>

                {tabValue === 0 ?
                    <MyTable
                        initSizeTable={initSizeTable}
                        setCurrentSizeTable={setCurrentSizeTable}
                        stickyHeader
                        size="small"
                        onScroll={(value) => setAppearFab(value)}
                        selectable="true">
                        <colgroup>
                            <col width="35%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="5%" />
                            <col width="15%" />
                            <col width="15%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell className={classes.tableTh} align="center"></TableCell> */}
                                <TableCell align="left">Ativo</TableCell>
                                <TableCell align="right">Saldo Anterior</TableCell>
                                {/* <TableCell className={classes.tableTh} align="right"></TableCell> */}
                                <TableCell align="right">Saldo Atual</TableCell>
                                {/* <TableCell className={classes.tableTh} align="right">Saldo Validado</TableCell> */}
                                <TableCell align="center" className='noPaddingCell'><Checkbox
                                    checked={checkedJustToConciliations}
                                    color="primary"
                                    onChange={handleChangeCheckBoxJustConciliations}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                /></TableCell>
                                <TableCell align="left" className='noPaddingCell'>{checkedJustToConciliations ? 'A conciliar' : 'Todos'}</TableCell>
                                <TableCell align="right" onClick={() => changeReturnColumnType()}>Retorno {returnColumnType == 'money' ? '(R$)' : '(%)'}</TableCell>
                                {/* <TableCell className={classes.tableTh} align="right">Conciliação</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {assetsFormatted ?
                                assetsFormatted.filter((_, index) => index < currentSizeTable).filter(el => checkedJustToConciliations ? (el.needConciliation || el.needCheckRent) : true).map((row, index) => (

                                    <Row key={'asset-transactions-' + index} row={row} offAsset={false} index={index} />

                                )) : null}

                            {/* Disponiblidade */}
                            {/* <Row key={'asset-transactions-disponibilization'} row={{}} /> */}

                            {/* {offAssets ? offAssets.map((row, index) => (

                        <Row key={'asset-transactions-' + index} row={row} offAsset={true} index={index} />

                    )) : null} */}

                        </TableBody>
                        {
                            assetsFormatted && assetsFormatted.length > 0 ?
                                <TableFooter className={classes.myTableFooter}>
                                    <TableRow className='totalLineInvestiments' key={'footer-t-assets-collect-investiments'}>
                                        <TableCell align="left">TOTAL INVESTIMENTOS</TableCell>
                                        <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(totalBeforeAmount, true)}</span></TableCell>
                                        <TableCell align="right"><span className={'spanTotal'}>{totalResultAmount ? applyMaskAmount(totalResultAmount, true) : "-"}</span></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                    <TableRow className='disponibilityLine' key={'footer-t-assets-collect-disponibility'}>
                                        <TableCell align="left">DISPONIBILIDADE</TableCell>
                                        <TableCell align="right"><span className={'spanTotal'}>{totalDisponibilityInAccounts.before ? applyMaskAmount(totalDisponibilityInAccounts.before, true) : "-"}</span></TableCell>
                                        <TableCell align="right"><span className={'spanTotal'}>{totalDisponibilityInAccounts.now ? applyMaskAmount(totalDisponibilityInAccounts.now, true) : "-"}</span></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                    <TableRow className='totalLinePL' key={'footer-t-assets-collect-total'}>
                                        <TableCell align="left">TOTAL PATRIMÔNIO</TableCell>
                                        <TableCell align="right"><span className={'spanTotal'}>{totalBeforeAmount != null && totalDisponibilityInAccounts.before != null ?
                                            applyMaskAmount(totalBeforeAmount + totalDisponibilityInAccounts.before, true) : '-'}</span></TableCell>
                                        <TableCell align="right"><span className={'spanTotal'}>{totalResultAmount != null && totalDisponibilityInAccounts.now != null ?
                                            applyMaskAmount(totalResultAmount + totalDisponibilityInAccounts.now, true) : '-'}</span></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableFooter> : null
                        }

                    </MyTable>

                    :

                    <TransactionsTable
                        transactions={transactions}
                        clientAccounts={clientAccounts}
                        clientFunds={clientFunds}
                        clientTitles={clientTitles}
                        clientAssets={clientAssets}
                        clientTitleAssets={clientTitleAssets}
                        clientOperations={clientOperations}
                    />
                }
            </DefaultScrollWithHeaderContent>

            <Backdrop
                onClick={handleCloseFab}
                style={{ zIndex: '999' }}
                open={openFab} />

            {

                clientOn && clientOn.client &&

                    //Verificar role
                    checkUserIsRole(currentUser,
                        [roles.admin,
                        roles.superadmin,
                        roles.advisor])
                    ?

                    <Fade
                        in={appearFab}
                        timeout={transitionDuration}
                        unmountOnExit>
                        <div className={speedDialClasses.speedDial}>
                            <SpeedDial
                                ariaLabel="Coleta de saldos"
                                onClick={() => handleOpenFab(!openFab)}
                                //hidden={true}
                                hidden={hidden}
                                icon={<SpeedDialIcon />}
                                open={openFab}
                                direction={direction}>
                                {speedDialActions.map((props) => (<SpeedDialAction {...props} />))}
                            </SpeedDial>

                        </div>
                    </Fade> : null

            }

            <div>
                <input
                    ref={inputFile}
                    className={classes.input}
                    id="file"
                    multiple
                    accept={SheetJSFT}
                    type="file"
                    onClick={() => resetImportVariables()}
                    onChange={handleChangeFile}
                />
            </div>

            <InconsistentFundsModal
                fundsToInconsistentFundsModal={fundsToInconsistentFundsModal}
                closeModal={() => setShowInconsistentFundsModal(false)}
                open={showInconsistentFundsModal} />

            <InconsistentTitlesModal
                data={dataToInconsistentTitlesModal ? dataToInconsistentTitlesModal : {}}
                handleAction={(action) => handleActionDivergentTitles(action)}
                open={dataToInconsistentTitlesModal ? true : false} />

            <DataImportModal
                dataToInsert={dataToInsert}
                handleModalImport={(doImport) => handleModalImport(doImport)}
                open={showImportModal} />

            <OffAssetsValidateModal
                dataOffAssets={dataOffAssets}
                handleModalOffAssets={(doAction) => handleModalOffAssets(doAction)}
                open={showOffAssetsValidateModal} />

            <AmortizationsToCreateModal
                data={amortizationsToCreate}
                open={showAmortizationToCreateModal}
                handleModal={(action) => handleAmortizationToCreateModal(action)} />

        </div >
    );
}




export const path = {
  root: '/',
  resetPass: '/resetPass',
  contactLema: '/contactLema',
  analysis: {
    root: '/analysis',
    funds: '/analysis/funds',
    titles: '/analysis/titles',
    compareFunds: '/analysis/compareFunds',
    risk: {
      root: '/analysis/risk',
      market: '/analysis/risk/market',
      articles: '/analysis/risk/articles',
      benchmarks: '/analysis/risk/benchmarks',
      policy: '/analysis/risk/policy',
    }
  },
  administration: {
    root: '/administration',
    client: {
      root: '/administration/client',
      details: {
        root: '/administration/client/details',
        newClient: '/administration/client/details/newClient',
        managers: '/administration/client/details/managers',
        submanagers: '/administration/client/details/submanagers',
        viewers: '/administration/client/details/viewers',
        cadprev: '/administration/client/details/cadprev',
        gescon: '/administration/client/details/gescon'
      }
    },
    clients: {
      root: '/administration/clients',
      all: '/administration/clients/all',
      closures: '/administration/clients/all/closures',
    },
    funds: {
      root: '/administration/funds',
      all: '/administration/funds/all',
      titles: '/administration/funds/titles',
    },
    advisor: {
      new: '/administration/newAdvisor'
    },
    logs: {
      root: '/administration/logs',
      actions: '/administration/logs/clientUpdates',
      actions: '/administration/logs/actions',
      accesses: '/administration/logs/accesses',
      conciliations: '/administration/logs/conciliations'
    },
    clientsRents: {
      root: '/administration/clientsRents'
    }
  },
  administrative: {
    root: '/administrative',
    demonstratives: {
      root: '/administrative/demonstratives',
      returnsIPC14: '/administrative/demonstratives/returns',
      funds: '/administrative/demonstratives/funds',
    },
    accreditation: '/administrative/filesAndDocuments',
    tools: '/administrative/tools',
  },
  registers: {
    statusClients: {
      root: '/registers/statusClients'
    },
    actuarysClients: {
      root: '/registers/actuarysClients'
    }
  },
  investiments: {
    root: '/investiments',
    targets: '/investiments/targets'
  }
};

export const clientScreenAllowedPaths = {
  component: path.administration.clients.root,
  manager: path.administration.client.details.newClient,
  admin: path.administration.clients.all,
};

export const administrationFundsScreenPaths = {
  home: path.administration.funds.all
};
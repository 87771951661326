import API from './config';

export async function getFullReport(client, paths, isCustomReport, customReportName) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/fullReport', {
            client: client,
            paths: paths,
            is_custom_report: isCustomReport,
            custom_report_name: customReportName,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}
export async function getPortfolioReport(client, period, data, extraPortfolioReportParams) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/portfolioReport', {
            client: client,
            period: period,
            data: data,
            extra_portfolio_report_params: extraPortfolioReportParams,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getProvisionalReport(client, period, data, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/provisionalReport', {
            client: client,
            period: period,
            data: data,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundsRankingReport(client, period, data, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/fundsRankingReport', {
            client,
            period,
            data,
            page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getDemonstrativeReport(client, period, data) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/demonstrativeReport', {
            client,
            period,
            data
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFullDemonstrativeReport(client, resultPaths) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/fullDemonstrativeReport', {
            client: client,
            resultPaths: resultPaths,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getDistribuitionReportPart(client, period, data, index, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/distribuitionReportPart', {
            client: client,
            period: period,
            data: data,
            index: index,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getDistribuitionReportPartGrouped(client, period, data1, data2, index1, index2, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/getDistribuitionReportPartGrouped', {
            client: client,
            period: period,
            data1: data1,
            data2: data2,
            index1,
            index2,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getDistribuitionReport(client, resultPaths) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/distribuitionReport', {
            client: client,
            resultPaths: resultPaths,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAprBatchReport(client, resultPaths) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/batchAprsReport', {
            client: client,
            resultPaths: resultPaths,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getCompareFundsReport(client, funds, benchmarks, tablesData) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/fundsCompareReport', {
            client: client,
            tablesData: tablesData,
            funds: funds,
            benchmarks: benchmarks
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAprReport(client, transaction) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("APR PAYLOAD: ", JSON.stringify({
            client: client,
            transaction: transaction
        }));

        const response = await API.post('/reports/aprReport', {
            client: client,
            transaction: transaction
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getSingleAprReport(client, transaction) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/singleAprReport', {
            client: client,
            transaction: transaction
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getArticlesReport(client, data, period, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/articlesReport', {
            client: client,
            data: data,
            period: period,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getArticlesV2Report(client, data, period, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/articlesReportV2', {
            client: client,
            data: data,
            period: period,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getTargetsReport(client, period, data, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/targetsReport', {
            client: client,
            data: data,
            period: period,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getDashboardReport(client, period, data, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/dashboardReport', {
            client: client,
            data: data,
            period: period,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getCapaReport(client, period, subTitle) {

    console.log('subTitle: ', subTitle);
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/capaReport', {
            client: client,
            period: period,
            sub_title: subTitle,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}



export async function getMyFundsReport(client, period, data, periodReport, monthsPeriod) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/myFundsReport', {
            client: client,
            data: data,
            period: period,
            period_report: periodReport,
            months_period: monthsPeriod,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getMyTitlesReport(client, period, data, page) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/myTitlesReport', {
            client: client,
            data: data,
            period: period,
            page: page,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getMyReturnsReport(client, period, data, pageIndex) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/myReturnsReport', {
            client,
            data,
            period,
            pageIndex
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getRiskMarketReport(client, period, data, pageIndex) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/riskMarketReport', {
            client,
            data,
            period,
            pageIndex
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getRiskArticlesReport(client, period, data, pageIndex) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/riskArticlesReport', {
            client,
            data,
            period,
            pageIndex
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getRiskBenchmarksReport(client, period, data, pageIndex) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/riskBenchmarksReport', {
            client,
            data,
            period,
            pageIndex
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getRiskPolicyReport(client, period, data, pageIndex) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/riskPolicyReport', {
            client,
            data,
            period,
            pageIndex
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFullRiskReport(client, resultPaths) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/reports/fullRiskReport', {
            client: client,
            resultPaths: resultPaths,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deprecatedSaveChartTmpImage(clientId, data, index, fileName) {
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    };

    try {
        const response = await API.post('/reports/saveChartTmpImageDeprecated', {
            client_id: clientId,
            img_base64: data,
            index: index,
            file_name: fileName,
        }, config);

        return {
            success: true,
            body: response
        };
    } catch (error) {
        return {
            success: false,
            body: error.response.data
        };
    }
}

/**
 * @typedef {{
 *  chart: import('../controllers/ReportController').DataToReport['dataToChart'];
 *  maxValue?: number;
 * }} DataToReportAPI
 * @param {number} clientId
 * @param {DataToReportAPI} data
 * @param {number} count
 * @param {string} fileName
 * @typedef {'plEvolution' | 'distribuition' | 'comparative'} ReportTypeAPI
 * @param {ReportTypeAPI} reportType
 * @param {import('chart.js').ChartLegendOptions} legend
 * @returns
 */
export async function saveChartTmpImage(
    clientId,
    data,
    count,
    fileName,
    reportType,
    legend
) {
    let config = {
        headers: {
            "x-access-token": localStorage.getItem("token"),
        },
    };

    try {
        const response = await API.post(
            "/reports/saveChartTmpImage",
            {
                client_id: clientId,
                data,
                count,
                file_name: fileName,
                reportType,
                legend,
            },
            config
        );

        return {
            success: true,
            body: response,
        };
    } catch (error) {
        return {
            success: false,
            body: error.response.data,
        };
    }
}